/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const bulkInvitePatients = /* GraphQL */ `
  mutation BulkInvitePatients($paramsStringified: String) {
    bulkInvitePatients(paramsStringified: $paramsStringified)
  }
`;
export const sendCustomSms = /* GraphQL */ `
  mutation SendCustomSms($paramsStringified: String) {
    sendCustomSMS(paramsStringified: $paramsStringified)
  }
`;
export const adminCancelAppointment = /* GraphQL */ `
  mutation AdminCancelAppointment($paramsStringified: String) {
    adminCancelAppointment(paramsStringified: $paramsStringified)
  }
`;
export const adminBookAppointment = /* GraphQL */ `
  mutation AdminBookAppointment($paramsStringified: String) {
    adminBookAppointment(paramsStringified: $paramsStringified)
  }
`;
export const saveAndFileToEmis = /* GraphQL */ `
  mutation SaveAndFileToEmis($paramsStringified: String) {
    saveAndFileToEmis(paramsStringified: $paramsStringified)
  }
`;
export const createSource = /* GraphQL */ `
  mutation CreateSource(
    $input: CreateSourceInput!
    $condition: ModelSourceConditionInput
  ) {
    createSource(input: $input, condition: $condition) {
      source_id
      type
      name
      create_at
      survey_id
      provider
      gp_guid
      error
      status
      controlled_patient_count
      survey {
        id
        type
        content
        name
      }
    }
  }
`;
export const updateSource = /* GraphQL */ `
  mutation UpdateSource(
    $input: UpdateSourceInput!
    $condition: ModelSourceConditionInput
  ) {
    updateSource(input: $input, condition: $condition) {
      source_id
      type
      name
      create_at
      survey_id
      provider
      gp_guid
      error
      status
      controlled_patient_count
      survey {
        id
        type
        content
        name
      }
    }
  }
`;
export const deleteSource = /* GraphQL */ `
  mutation DeleteSource(
    $input: DeleteSourceInput!
    $condition: ModelSourceConditionInput
  ) {
    deleteSource(input: $input, condition: $condition) {
      source_id
      type
      name
      create_at
      survey_id
      provider
      gp_guid
      error
      status
      controlled_patient_count
      survey {
        id
        type
        content
        name
      }
    }
  }
`;
export const createControlledPatient = /* GraphQL */ `
  mutation CreateControlledPatient(
    $input: CreateControlledPatientInput!
    $condition: ModelControlledPatientConditionInput
  ) {
    createControlledPatient(input: $input, condition: $condition) {
      patient_guid
      source_id
      version
      nhs_number
      gp_guid
      gp_ods_code
      gp_login
      created_at
      survey_answered
      status
      initial_status
      survey_id
      survey_type
      survey_response
      hash
      control_level
      processed_response
      patient_ods_code
      source {
        source_id
        type
        name
        create_at
        survey_id
        provider
        gp_guid
        error
        status
        controlled_patient_count
        survey {
          id
          type
          content
          name
        }
      }
      survey {
        id
        type
        content
        name
      }
      notifications {
        items {
          id
          gp_guid
          patient_guid
          source_id
          sent_on
          channel
          type
          sent_successfully
        }
        nextToken
      }
      patient {
        patient_guid
        version
        gp_guid
        doctor_guid
        given_name
        surname
        nhs_number
        email
        phone_number
        device_token
        medloop_sub_id
        communication_channel
        birthdate
        sex
        doctor {
          doctor_guid
          version
          contract_start_date
          given_name
          job_category_code
          job_category_name
          gp_guid
          surname
          title
        }
        gp {
          gp_guid
          name
          version
          ods_code
          login
          provider
          gp_contact_number
          api_key
        }
      }
      gp {
        gp_guid
        name
        version
        ods_code
        login
        provider
        gp_contact_number
        api_key
      }
    }
  }
`;
export const updateControlledPatient = /* GraphQL */ `
  mutation UpdateControlledPatient(
    $input: UpdateControlledPatientInput!
    $condition: ModelControlledPatientConditionInput
  ) {
    updateControlledPatient(input: $input, condition: $condition) {
      patient_guid
      source_id
      version
      nhs_number
      gp_guid
      gp_ods_code
      gp_login
      created_at
      survey_answered
      status
      initial_status
      survey_id
      survey_type
      survey_response
      hash
      control_level
      processed_response
      patient_ods_code
      source {
        source_id
        type
        name
        create_at
        survey_id
        provider
        gp_guid
        error
        status
        controlled_patient_count
        survey {
          id
          type
          content
          name
        }
      }
      survey {
        id
        type
        content
        name
      }
      notifications {
        items {
          id
          gp_guid
          patient_guid
          source_id
          sent_on
          channel
          type
          sent_successfully
        }
        nextToken
      }
      patient {
        patient_guid
        version
        gp_guid
        doctor_guid
        given_name
        surname
        nhs_number
        email
        phone_number
        device_token
        medloop_sub_id
        communication_channel
        birthdate
        sex
        doctor {
          doctor_guid
          version
          contract_start_date
          given_name
          job_category_code
          job_category_name
          gp_guid
          surname
          title
        }
        gp {
          gp_guid
          name
          version
          ods_code
          login
          provider
          gp_contact_number
          api_key
        }
      }
      gp {
        gp_guid
        name
        version
        ods_code
        login
        provider
        gp_contact_number
        api_key
      }
    }
  }
`;
export const deleteControlledPatient = /* GraphQL */ `
  mutation DeleteControlledPatient(
    $input: DeleteControlledPatientInput!
    $condition: ModelControlledPatientConditionInput
  ) {
    deleteControlledPatient(input: $input, condition: $condition) {
      patient_guid
      source_id
      version
      nhs_number
      gp_guid
      gp_ods_code
      gp_login
      created_at
      survey_answered
      status
      initial_status
      survey_id
      survey_type
      survey_response
      hash
      control_level
      processed_response
      patient_ods_code
      source {
        source_id
        type
        name
        create_at
        survey_id
        provider
        gp_guid
        error
        status
        controlled_patient_count
        survey {
          id
          type
          content
          name
        }
      }
      survey {
        id
        type
        content
        name
      }
      notifications {
        items {
          id
          gp_guid
          patient_guid
          source_id
          sent_on
          channel
          type
          sent_successfully
        }
        nextToken
      }
      patient {
        patient_guid
        version
        gp_guid
        doctor_guid
        given_name
        surname
        nhs_number
        email
        phone_number
        device_token
        medloop_sub_id
        communication_channel
        birthdate
        sex
        doctor {
          doctor_guid
          version
          contract_start_date
          given_name
          job_category_code
          job_category_name
          gp_guid
          surname
          title
        }
        gp {
          gp_guid
          name
          version
          ods_code
          login
          provider
          gp_contact_number
          api_key
        }
      }
      gp {
        gp_guid
        name
        version
        ods_code
        login
        provider
        gp_contact_number
        api_key
      }
    }
  }
`;
export const createSurvey = /* GraphQL */ `
  mutation CreateSurvey(
    $input: CreateSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    createSurvey(input: $input, condition: $condition) {
      id
      type
      content
      name
    }
  }
`;
export const updateSurvey = /* GraphQL */ `
  mutation UpdateSurvey(
    $input: UpdateSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    updateSurvey(input: $input, condition: $condition) {
      id
      type
      content
      name
    }
  }
`;
export const deleteSurvey = /* GraphQL */ `
  mutation DeleteSurvey(
    $input: DeleteSurveyInput!
    $condition: ModelSurveyConditionInput
  ) {
    deleteSurvey(input: $input, condition: $condition) {
      id
      type
      content
      name
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification(
    $input: CreateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    createNotification(input: $input, condition: $condition) {
      id
      gp_guid
      patient_guid
      source_id
      sent_on
      channel
      type
      sent_successfully
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification(
    $input: UpdateNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    updateNotification(input: $input, condition: $condition) {
      id
      gp_guid
      patient_guid
      source_id
      sent_on
      channel
      type
      sent_successfully
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification(
    $input: DeleteNotificationInput!
    $condition: ModelNotificationConditionInput
  ) {
    deleteNotification(input: $input, condition: $condition) {
      id
      gp_guid
      patient_guid
      source_id
      sent_on
      channel
      type
      sent_successfully
    }
  }
`;
export const createLocation = /* GraphQL */ `
  mutation CreateLocation(
    $input: CreateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    createLocation(input: $input, condition: $condition) {
      staff_id
      gp_guid
      post_code
      address
      city
      name
      phone
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation(
    $input: UpdateLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    updateLocation(input: $input, condition: $condition) {
      staff_id
      gp_guid
      post_code
      address
      city
      name
      phone
    }
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation(
    $input: DeleteLocationInput!
    $condition: ModelLocationConditionInput
  ) {
    deleteLocation(input: $input, condition: $condition) {
      staff_id
      gp_guid
      post_code
      address
      city
      name
      phone
    }
  }
`;
export const createSlots = /* GraphQL */ `
  mutation CreateSlots(
    $input: CreateSlotsInput!
    $condition: ModelSlotsConditionInput
  ) {
    createSlots(input: $input, condition: $condition) {
      id
      gp_guid
      year
      month
      day
      hour
      minute
      staff_id
      duration
      patient_guid
      patient_hash
      version
      location_details
      reason
      status
      title
      patient_ods_code
      comments
      location {
        staff_id
        gp_guid
        post_code
        address
        city
        name
        phone
      }
    }
  }
`;
export const updateSlots = /* GraphQL */ `
  mutation UpdateSlots(
    $input: UpdateSlotsInput!
    $condition: ModelSlotsConditionInput
  ) {
    updateSlots(input: $input, condition: $condition) {
      id
      gp_guid
      year
      month
      day
      hour
      minute
      staff_id
      duration
      patient_guid
      patient_hash
      version
      location_details
      reason
      status
      title
      patient_ods_code
      comments
      location {
        staff_id
        gp_guid
        post_code
        address
        city
        name
        phone
      }
    }
  }
`;
export const deleteSlots = /* GraphQL */ `
  mutation DeleteSlots(
    $input: DeleteSlotsInput!
    $condition: ModelSlotsConditionInput
  ) {
    deleteSlots(input: $input, condition: $condition) {
      id
      gp_guid
      year
      month
      day
      hour
      minute
      staff_id
      duration
      patient_guid
      patient_hash
      version
      location_details
      reason
      status
      title
      patient_ods_code
      comments
      location {
        staff_id
        gp_guid
        post_code
        address
        city
        name
        phone
      }
    }
  }
`;
export const createPatient = /* GraphQL */ `
  mutation CreatePatient(
    $input: CreatePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    createPatient(input: $input, condition: $condition) {
      patient_guid
      version
      gp_guid
      doctor_guid
      given_name
      surname
      nhs_number
      email
      phone_number
      device_token
      medloop_sub_id
      communication_channel
      birthdate
      sex
      doctor {
        doctor_guid
        version
        contract_start_date
        given_name
        job_category_code
        job_category_name
        gp_guid
        surname
        title
        patients {
          nextToken
        }
        gp {
          gp_guid
          name
          version
          ods_code
          login
          provider
          gp_contact_number
          api_key
        }
      }
      gp {
        gp_guid
        name
        version
        ods_code
        login
        provider
        gp_contact_number
        api_key
      }
    }
  }
`;
export const updatePatient = /* GraphQL */ `
  mutation UpdatePatient(
    $input: UpdatePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    updatePatient(input: $input, condition: $condition) {
      patient_guid
      version
      gp_guid
      doctor_guid
      given_name
      surname
      nhs_number
      email
      phone_number
      device_token
      medloop_sub_id
      communication_channel
      birthdate
      sex
      doctor {
        doctor_guid
        version
        contract_start_date
        given_name
        job_category_code
        job_category_name
        gp_guid
        surname
        title
        patients {
          nextToken
        }
        gp {
          gp_guid
          name
          version
          ods_code
          login
          provider
          gp_contact_number
          api_key
        }
      }
      gp {
        gp_guid
        name
        version
        ods_code
        login
        provider
        gp_contact_number
        api_key
      }
    }
  }
`;
export const deletePatient = /* GraphQL */ `
  mutation DeletePatient(
    $input: DeletePatientInput!
    $condition: ModelPatientConditionInput
  ) {
    deletePatient(input: $input, condition: $condition) {
      patient_guid
      version
      gp_guid
      doctor_guid
      given_name
      surname
      nhs_number
      email
      phone_number
      device_token
      medloop_sub_id
      communication_channel
      birthdate
      sex
      doctor {
        doctor_guid
        version
        contract_start_date
        given_name
        job_category_code
        job_category_name
        gp_guid
        surname
        title
        patients {
          nextToken
        }
        gp {
          gp_guid
          name
          version
          ods_code
          login
          provider
          gp_contact_number
          api_key
        }
      }
      gp {
        gp_guid
        name
        version
        ods_code
        login
        provider
        gp_contact_number
        api_key
      }
    }
  }
`;
export const createDoctor = /* GraphQL */ `
  mutation CreateDoctor(
    $input: CreateDoctorInput!
    $condition: ModelDoctorConditionInput
  ) {
    createDoctor(input: $input, condition: $condition) {
      doctor_guid
      version
      contract_start_date
      given_name
      job_category_code
      job_category_name
      gp_guid
      surname
      title
      patients {
        items {
          patient_guid
          version
          gp_guid
          doctor_guid
          given_name
          surname
          nhs_number
          email
          phone_number
          device_token
          medloop_sub_id
          communication_channel
          birthdate
          sex
        }
        nextToken
      }
      gp {
        gp_guid
        name
        version
        ods_code
        login
        provider
        gp_contact_number
        api_key
      }
    }
  }
`;
export const updateDoctor = /* GraphQL */ `
  mutation UpdateDoctor(
    $input: UpdateDoctorInput!
    $condition: ModelDoctorConditionInput
  ) {
    updateDoctor(input: $input, condition: $condition) {
      doctor_guid
      version
      contract_start_date
      given_name
      job_category_code
      job_category_name
      gp_guid
      surname
      title
      patients {
        items {
          patient_guid
          version
          gp_guid
          doctor_guid
          given_name
          surname
          nhs_number
          email
          phone_number
          device_token
          medloop_sub_id
          communication_channel
          birthdate
          sex
        }
        nextToken
      }
      gp {
        gp_guid
        name
        version
        ods_code
        login
        provider
        gp_contact_number
        api_key
      }
    }
  }
`;
export const deleteDoctor = /* GraphQL */ `
  mutation DeleteDoctor(
    $input: DeleteDoctorInput!
    $condition: ModelDoctorConditionInput
  ) {
    deleteDoctor(input: $input, condition: $condition) {
      doctor_guid
      version
      contract_start_date
      given_name
      job_category_code
      job_category_name
      gp_guid
      surname
      title
      patients {
        items {
          patient_guid
          version
          gp_guid
          doctor_guid
          given_name
          surname
          nhs_number
          email
          phone_number
          device_token
          medloop_sub_id
          communication_channel
          birthdate
          sex
        }
        nextToken
      }
      gp {
        gp_guid
        name
        version
        ods_code
        login
        provider
        gp_contact_number
        api_key
      }
    }
  }
`;
export const createGp = /* GraphQL */ `
  mutation CreateGp($input: CreateGPInput!, $condition: ModelGPConditionInput) {
    createGP(input: $input, condition: $condition) {
      gp_guid
      name
      version
      ods_code
      login
      provider
      gp_contact_number
      api_key
    }
  }
`;
export const updateGp = /* GraphQL */ `
  mutation UpdateGp($input: UpdateGPInput!, $condition: ModelGPConditionInput) {
    updateGP(input: $input, condition: $condition) {
      gp_guid
      name
      version
      ods_code
      login
      provider
      gp_contact_number
      api_key
    }
  }
`;
export const deleteGp = /* GraphQL */ `
  mutation DeleteGp($input: DeleteGPInput!, $condition: ModelGPConditionInput) {
    deleteGP(input: $input, condition: $condition) {
      gp_guid
      name
      version
      ods_code
      login
      provider
      gp_contact_number
      api_key
    }
  }
`;
