import {Button, FormControl, Grid, LinearProgress, MenuItem, Paper, Select, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {API, Auth, graphqlOperation, Storage} from "aws-amplify";
import moment from "moment";
import {useSnackbar} from "notistack";
import React, {useEffect, useMemo, useState} from "react";
import Link from "@material-ui/core/Link";
import {listSurveys, sourceByGp} from "../graphql/queries";
import SourceTable from "../components/SourceTable.js";
import {DATE_TIME_FORMAT} from "../utils/constants";
import PublishIcon from "@material-ui/icons/Publish";
import CheckCircleOutlineIcon from "@material-ui/icons/CheckCircleOutline";
import SyncIcon from "@material-ui/icons/Sync";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles(theme => ({
	root: {
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		backgroundColor: theme.palette.background.paper,
		height: "100%",
	},
	inline: {
		display: "inline",
	},
	avatar: {
		backgroundColor: theme.palette.primary.main, // done
	},
	paper: {
		padding: theme.spacing(2, 4),
		width: "100%",
		boxShadow: "unset",
	},
	buttonWrap: {
		textAlign: "center",
	},
	tableWrapper: {
		paddingTop: theme.spacing(2.5),
	},
	table: {
		overflow: "auto",
	},
	header: {
		fontSize: 24,
		fontWeight: "500",
		color: theme.palette.secondary.dark,
	},
	formControl: {
		borderRadius: theme.spacing(1),
	},
	content: {
		paddingTop: theme.spacing(3),
	},
	uploadWrapper: {
		flexDirection: "row",
		justifyContent: "space-between",
		paddingTop: theme.spacing(2),
	},
	uploadedfile: {
		paddingLeft: theme.spacing(2),
		fontSize: 16,
		fontWeight: "500",
		lineHeight: "1.57",
	},
	sumbitButton: {
		padding: theme.spacing(0.75, 3),
		borderRadius: theme.spacing(1),
		textTransform: "none",
	},
	button: {
		borderRadius: theme.spacing(0.75),
		backgroundColor: "#e5e5ee",
		textTransform: "none",
	},
	tableHeader: {
		padding: theme.spacing(1.5, 0),
		fontSize: 16,
		fontWeight: "500",
		color: theme.palette.secondary.dark,
	},
	chooseFile: {
		display: "flex",
	},
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

const CSVUploader = () => {
	const {enqueueSnackbar} = useSnackbar();
	const classes = useStyles();

	const [file, setFile] = useState(null);
	const [isUploading, setIsUploading] = useState(false);
	const [refreshSources, setRefreshSources] = useState(false);
	const [surveys, setSurveys] = useState([]);
	const [selectedSurvey, setSelectedSurvey] = useState(null);
	const [sourcesList, setSourceList] = useState([]);
	const [sourcesLoading, setSourcesLoading] = useState(false);
	const [user, setUser] = useState(null);

	useEffect(() => {
		const fetchSurveys = async () => {
			try {
				setIsUploading(true);
				const response = await API.graphql(graphqlOperation(listSurveys));
				const surveys = response.data.listSurveys.items;
				setSurveys(surveys);
				setSelectedSurvey(surveys[0]);
			} catch (error) {
				console.log(error);
				enqueueSnackbar("There was an error while fetching the surveys please try again.", {variant: "error"});
			} finally {
				setIsUploading(false);
			}
		};
		fetchSurveys();
	}, [enqueueSnackbar]);

	useEffect(() => {
		if (user) return;
		const getUser = async () => {
			try {
				const _user = await Auth.currentAuthenticatedUser();
				setUser(_user);
			} catch (error) {
				console.log(error);
				enqueueSnackbar("Error occurred. Please try again", {variant: "error"});
			}
		};
		getUser();
	}, [enqueueSnackbar, user]);

	const getDate = name => {
		const dateArr = name.split("-");
		let date;
		if (dateArr && dateArr.length >= 3) {
			date = new Date(dateArr[0], dateArr[1] - 1, dateArr[2]);
		} else if (dateArr && dateArr.length >= 6) {
			date = new Date(dateArr[0], dateArr[1] - 1, dateArr[2], dateArr[3], dateArr[4], dateArr[5]);
		}
		return date ? date : "";
	};

	useEffect(() => {
		if (!user) return;
		const getSources = async () => {
			try {
				setSourcesLoading(true);
				console.log(user.attributes["custom:gp_guid"]);
				const result = await API.graphql(
					graphqlOperation(sourceByGp, {
						gp_guid: user.attributes["custom:gp_guid"],
					})
				);
				const list = result.data.sourceByGP.items.map(item => {
					item.fileName = decodeURIComponent(item.name);
					return item;
				});
				try {
					list.sort((a, b) => {
						return moment(b.create_at) - moment(a.create_at);
					});
				} catch (error) {
					console.log(error);
				}
				setSourceList(list);
			} catch (error) {
				console.log(error);
				enqueueSnackbar(
					"There was an error while fetching the source list. Please try again, Please try again, if the problem persists, contanct support@medloop.co",
					{variant: "error"}
				);
			} finally {
				setSourcesLoading(false);
			}
		};
		getSources();
	}, [enqueueSnackbar, user, refreshSources]);

	const handleSurveyChange = event => {
		setSelectedSurvey(event.target.value);
	};

	const handleFileChange = event => {
		const {files} = event.target;
		setFile(files[0]);
		// if(!validateExistingFile(files[0])) {
		// 	setFile(files[0]);
		// } else {
		// 	enqueueSnackbar("File already exists.", {variant: "error"});
		// }
	};

	const validateExistingFile = file => {
		let fileName = file.name.split(".")[0];
		let sourceNameList = sourcesList.map(source => decodeURIComponent(source.name) + " - " + moment(source.create_at).format("DD/MM/yyyy"));
		let currentFile = decodeURIComponent(fileName) + " - " + moment().format("DD/MM/yyyy");
		return sourceNameList.includes(currentFile);
	};

	const handleFileClick = event => {
		event.target.value = "";
	};

	const onSubmit = async () => {
		try {
			setIsUploading(true);
			const user = await Auth.currentAuthenticatedUser();

			let fileName = file.name;

			const fileNameDatePart = Date.now();

			fileName = fileName.replace(/\s/g, "-");
			fileName = fileName.split(".");
			fileName.pop();
			fileName = `${fileName.join(".")}.${fileNameDatePart}.csv`;

			const provider = "EMIS";
			const result = await Storage.put(
				`${provider}/${user.attributes["custom:gp_guid"]}/${selectedSurvey.type}/${selectedSurvey.id}/${fileName}`,
				file,
				{level: "protected", contentType: "text/csv"}
			);
			const {key} = result;
			setFile(null);
			setTimeout(function() {
				setRefreshSources(!refreshSources);
			}, 3000); // adding some delay before refreshing the list
			enqueueSnackbar(`File Upload was successfull. File name uploaded: ${key}`, {variant: "success"});
		} catch (error) {
			console.log(error);
			enqueueSnackbar("There was an error while uploading the file. Please try again.", {variant: "error"});
		} finally {
			setIsUploading(false);
		}
	};

	const getStatusIcon = row => {
		let validationErrors = [];
		let headerErrors = [];
		let parsedError;
		try {
			parsedError = JSON.parse(row.original.error);
			parsedError.forEach(e => {
				e.errors.forEach(err => {
					validationErrors.push(err.key);
				});
			});
		} catch (error) {
			if (row.original.error !== "") {
				headerErrors.push(row.original.error);
			}
		}
		if (row.original.status) {
			switch (row.original.status) {
				case "PROCESSED":
					return <CheckCircleOutlineIcon fontSize="small" color="primary" />;
				case "UPLOADED":
					return <SyncIcon fontSize="small" color="secondary" />;
				case "ERROR":
					let errorTooltipText =
						validationErrors.length > 0
							? `${validationErrors.join(",")} ${validationErrors.length > 1 ? "are" : "is"} incorrectly formatted.`
							: headerErrors;
					return (
						<Tooltip title={errorTooltipText}>
							<ErrorOutlineIcon fontSize="small" color="error" />
						</Tooltip>
					);
				default:
					return <CheckCircleOutlineIcon fontSize="small" color="secondary" />;
			}
		}
		return "";
	};

	const columns = useMemo(() => {
		return [
			{
				Header: "File name",
				accessor: "fileName",
				numeric: false,
				disablePadding: false,
				sortType: (a, b) => {
					const A = a.original.fileName.toLowerCase();
					const B = b.original.fileName.toLowerCase();
					if (A < B) return -1;
					else if (A > B) return 1;
					else return 0;
				},
			},
			{
				Header: "Upload date",
				accessor: "uploadedDate",
				numeric: false,
				disablePadding: false,
				Cell: ({row}) => {
					return row.original.create_at ? moment(row.original.create_at).format(DATE_TIME_FORMAT) : "";
				},
				sortInverted: true,
			},
			{
				Header: "Status",
				accessor: "status",
				numeric: false,
				disablePadding: false,
			},
			{
				Header: "",
				accessor: "icon",
				numeric: false,
				align: "right",
				size: "small",
				disablePadding: false,
				disableSortBy: true,
				Cell: ({row}) => {
					return getStatusIcon(row);
				},
			},
		];
	});

	return (
		<div>
			<Grid container justify="center" spacing={4} className={classes.root}>
				<Grid item md={8}>
					{isUploading && <LinearProgress variant="indeterminate" color="primary" />}
					<Paper className={classes.paper}>
						<Grid container direction="row" justify="space-between" spacing={4}>
							<Grid item>
								<Typography component="h3" className={classes.header} gutterBottom>
									Patient Processor
								</Typography>
								<Grid item md={12}>
									<FormControl fullWidth focused margin={"dense"}>
										<Select
											MenuProps={MenuProps}
											fullWidth
											variant="outlined"
											id="select-survey"
											value={selectedSurvey ? selectedSurvey : "Select a survey"}
											onChange={handleSurveyChange}
											className={classes.formControl}
										>
											{surveys.map(survey => (
												<MenuItem key={survey.id} value={survey}>
													{survey.name}
												</MenuItem>
											))}
										</Select>
									</FormControl>
								</Grid>
							</Grid>
							<Grid item>
								<Typography>
									<Link color="secondary" href={`${process.env.PUBLIC_URL}/patient-list-template.csv`}>
										Download CSV Template
									</Link>
								</Typography>
							</Grid>
						</Grid>
						<Grid container className={classes.content}>
							<Grid container>
								<Typography component="p"> Upload data in a CSV file format to process patients:</Typography>
								<Grid container className={classes.uploadWrapper}>
									<Grid item className={classes.chooseFile} alignItems="center">
										<input
											accept=".csv"
											onChange={handleFileChange}
											onClick={handleFileClick}
											className={classes.input}
											style={{display: "none"}}
											id="raised-button-file"
											type="file"
										/>
										<label htmlFor="raised-button-file">
											<Button variant="contained" disableElevation color={"grey"} component="div" className={classes.button}>
												Choose CSV File
											</Button>
										</label>
										<Typography component="span" variant="subtitle1" className={classes.uploadedfile}>
											{file ? file.name : "No file chosen"}
										</Typography>
									</Grid>
									<Grid item className={classes.buttonWrap}>
										<Button
											variant="contained"
											color="primary"
											disableElevation
											type="submit"
											onClick={onSubmit}
											endIcon={<PublishIcon />}
											className={classes.sumbitButton}
											disabled={!(file && selectedSurvey) || isUploading}
										>
											Upload
										</Button>
									</Grid>
								</Grid>
							</Grid>
						</Grid>
						<Grid className={classes.tableWrapper}>
							<Typography className={classes.tableHeader}>Past Uploads</Typography>
							{sourcesLoading && <LinearProgress variant="indeterminate" color="primary" />}
							<SourceTable columns={columns} data={sourcesList} className={classes.table}></SourceTable>
						</Grid>
					</Paper>
				</Grid>
			</Grid>
		</div>
	);
};

export default CSVUploader;
