import {Button, Grid, TextField} from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import "../App.css";
import {API, graphqlOperation, Auth} from "aws-amplify";
import {sendCustomSms} from "../graphql/mutations";
import {useSnackbar} from "notistack";

export default function SendMessageDialog(props) {
	const {handleClose, slot, isOpen} = props;
	const {enqueueSnackbar} = useSnackbar();

	const handleChange = prop => event => {
		event.preventDefault();
		setValues({...values, [prop]: event.target.value});
	};

	const [values, setValues] = React.useState({
		message: "",
	});

	const clearMessage = () => {
		setValues({message: ""});
	};

	const sendNotif = async (slot, values, type) => {
		// push to SQS instead
		try {
			await API.graphql(
				graphqlOperation(sendCustomSms, {
					paramsStringified: JSON.stringify({
						patientGuid: slot.patient_guid,
						gpGuid: slot.gp_guid,
						sourceId: slot.reason ? slot.reason : JSON.parse(slot.reason).source_id,
						type,
						content: values.message,
					}),
				})
			);
			enqueueSnackbar("SMS sucessfully sent.", {variant: "success"});
			handleClose();
			clearMessage();
		} catch (err) {
			console.log(`ERROR: ${JSON.stringify(err)}`);
			enqueueSnackbar("Error sending SMS", {variant: "error"});
		}
	};

	return (
		<Dialog
			PaperProps={{
				style: {
					margin: "0px",
				},
			}}
			open={isOpen}
			onClose={() => {
				handleClose();
				clearMessage();
			}}
			aria-labelledby="batchCreate"
		>
			<DialogTitle id="form-dialog-title">Message Patient via SMS</DialogTitle>
			<DialogContent>
				<Grid container spacing={1}>
					<Grid item md={12} xs={12}>
						<TextField
							autoFocus
							margin="dense"
							label="Message"
							multiline
							rows={5}
							value={values.message}
							onChange={handleChange("message")}
							fullWidth
							variant="outlined"
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button
					data-amplify-analytics-on="click"
					data-amplify-analytics-name="cancel_send_message"
					data-amplify-analytics-attrs={`gp_guid:${slot?.gp_guid},patien_guid:${slot?.patient_guid}`}
					onClick={() => {
						handleClose();
						clearMessage();
					}}
					color="primary"
				>
					Cancel
				</Button>

				<Button
					data-amplify-analytics-on="click"
					data-amplify-analytics-name="send_message"
					data-amplify-analytics-attrs={`gp_guid:${slot?.gp_guid},patien_guid:${slot?.patient_guid}`}
					onClick={() => {
						sendNotif(slot, values, "CUSTOM");
					}}
					color="primary"
					disabled={values.message.length === 0 || values.message.trim().length == 0}
				>
					Submit
				</Button>
			</DialogActions>
		</Dialog>
	);
}
