import React, {useEffect, useState} from "react";
import {useSnackbar} from "notistack";
import {makeStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import LinearProgress from "@material-ui/core/LinearProgress";
import {useTable, usePagination, useSortBy, useRowSelect} from "react-table";

import {NOTIFICATION_ALLOWED_COUNT} from "../../utils/constants";
import TablePaginationActions from "./tablePaginationActions";
import Checkbox from "@material-ui/core/Checkbox";

const useStyles = makeStyles(theme => ({
	root: {
		width: "100%",
		paddingTop: theme.spacing(0),
	},
	paper: {
		width: "100%",
		// marginBottom: theme.spacing(2),
	},
	table: {
		minWidth: 750,
	},
	tableCell: {
		// maxWidth: theme.spacing(15),
		overflow: "hidden",
		textOverflow: "ellipsis",
		whiteSpace: "nowrap",
	},
	visuallyHidden: {
		border: 0,
		clip: "rect(0 0 0 0)",
		height: 1,
		margin: -1,
		overflow: "hidden",
		padding: 0,
		position: "absolute",
		top: 20,
		width: 1,
	},
	container: {
		maxHeight: "calc(100vh - 324px);",
		minHeight: "calc(100vh - 400px);",
	},
}));

export default function EnhancedTable(props) {
	const classes = useStyles();
	const [data, setData] = useState([]);
	const {enqueueSnackbar} = useSnackbar();
	const {
		columns,
		fetchRecords,
		onRowClick,
		selectable,
		resetData,
		setSelectedRows,
		enableCustomSort,
		setOrderBy,
		setPagination,
		limit,
	} = props;

	const [currentToken, setCurrentToken] = useState(undefined);
	const [nextToken, setNextToken] = useState();
	const [previousTokens, setPreviousTokens] = useState([]);
	const [sortByObj, setSortByObj] = useState({});
	const [loading, setLoading] = React.useState(false);
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		nextPage,
		previousPage,
		setPageSize,
		getToggleAllPageRowsSelectedProps,
		selectedFlatRows,
		state: {pageIndex, pageSize, selectedRowIds},
	} = useTable(
		{
			columns,
			data,
			initialState: {
				pageIndex: 0,
				pageSize: limit,
				selectedRowIds: {},
			},
			manualSortBy: enableCustomSort || false,
			manualPagination: true, // Tell the usePagination
			// hook that we'll handle our own data fetching
			// This means we'll also have to provide our own
			// pageCount.
			pageCount: -1,
		},
		useSortBy,
		usePagination,
		useRowSelect
	);

	const hasNext = !!nextToken;
	const hasPrev = previousTokens.length;

	const next = () => {
		setPreviousTokens(prev => [...prev, currentToken]);
		setCurrentToken(nextToken);
		setNextToken(null);
		nextPage();
	};

	const prev = () => {
		setCurrentToken(previousTokens.pop());
		setPreviousTokens([...previousTokens]);
		setNextToken(null);
		previousPage();
	};

	const reset = () => {
		setCurrentToken(undefined);
		setPreviousTokens([]);
		setNextToken(null);
	};

	useEffect(() => {
		if (selectable && setSelectedRows) {
			setSelectedRows(
				selectedFlatRows
					.filter(row => {
						return row.original.numberOfInvites < 3 ? true : false;
					})
					.map(row => {
						return row.original;
					})
			);
		}
	}, [selectedRowIds, selectedFlatRows, selectable, setSelectedRows]);

	useEffect(() => {
		if (!enableCustomSort) return;
		setOrderBy(sortByObj);
	}, [enableCustomSort, setOrderBy, sortByObj]);

	useEffect(() => {
		let active = true;
		if (!pageSize) return;
		(async () => {
			try {
				setLoading(true);
				const response = await fetchRecords({pageSize, currentToken});
				const {data, nextToken} = response;
				if (!active) {
					return;
				}
				console.log(data);
				setData(data);
				setNextToken(nextToken);
			} catch (err) {
				console.log(err);
				enqueueSnackbar("Error loading patients.", {variant: "error"});
			} finally {
				setLoading(false);
			}
		})();

		return () => {
			active = false;
		};
	}, [currentToken, enqueueSnackbar, fetchRecords, pageSize, resetData]); // TODO: need to refactor

	const handleClick = (event, row) => {
		return onRowClick && onRowClick(row.original);
	};

	const handleChangeRowsPerPage = event => {
		setPageSize(parseInt(event.target.value, 10));
		setPagination({
			pageSize: parseInt(event.target.value, 10),
		});
		reset();
	};

	const isCheckoxDisabled = () => {
		return !(data && data.length > 0 && page.length > 0);
	};

	const selectionHeader = () => {
		return selectable ? (
			<TableCell className={classes.tableCell} key={"selection-header"}>
				<Checkbox disabled={isCheckoxDisabled()} {...getToggleAllPageRowsSelectedProps()} name="checkedB" color="secondary" />
			</TableCell>
		) : null;
	};

	const EnhancedTableHead = props => {
		const {headerGroup, classes, selectionHeader} = props;
		return (
			<TableHead>
				<TableRow {...headerGroup.getHeaderGroupProps()}>
					{selectionHeader()}
					{headerGroup.headers.map(column => (
						<TableCell
							{...column.getHeaderProps(column.getSortByToggleProps())}
							key={column.id}
							align={column.align ? column.align : column.numeric ? "right" : "left"}
							padding={column.disablePadding ? "none" : "default"}
							onClick={async () => {
								let temp = {};
								await column.toggleSortBy();
								if (column.isSorted) {
									temp[column.id] = column.isSortedDesc ? "desc" : "asc";
								} else {
									delete temp[column.id];
								}
								setSortByObj(temp);
							}}
						>
							<TableSortLabel
								active={column.isSorted}
								hideSortIcon={column.disableSortBy}
								direction={column.isSorted ? (column.isSortedDesc ? "desc" : "asc") : "asc"}
							>
								{column.render("Header")}
								{column.isSorted ? (
									<span className={classes.visuallyHidden}>{column.isSortedDesc ? "sorted descending" : "sorted ascending"}</span>
								) : null}
							</TableSortLabel>
						</TableCell>
					))}
				</TableRow>
			</TableHead>
		);
	};

	const checkRowDisability = row => {
		if (row.original.numberOfInvites >= NOTIFICATION_ALLOWED_COUNT) {
			return {};
		}
		return row.getToggleRowSelectedProps();
	};

	return (
		<div className={classes.root}>
			<Paper variant="outlined" className={classes.paper}>
				{loading && <LinearProgress color="primary" />}
				<TableContainer className={classes.container}>
					<Table
						{...getTableProps()}
						className={classes.table}
						aria-labelledby="tableTitle"
						size="medium"
						stickyHeader
						aria-label="enhanced table"
					>
						<EnhancedTableHead headerGroup={headerGroups[0]} classes={classes} rowCount={page.length} selectionHeader={selectionHeader} />
						<TableBody {...getTableBodyProps()}>
							{page.map((row, i) => {
								prepareRow(row);
								return (
									<TableRow {...row.getRowProps()} hover onClick={event => handleClick(event, row)} tabIndex={-1} key={row.original.id}>
										{selectable ? (
											<TableCell className={classes.tableCell} key={"selection-cell" + row.id}>
												<Checkbox
													{...checkRowDisability(row)}
													color="secondary"
													disabled={row.original.numberOfInvites >= NOTIFICATION_ALLOWED_COUNT}
												/>
											</TableCell>
										) : null}
										{row.cells.map(cell => {
											return (
												<TableCell
													{...cell.getCellProps()}
													className={classes.tableCell}
													key={cell.column.id + "_jsd" + cell.row.id + "1"}
													align={cell.column.align ? cell.column.align : cell.column.numeric ? "right" : "left"}
													size={cell.column.size ? cell.column.size : "medium"}
													padding={cell.column.disablePadding ? "none" : "default"}
													style={{backgroundColor: cell.column.backgroundColor ? cell.column.backgroundColor(row) : ""}}
												>
													{cell.render("Cell")}
												</TableCell>
											);
										})}
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[30, 50, 100]}
					component="div"
					count={-1}
					rowsPerPage={pageSize}
					page={pageIndex}
					onChangeRowsPerPage={handleChangeRowsPerPage}
					onChangePage={e => console.log("Page changed")}
					ActionsComponent={props => (
						<TablePaginationActions onNext={next} onPrevious={prev} hasNext={hasNext} hasPrevious={hasPrev} {...props} />
					)}
				/>
			</Paper>
		</div>
	);
}
