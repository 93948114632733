import {Box, Button, CircularProgress, Dialog, Grid, Link, Select, Slide, TextareaAutosize, Typography, TextField} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {makeStyles} from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import LaptopIcon from "@material-ui/icons/Laptop";
import LockIcon from "@material-ui/icons/Lock";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import Amplify, {API, Analytics} from "aws-amplify";
import React, {useEffect, useState} from "react";
import Badge from "react-download-android";
import BadgeIOS from "react-download-ios";
import AppointmentBooking from "../components/AppointmentBooking.js";
import questionaryImg from "../Questionaire.png";
import {ANDROID_LINK, FAQ_LINK, IOS_LINK, PRIVACY_NOTICE} from "../utils/constants";
import ICONS from "../utils/icons";
import withWidth, {isWidthUp} from "@material-ui/core/withWidth";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Mockup from "../mockup.jpg";
import appStore from "../app-store.png";
import playStore from "../play-store.png";
import AddToCalendarHOC from "react-add-to-calendar-hoc";
import moment from "moment";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {useSnackbar} from "notistack";
import {getDateFormatted} from "../utils/utils.js";

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		padding: theme.spacing(2),
		backgroundColor: theme.palette.background.paper,
	},
	inline: {
		display: "inline",
	},
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: 200,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
	},
	feedbackWrap: {
		border: "1px solid rgba(0,0,0,0.2)",
		margin: theme.spacing(1) + 2,
		borderRadius: 4,
		padding: theme.spacing(3),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	success: {
		fontSize: 48,
		marginTop: theme.spacing(2),
		color: "#20E8DB",
	},
	feedback: {
		marginBottom: theme.spacing(1),
		width: "94%",
		borderRadius: 6,
		borderColor: theme.palette.primary.light,
		padding: theme.spacing(1),
		fontSize: 16,
		borderWidth: 2,
	},
	thankyou: {
		fontSize: 24,
		color: "rgba(2, 9, 39, 0.87)",
		marginTop: theme.spacing(3),
	},
	feedbackContainer: {
		marginTop: theme.spacing(3),
		minHeight: theme.spacing(33),
		marginBottom: theme.spacing(3),
		display: "flex",

		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end",
	},
	feedbackSubmitted: {
		marginTop: theme.spacing(3),
		minHeight: theme.spacing(33),
		marginBottom: theme.spacing(3),
		display: "flex",

		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
	},
	feedbackSent: {
		fontSize: 32,
		marginLeft: theme.spacing(2),
	},
	submitButton: {
		marginTop: theme.spacing(2),
		backgroundColor: "rgba(46, 54, 90, 0.08)",
		color: "#2e365a",
	},
	link: {
		color: theme.palette.primary.dark,
		flex: "1",
	},
	info: {
		color: "rgb(138 138 138)",
		fontSize: 18,
	},
	hero: {
		background: "#1dcabf",
		padding: theme.spacing(3),
		borderRadius: theme.spacing(0.5),
		boxShadow: "0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20)",
	},
	loaderWrap: {
		height: "100vh",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
	},
	imgWrap: {
		textAlign: "center",
	},
	startButton: {
		fontSize: "20px",
		textTransform: "none",
		color: "white",
		maxWidth: "320px",
		minWidth: "320px",
	},
	bookingWrapper: {
		display: "flex",
		flexDirection: "column",
	},
	bookingRow: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		padding: theme.spacing(1.5, 0),
	},
	slots: {
		flex: 1,
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
	},
	headingAcc: {
		display: "block",
		fontSize: 22,
		fontWeight: theme.typography.fontWeightRegular,
	},
	sectionHeader: {
		fontSize: 24,
		color: "rgba(0, 0, 0, 0.87)",
	},
	infoText: {
		fontSize: 16,
		lineHeight: 1.5,
		letterSpacing: "0.15px",
		color: "#595e71",
		marginTop: theme.spacing(3),
	},
	subSection: {
		flex: 1,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
	},
	addTO: {
		backgroundColor: "rgba(46, 54, 90, 0.08)",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		// padding: theme.spacing(0, 1),
		borderRadius: "4px",
		color: "#2e365a",
		cursor: "pointer",
		marginTop: theme.spacing(3),
	},
	linkStyles: {
		display: "flex",
		flexDirection: "column",
		color: theme.palette.secondary.dark,
		padding: theme.spacing(0, 1),
	},
}));

const myCss = {
	header: "my-title sv-title sv-container-modern__title",
};

const AddToCalendarDropdown = AddToCalendarHOC(Button, List);

const SurveyPage = props => {
	const classes = useStyles();
	const [model, setModel] = useState(null);
	const [gpName, setGpName] = useState(null);
	const [gpOdsCode, setGpOdsCode] = useState("");
	const [dob, setDob] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const {enqueueSnackbar} = useSnackbar();
	const [started, setStarted] = React.useState(false);
	const [checked, setChecked] = React.useState(true);
	const [isCompleted, setIsCompleted] = useState(false);
	const [showAppointments, setShowAppointments] = useState(false);
	const [response, setResponse] = useState(null);
	const [responseOptions, setResponseOptions] = useState(null);
	const [locations, setLocations] = useState(null);
	const [booked, setBooked] = useState(false);
	const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false);
	const {
		match: {params},
	} = props;

	useEffect(() => {
		const getLocations = async () => {
			if (!dob) return;
			try {
				setLoading(true);
				const requestParams = {
					body: {
						birthdate: dob,
					},
				};
				let result = await API.get("appointments", `/slots/${params.hash}/locations`, requestParams);
				console.log(result);
				setLocations(result.locations);
				Analytics.record({
					name: "e_consult_get_locations_success",
					attributes: {ods_code: params.ods_code, patient_hash: params.hash, patient_dob: dob},
				});
			} catch (err) {
				console.log(err);
				Analytics.record({
					name: "e_consult_get_locations_error",
					attributes: {ods_code: params.ods_code, patient_hash: params.hash, patient_dob: dob},
				});
			} finally {
				setLoading(false);
			}
		};
		getLocations();
	}, [params, dob]);

	const onBookingComplete = async (slot, location) => {
		setBooked(true);
	};

	const showMedloopLogo = () => {
		if (isWidthUp("md", props.width)) {
			return (
				<Box textAlign="right">
					<Typography color="textPrimary">
						<Box display="flex" alignContent="center" justifyContent="flex-end">
							<span>In Collaboration with </span>
							<span style={{marginLeft: "8px"}}>{ICONS.MEDLOOP_LOGO_GREEN}</span>
						</Box>
					</Typography>
				</Box>
			);
		}
		return (
			<Box display="flex" flexDirection="row-reverse">
				{ICONS.MEDLOOP_LOGO_SMALL}
			</Box>
		);
	};

	const getSurveyTitle = () => {
		if (isWidthUp("md", props.width)) {
			return (
				<Box>
					<Typography component="div">
						<Box fontSize={16} color="textPrimary">
							<b>{gpName ? gpName : "GP Practice Name"}</b>&nbsp;&nbsp;&nbsp;&nbsp;{"E-Consultation"}
						</Box>
					</Typography>
				</Box>
			);
		}
		return (
			<Box>
				<Typography component="div">
					<Box fontSize={16} fontWeight="fontWeightBold" color="textPrimary">
						{gpName ? gpName : "GP Practice Name"}
					</Box>
				</Typography>
				<Typography component="div">
					<Box fontSize={16} color="textPrimary">
						{"E-Consultation"}
					</Box>
				</Typography>
			</Box>
		);
	};

	const handleDobChange = async value => {
		setDob(value);
	};

	const pageLayout = () => {
		if (loading) {
			return (
				<Box className={classes.loaderWrap}>
					<Box>{ICONS.MEDLOOP_LOGO_GREEN}</Box>
					<CircularProgress color="primary" />
				</Box>
			);
		}

		if (booked) {
			return (
				<div className={classes.root}>
					<Grid container spacing={3} justify="center">
						<Grid item md={6} xs={10}>
							{getSurveyTitle()}
						</Grid>
						<Grid item md={6} xs={2}>
							{showMedloopLogo()}
						</Grid>
						<Grid item md={8} xs={12}>
							<Typography component="div">
								<Box fontSize={16} fontWeight="fontWeightBold" color="textPrimary">
									You will receive a SMS with information about your Online Appointment
								</Box>
							</Typography>
						</Grid>
					</Grid>
				</div>
			);
		}

		return (
			<div className={classes.root}>
				<Grid container spacing={3} justify="center">
					<Grid item md={6} xs={10}>
						{getSurveyTitle()}
					</Grid>
					<Grid item md={6} xs={2}>
						{showMedloopLogo()}
					</Grid>
					<Grid item md={8} xs={12}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<KeyboardDatePicker
								// disableToolbar
								placeholder="Birthdate"
								fullWidth
								allowKeyboardControl={true}
								inputVariant="outlined"
								format="MM-dd-yyyy"
								margin="dense"
								id="date-picker-inline"
								value={dob}
								onChange={e => handleDobChange(e)}
								KeyboardButtonProps={{
									"aria-label": "change date",
								}}
								autoOk
							/>
						</MuiPickersUtilsProvider>
					</Grid>

					<Grid item md={8} xs={12}>
						{dob && locations ? (
							<AppointmentBooking
								setIsLoading={setLoading}
								dob={getDateFormatted(dob)}
								showSuccessToast={true}
								showErrorToast={true}
								locations={locations}
								hash={params.hash}
								isAdmin={false}
								onBookingComplete={onBookingComplete}
							/>
						) : (
							""
						)}
					</Grid>
					<Backdrop className={classes.backdrop} open={loading}>
						<CircularProgress color="inherit" />
					</Backdrop>
				</Grid>
			</div>
		);
	};

	return <Box pb={3}>{pageLayout()}</Box>;
};

export default withWidth()(SurveyPage);
