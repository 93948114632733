import {HEIGHT, WEIGHT} from "./constants.js";

export const getHeightInCM = (height, unit) => {
	if (unit === "ft") {
		try {
			var string = String(height);
			var numbers = string.match(/\d+/g).map(Number);
			if (numbers.length === 1) {
				return numbers[0] * 30.48;
			} else if (numbers.length === 2) {
				return numbers[0] * 30.48 + numbers[1] * 2.54;
			}
		} catch (err) {
			console.log(`error occured when parsing height ${err}`);
		}
		return height * 30.48;
	}
	if (unit === "in" || unit === "inch" || unit === "inches") return height * 2.54;
	return height;
};

export const getWeightInKG = (weight, unit) => {
	if (unit === "stones") return weight * 6.35029;
	if (unit === "pounds") return weight * 0.453592;
	return weight;
};

export const calculateBMI = (heightCM, weightKG) => {
	let heightM = heightCM / 100;
	return parseFloat(weightKG / (heightM * heightM)).toFixed(2);
};

export const calculateBMIFromProcessedResponse = processedResponse => {
	// 	if (!processedResponse || !processedResponse[HEIGHT] || !processedResponse[WEIGHT]) return "";
	// 	let height = getHeightInCM(processedResponse[HEIGHT].numeric_value.Value, processedResponse[HEIGHT].numeric_value.Units);
	// 	let weight = getWeightInKG(processedResponse[WEIGHT].numeric_value.Value, processedResponse[WEIGHT].numeric_value.Units);
	// 	return calculateBMI(height, weight);

	if (processedResponse && processedResponse["bmi_index"] && processedResponse["bmi_index"].numeric_value) {
		return processedResponse["bmi_index"].numeric_value.Value;
	}
	return "";
};

export const getDateFormatted = value => {
	return value.getFullYear() + "-" + ("0" + (value.getMonth() + 1)).slice(-2) + "-" + ("0" + value.getDate()).slice(-2);
};

export const getDateString = (value, connector = "-") => {
	return (
		("0" + value.getDate()).slice(-2) +
		connector +
		("0" + (value.getMonth() + 1)).slice(-2) +
		connector +
		value
			.getFullYear()
			.toString()
			.slice(-2)
	);
};

export const getStatus = row => {
	let colorClass = "";
	if (row.original.status) {
		switch (row.original.status) {
			case "IDENTIFED":
				colorClass = "toInviteStatus";
				break;
			case "NOTIFIED":
				colorClass = "invitedStatus";
				break;
			case "TO_REVIEW":
				colorClass = "reviewStatus";
				break;
			case "PROCESSED":
				colorClass = "doneStatus";
				break;
			default:
				break;
		}
	}
	return colorClass;
};

export const getActColor = value => {
	var actClass = "";
	if (value <= 20) {
		actClass = "below20Act";
	} else if (value <= 24) {
		actClass = "below24Act";
	} else {
		actClass = "above25Act";
	}
	return actClass;
};

export const getLinkLabel = source => {
	if (source && source.survey && source.survey.type) {
		return source.survey.type === "ASTHMA" ? "Survey Link" : "Booking Link";
	}
	return "Booking Link";
};
