export const controlledPatientBySource = /* GraphQL */ `
	query ControlledPatientBySource(
		$source_id: String
		$gp_guid: ModelIDKeyConditionInput
		$sortDirection: ModelSortDirection
		$filter: ModelControlledPatientFilterInput
		$limit: Int
		$nextToken: String
		$notificationFilter: ModelNotificationFilterInput
	) {
		controlledPatientBySource(
			source_id: $source_id
			gp_guid: $gp_guid
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				patient_guid
				source_id
				version
				nhs_number
				gp_guid
				gp_ods_code
				gp_login
				created_at
				survey_answered
				status
				survey_id
				survey_type
				survey_response
				hash
				control_level
				processed_response
				survey {
					id
					type
					name
				}
				patient {
					patient_guid
					version
					gp_guid
					doctor_guid
					given_name
					surname
					nhs_number
					email
					phone_number
					device_token
					medloop_sub_id
					communication_channel
					birthdate
					sex
				}
				notifications(filter: $notificationFilter) {
					items {
						patient_guid
						source_id
						sent_on
						channel
						type
						sent_successfully
					}
				}
			}
			nextToken
		}
	}
`;

export const onUpdateControlledPatient = /* GraphQL */ `
	subscription OnUpdateControlledPatient($gp_guid: String) {
		onUpdateControlledPatient(gp_guid: $gp_guid) {
			patient_guid
			status
		}
	}
`;

export const controlledPatientByStatus = /* GraphQL */ `
	query ControlledPatientByStatus(
		$status: ControlledPatientStatus
		$gp_guidSource_id: ModelControlledPatientByStatusCompositeKeyConditionInput
		$sortDirection: ModelSortDirection
		$filter: ModelControlledPatientFilterInput
		$limit: Int
		$nextToken: String
		$notificationFilter: ModelNotificationFilterInput
	) {
		controlledPatientByStatus(
			status: $status
			gp_guidSource_id: $gp_guidSource_id
			sortDirection: $sortDirection
			filter: $filter
			limit: $limit
			nextToken: $nextToken
		) {
			items {
				patient_guid
				source_id
				version
				nhs_number
				gp_guid
				gp_ods_code
				gp_login
				created_at
				survey_answered
				status
				survey_id
				survey_type
				survey_response
				hash
				control_level
				processed_response
				survey {
					id
					type
					name
				}
				patient {
					patient_guid
					version
					gp_guid
					doctor_guid
					given_name
					surname
					nhs_number
					email
					phone_number
					device_token
					medloop_sub_id
					communication_channel
					birthdate
					sex
				}
				source {
					name
					type
				}
				notifications(filter: $notificationFilter) {
					items {
						patient_guid
						source_id
						sent_on
						channel
						type
						sent_successfully
					}
				}
			}
			nextToken
		}
	}
`;

export const updateControlledPatientStatus = /* GraphQL */ `
	mutation UpdateControlledPatient($input: UpdateControlledPatientInput!, $condition: ModelControlledPatientConditionInput) {
		updateControlledPatient(input: $input, condition: $condition) {
			patient_guid
			source_id
			status
			gp_guid
			survey_answered
			survey_response
		}
	}
`;
