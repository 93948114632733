export const DATE_FORMAT = "DD MMM YYYY";
export const DATE_TIME_FORMAT = "DD MM YYYY, HH:mm:ss";

export const NOTIFICATION_ALLOWED_COUNT = 3;

export const ANDROID_LINK = "https://play.google.com/store/apps/details?id=com.medloop.v1&hl=en_GB";

export const IOS_LINK = "https://apps.apple.com/gb/app/medloop/id1459846548";

export const FAQ_LINK = "https://docs.google.com/document/d/1a0rgN6EFvK5OyAlecrifsCyaCivrudxuOcOQf6HOKGs/edit?ts=5fda24a3";

export const PRIVACY_NOTICE = "https://docs.google.com/document/d/1xGUU6mzFoXj_5PzKUJAZTCSkcOvBho3DGYBClxX4kzo/edit";

export const FEEDBACK_FORM_LINK = "https://docs.google.com/forms/d/e/1FAIpQLSfPUs-jtbAl8zAtmpwJ83zp1HfNLzhJwHqYmWzy4OhjjU3c8Q/viewform";

export const ACT_SCORE = "act_score";
export const AWARENESS = "asthma_self_awareness";
export const CONSULTATION = "consultation_title";
export const EXACERBATION = "exacerbation";
export const HEIGHT = "height";
export const ACTIVITIES = "limits_daily_activities";
export const SYMPTOMS = "night_symptoms";
export const SMOKING = "smoking_status";
export const BREATH = "shortness_of_breath";
export const WEIGHT = "weight";
export const RELIEVER = "use_of_reliever";
export const BMI = "BMI";
export const BMI_METER = "bmi_index";
export const PEAK_FLOW = "peak_flow_rate";
export const COVID_SURVEY_TYPE = "COVID";
export const ASTHMA_SURVEY_TYPE = "ASTHMA";
export const HOSPITAL_ADMISSION = "hospital_admission";

export const CONTROLLED_PATIENT_STATUS = {
	IDENTIFIED: "IDENTIFIED",
	NOTIFIED: "NOTIFIED",
	ANSWERED: "ANSWERED",
	ACTION_REQUIRED: "ACTION_REQUIRED",
	PROCESSED: "PROCESSED",
	MANUAL_CONTACT: "MANUAL_CONTACT",
	BOOKED: "BOOKED",
	DECLINED: "DECLINED",
	TO_REVIEW: "TO_REVIEW",
};

export const NOTIFICATION_TYPES = {
	INVITATION: "INVITATION",
	USER_BOOKED: "USER_BOOKED",
	ADMIN_MODIFIED: "ADMIN_MODIFIED",
	ADMIN_CANCELLED: "ADMIN_CANCELLED",
	CUSTOM: "CUSTOM",
	APPOINTMENT_REMINDER: "APPOINTMENT_REMINDER",
	USER_CANCELLED: "USER_CANCELLED",
	MODIFY_BOOKING: "MODIFY_BOOKING",
	ASTHMA_INVITATION: "ASTHMA_INVITATION",
};

export const CONTROLLED_PATIENT_STATUS_TRANSLATOR = {
	IDENTIFIED: "To invite",
	NOTIFIED: "Invited",
	TO_REVIEW: "To review",
	PROCESSED: "Done",
};

export const controlLevelText = {
	WELL_CONTROLLED: "Well controlled",
	CONTROLLED: "Moderately Controlled",
	VERY_POORLY_CONTROLLED: "Very Poorly controlled",
	POORLY_CONTROLLED: "Poorly controlled",
};

export const NOTIFICATION_ALLOWED_ON_PATIENT_STATUS = [CONTROLLED_PATIENT_STATUS.IDENTIFIED, CONTROLLED_PATIENT_STATUS.NOTIFIED];
