import {Box, Button, CircularProgress, Dialog, MenuItem, Grid, Link, Select, Slide, TextareaAutosize, Typography} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {makeStyles} from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import LaptopIcon from "@material-ui/icons/Laptop";
import LockIcon from "@material-ui/icons/Lock";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import Amplify, {API, Analytics} from "aws-amplify";
import React, {useEffect, useState} from "react";
import Badge from "react-download-android";
import BadgeIOS from "react-download-ios";
import * as Survey from "survey-react";
import "survey-react/survey.css";
import AppointmentBooking from "../components/AppointmentBooking.js";
import questionaryImg from "../Questionaire.png";
import {ANDROID_LINK, FAQ_LINK, IOS_LINK, PRIVACY_NOTICE} from "../utils/constants";
import ICONS from "../utils/icons";
import withWidth, {isWidthUp} from "@material-ui/core/withWidth";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Mockup from "../mockup.jpg";
import appStore from "../app-store.png";
import playStore from "../play-store.png";
import AddToCalendarHOC from "react-add-to-calendar-hoc";
import moment from "moment";

Survey.StylesManager.applyTheme("modern");

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		padding: theme.spacing(2),
		backgroundColor: theme.palette.background.paper,
	},
	inline: {
		display: "inline",
	},
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: 200,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
	},
	feedbackWrap: {
		border: "1px solid rgba(0,0,0,0.2)",
		margin: theme.spacing(1) + 2,
		borderRadius: 4,
		padding: theme.spacing(3),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	success: {
		fontSize: 48,
		marginTop: theme.spacing(2),
	},
	feedback: {
		marginBottom: theme.spacing(1),
		width: "94%",
		borderRadius: 6,
		borderColor: theme.palette.primary.light,
		padding: theme.spacing(1),
		fontSize: 16,
		borderWidth: 2,
	},
	thankyou: {
		fontSize: 24,
		color: "rgba(2, 9, 39, 0.87)",
		marginTop: theme.spacing(4),
	},
	feedbackContainer: {
		marginTop: theme.spacing(3),
		minHeight: theme.spacing(33),
		marginBottom: theme.spacing(3),
		display: "flex",

		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end",
	},
	feedbackSubmitted: {
		marginTop: theme.spacing(3),
		minHeight: theme.spacing(33),
		marginBottom: theme.spacing(3),
		display: "flex",

		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
	},
	feedbackSent: {
		fontSize: 32,
		marginLeft: theme.spacing(2),
	},
	submitButton: {
		marginTop: theme.spacing(2),
		backgroundColor: "rgba(46, 54, 90, 0.08)",
		color: "#2e365a",
	},
	link: {
		color: theme.palette.primary.dark,
		flex: "1",
	},
	info: {
		color: "rgb(138 138 138)",
		fontSize: 18,
	},
	hero: {
		background: "#1dcabf",
		padding: theme.spacing(3),
		borderRadius: theme.spacing(0.5),
		boxShadow: "0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20)",
	},
	loaderWrap: {
		height: "100vh",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
	},
	imgWrap: {
		textAlign: "center",
	},
	startButton: {
		fontSize: "20px",
		textTransform: "none",
		color: "white",
		maxWidth: "320px",
		minWidth: "320px",
	},
	bookingWrapper: {
		display: "flex",
		flexDirection: "column",
	},
	bookingRow: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		padding: theme.spacing(1.5, 0),
	},
	slots: {
		flex: 1,
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
	},
	headingAcc: {
		display: "block",
		fontSize: 22,
		fontWeight: theme.typography.fontWeightRegular,
	},
	sectionHeader: {
		fontSize: 24,
		color: "rgba(0, 0, 0, 0.87)",
	},
	infoText: {
		fontSize: 16,
		lineHeight: 1.5,
		letterSpacing: "0.15px",
		color: "#595e71",
		marginTop: theme.spacing(4),
	},
	subSection: {
		flex: 1,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
	},
	addTO: {
		backgroundColor: "rgba(46, 54, 90, 0.08)",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		// padding: theme.spacing(0, 1),
		borderRadius: "4px",
		color: "#2e365a",
		cursor: "pointer",
		marginTop: theme.spacing(3),
	},
	linkStyles: {
		display: "flex",
		flexDirection: "column",
		color: theme.palette.secondary.dark,
		padding: theme.spacing(0, 1),
	},
}));

const myCss = {
	header: "my-title sv-title sv-container-modern__title",
};

const AddToCalendarDropdown = AddToCalendarHOC(Button, List);

const EConsult = props => {
	const classes = useStyles();
	const [model, setModel] = useState(null);
	const [surveyName, setSurveyName] = useState(null);
	const [sourceId, setSourceId] = useState(null);
	const [dob, setDob] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [surveyValidation, setSurveyValidation] = useState(false);
	const [started, setStarted] = React.useState(false);
	const [checked, setChecked] = React.useState(true);
	const [isCompleted, setIsCompleted] = useState(false);
	const [showAppointments, setShowAppointments] = useState(false);
	const [response, setResponse] = useState(null);
	const [responseOptions, setResponseOptions] = useState(null);
	const [locations, setLocations] = useState(null);
	const [bookedSlots, setBookedSlots] = useState([]);
	const [feedBackSlot, setFeedBackSlot] = useState({slot: null, location: null});
	const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false);
	const {
		match: {params},
	} = props;

	const [language, setLanguage] = React.useState("en");

	const handleChange = event => {
		setLanguage(event.target.value);
	};

	useEffect(() => {
		if (!model) return;
		model.locale = language;
		model.render();
	}, [language, model]);

	useEffect(() => {
		setLoading(true);
		setModel(null);
		const getSurvey = async () => {
			console.log("get survey");
			try {
				let response = await API.get("surveys", `/surveys/econsult/${params.ods_code}`);
				let survey = new Survey.Model(response.survey_content); //should get the survey via the API response.
				setSourceId(response.source_id);
				console.log(response);
				setModel(survey);
				setSurveyName(response.survey_name);
				Analytics.record({
					name: "load_econsult_success",
					attributes: {ods_code: params.ods_code, survey: response.survey_name},
				});
			} catch (error) {
				console.log("error get survey");
				setError(error?.response?.data?.error);
				Analytics.record({
					name: "load_econsult_error",
					attributes: {ods_code: params.ods_code},
				});
			} finally {
				setLoading(false);
			}
		};
		getSurvey();
	}, [params]);

	//Submit Survey via API Gateway
	const onComplete = async (surveyResponse, options) => {
		//Write survey results into database
		console.log("submit survey");
		setLoading(true);
		try {
			const requestParams = {
				body: {
					survey_response: surveyResponse.data,
					source_id: sourceId,
				},
			};
			console.log(surveyResponse.data);
			console.log("API");
			await API.post("surveys", `/surveys/econsult/${params.ods_code}`, requestParams);
			setLoading(false);
			setIsCompleted(true);
			Analytics.record({
				name: "submit_econsult_success",
				attributes: {ods_code: params.ods_code},
			});
		} catch (error) {
			console.log(error);
			setModel(null);
			setError(error?.response?.data?.error);
			setLoading(false);
			Analytics.record({
				name: "submit_econsult_error",
				attributes: {ods_code: params.ods_code},
			});
		} finally {
			setLoading(false);
		}
	};

	const startSurvey = () => {
		setStarted(prev => !prev);
		setIsCompleted(false);
	};

	const showMedloopLogo = () => {
		if (isWidthUp("md", props.width)) {
			return (
				<Box textAlign="right">
					<Typography color="textPrimary">
						<Box display="flex" alignContent="center" justifyContent="flex-end">
							<span>In Collaboration with </span>
							<span style={{marginLeft: "8px"}}>{ICONS.MEDLOOP_LOGO_GREEN}</span>
						</Box>
					</Typography>
				</Box>
			);
		}
		return (
			<Box display="flex" flexDirection="row-reverse">
				{ICONS.MEDLOOP_LOGO_SMALL}
			</Box>
		);
	};

	const hideHelper = () => {
		setChecked(prev => !prev);
	};

	const getSurveyTitle = () => {
		if (isWidthUp("md", props.width)) {
			return (
				<Box>
					<Typography component="div">
						<Box fontSize={16} color="textPrimary">
							<b>{params?.ods_code ? params?.ods_code : "GP Practice Name"}</b>&nbsp;&nbsp;&nbsp;&nbsp;{surveyName}
						</Box>
					</Typography>
				</Box>
			);
		}
		return (
			<Box>
				<Typography component="div">
					<Box fontSize={16} fontWeight="fontWeightBold" color="textPrimary">
						{params?.ods_code ? params?.ods_code : "GP Practice Name"}
					</Box>
				</Typography>
				<Typography component="div">
					<Box fontSize={16} color="textPrimary">
						{surveyName}
					</Box>
				</Typography>
			</Box>
		);
	};

	const pageLayout = () => {
		if (loading) {
			return (
				<Box className={classes.loaderWrap}>
					<Box>{ICONS.MEDLOOP_LOGO_GREEN}</Box>
					<CircularProgress color="primary" />
				</Box>
			);
		}
		if (model) {
			return (
				<div className={classes.root}>
					<Grid container spacing={3} justify="center">
						<Grid item md={6} xs={10}>
							{getSurveyTitle()}
						</Grid>
						<Grid item md={6} xs={2}>
							{showMedloopLogo()}
						</Grid>
						<Grid item md={8} xs={12}>
							<Box>
								<Select labelId="demo-simple-select-label" id="demo-simple-select" value={language} onChange={handleChange}>
									<MenuItem value={"en"}>English</MenuItem>
									<MenuItem value={"de"}>Deutsch</MenuItem>
								</Select>
								<Survey.Survey showCompletedPage={false} model={model} css={myCss} onComplete={onComplete} />
							</Box>

							{isCompleted ? "Completed" : <div></div>}
						</Grid>
						<Backdrop className={classes.backdrop} open={surveyValidation}>
							<CircularProgress color="inherit" />
						</Backdrop>
					</Grid>
				</div>
			);
		}
		if (error) {
			return (
				<Box>
					<Typography variant="h3" gutterBottom>
						{error}
					</Typography>
					<Badge id="com.medloop.v1" />
					<BadgeIOS id="id1459846548" />
				</Box>
			);
		}
	};

	return <Box pb={3}>{pageLayout()}</Box>;
};

export default withWidth()(EConsult);
