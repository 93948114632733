import React from "react";
import IconButton from "@material-ui/core/IconButton";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import {makeStyles, useTheme} from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
	root: {
		flexShrink: 0,
		marginLeft: theme.spacing(2.5),
	},
}));

const TablePaginationActions = props => {
	const classes = useStyles();
	const theme = useTheme();
	const {hasNext, hasPrevious, onPrevious, onNext} = props;

	const handleBackButtonClick = event => {
		onPrevious();
	};

	const handleNextButtonClick = event => {
		onNext();
	};

	return (
		<div className={classes.root}>
			<IconButton onClick={handleBackButtonClick} disabled={!hasPrevious} aria-label="previous page">
				{theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
			</IconButton>
			<IconButton onClick={handleNextButtonClick} disabled={!hasNext} aria-label="next page">
				{theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
			</IconButton>
		</div>
	);
};

export default TablePaginationActions;
