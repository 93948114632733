import React, {useCallback, useState, useEffect} from "react";
import {useLocation} from "react-router-dom";

import {API, Auth, graphqlOperation} from "aws-amplify";
import {controlledPatientByStatus, onUpdateControlledPatient} from "../services/controlledPatient";
import {getGp} from "../graphql/queries";

import clsx from "clsx";
import {makeStyles, useTheme, fade} from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import Menu from "@material-ui/core/Menu";
import AccountCircle from "@material-ui/icons/AccountCircle";
import InsertEmoticonIcon from "@material-ui/icons/InsertEmoticon";

import {AmplifySignOut} from "@aws-amplify/ui-react";

import PublishIcon from "@material-ui/icons/Publish";
import ListIcon from "@material-ui/icons/List";
import Button from "@material-ui/core/Button";
import medloopLogo from "../icon-general-medlooplogo.svg";
import DateRangeIcon from "@material-ui/icons/DateRange";

import Badge from "@material-ui/core/Badge";
import MailIcon from "@material-ui/icons/Mail";
import {useSnackbar} from "notistack";
import {MenuItem} from "@material-ui/core";

import {useHistory} from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import CircularProgress from "@material-ui/core/CircularProgress";
import InputBase from "@material-ui/core/InputBase";

import SearchIcon from "@material-ui/icons/Search";
import FindInPageIcon from "@material-ui/icons/FindInPage";

const {FEEDBACK_FORM_LINK} = require("../utils/constants");

const drawerWidth = 240;

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		"& > *": {
			margin: theme.spacing(1),
		},
	},
	grow: {
		flexGrow: 1,
	},
	appBar: {
		backgroundColor: "#ffffff",
		zIndex: theme.zIndex.drawer + 1,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		color: "#000000",
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(["width", "margin"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	menuButton: {
		marginRight: 36,
	},
	hide: {
		display: "none",
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
		whiteSpace: "nowrap",
	},
	drawerOpen: {
		width: drawerWidth,
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	},
	drawerClose: {
		transition: theme.transitions.create("width", {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		overflowX: "hidden",
		width: theme.spacing(7) + 1,
		[theme.breakpoints.up("sm")]: {
			width: theme.spacing(9) + 1,
		},
	},
	toolbar: {
		display: "flex",
		alignItems: "center",
		justifyContent: "flex-end",
		padding: theme.spacing(0, 1),

		// necessary for content to be below app bar
		...theme.mixins.toolbar,
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
	},
	sectionDesktop: {
		display: "none",
		[theme.breakpoints.up("md")]: {
			display: "flex",
		},
		alignItems: "center",
	},
	practiceName: {
		fontSize: 24,
		marginRight: theme.spacing(1.5),
		fontWeight: "500",
		color: theme.palette.secondary.dark,
	},
	search: {
		position: "relative",
		borderRadius: theme.shape.borderRadius,
		backgroundColor: fade(theme.palette.common.white, 0.15),
		"&:hover": {
			backgroundColor: fade(theme.palette.common.white, 0.25),
		},
		marginLeft: 0,
		width: "100%",
		[theme.breakpoints.up("sm")]: {
			marginLeft: theme.spacing(1),
			width: "auto",
		},
	},
	inputInput: {
		padding: theme.spacing(1, 1, 1, 0),
		// vertical padding + font size from searchIcon
		paddingLeft: `calc(1em + ${theme.spacing(1)}px)`,
		transition: theme.transitions.create("width"),
		width: "100%",
		[theme.breakpoints.up("sm")]: {
			width: "25ch",
			"&:focus": {
				width: "30ch",
			},
		},
	},
	feedbackButton: {
		textTransform: "capitalize",
		backgroundColor: "rgba(26, 154, 146, 0.08)",
		color: "#1DCABF",
		"&:hover": {
			color: "#06a299",
			backgroundColor: "rgba(26, 154, 146, 0.08)",
		},
	},
	feedbackEmoji: {
		marginLeft: 4,
	},
}));

export default function MiniDrawer() {
	const location = useLocation();
	const classes = useStyles();
	const {enqueueSnackbar} = useSnackbar();
	const theme = useTheme();
	const [open, setOpen] = React.useState(false);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const isMenuOpen = Boolean(anchorEl);
	const [user, setUser] = useState(null);
	const [gp, setGp] = useState(null);
	const [numMessages, setNumMessages] = useState("0");

	let history = useHistory();
	const [openSearch, setOpenSearch] = useState(false);
	const [patients, setPatients] = useState([]);
	const [loading, setLoading] = useState(false);
	const [searchValue, setSearchValue] = useState(null);

	useEffect(() => {
		if (user) return;
		const getUser = async () => {
			try {
				const _user = await Auth.currentAuthenticatedUser();
				setUser(_user);
			} catch (error) {
				console.log(error);
				enqueueSnackbar("Error occurred. Please try again", {variant: "error"});
			} finally {
			}
		};
		getUser();
	}, [enqueueSnackbar, user]);

	//TODO trigger message number update.
	React.useEffect(() => {
		if (user) {
			const subscription = API.graphql({
				query: onUpdateControlledPatient,
				variables: {gp_guid: user.attributes["custom:gp_guid"]},
			}).subscribe({
				next: ({provider, value}) => {
					console.log("review update", value);
					if (value.data.onUpdateControlledPatient.status === "TO_REVIEW") {
						//very ugly way of controlling the num messages.
						enqueueSnackbar("New Patient Answer to review", {variant: "success"});
						setNumMessages(numMessages === "10+" ? numMessages : numMessages + 1);
					}
					if (value.data.onUpdateControlledPatient.status === "PROCESSED") {
						setNumMessages(numMessages === "10+" ? numMessages : numMessages - 1);
					}
					console.log("review update done", value);
				},
			});
			return () => subscription.unsubscribe();
		}
	}, [enqueueSnackbar, numMessages, user]);

	useEffect(() => {
		if (!user) return;
		const getPatientsInReview = async () => {
			const result = await API.graphql(
				graphqlOperation(controlledPatientByStatus, {
					status: "TO_REVIEW",
					gp_guidSource_id: {beginsWith: {gp_guid: user.attributes["custom:gp_guid"]}},
					limit: 10,
				})
			);
			setNumMessages(
				result.data.controlledPatientByStatus.items.length === 10 ? "10+" : result.data.controlledPatientByStatus.items.length
			);
		};
		getPatientsInReview();
	}, [enqueueSnackbar, user]);

	useEffect(() => {
		if (!user) return;
		const queryGp = async () => {
			const result = await API.graphql(
				graphqlOperation(getGp, {
					gp_guid: user.attributes["custom:gp_guid"],
					version: "V_0",
				})
			);
			console.log(result.data.getGP);
			setGp(result.data.getGP);
		};
		queryGp();
	}, [enqueueSnackbar, user]);

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const handleProfileMenuOpen = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleMenuClose = () => {
		setAnchorEl(null);
	};

	const handleFeedbackMenuOpen = () => {
		window.open(FEEDBACK_FORM_LINK, "");
	};

	const loadPatientByTerm = async term => {
		try {
			const params = {
				headers: {
					"m-emis-user": user.attributes["custom:emis_login"],
				},
				queryStringParameters: {
					term: term,
				},
			};
			let result = await API.get("pimi", `/pimi/${user.attributes["custom:gp_guid"]}/patients`, params);
			setPatients(result.filter(patient => patient.NhsNumber)); //We cannot get medical records for patients without NHS number (needs a update in the API)
		} catch (error) {
			enqueueSnackbar("Error fetching medications from emis, please try again later.", {variant: "error"});
		}
	};

	const searchPatients = async event => {
		let value = event.target.value;
		if (value.length >= 3 && !loading) {
			setLoading(true);
			await loadPatientByTerm(value);
			setLoading(false);
		}
	};

	const goToPatientDetails = (patient, value) => {
		if (!value) {
			return;
		}
		if (!value.NhsNumber) {
			alert("Invalid Patiet, missing NHS Number");
			return;
		}
		setSearchValue(null);
		history.push(`/dashboard/emr/${user.attributes["custom:gp_guid"]}/${value.NhsNumber.replace(/\s/g, "")}`);
	};

	const menuId = "primary-search-account-menu";
	const renderMenu = (
		<Menu
			anchorEl={anchorEl}
			anchorOrigin={{vertical: "top", horizontal: "right"}}
			id={menuId}
			keepMounted
			transformOrigin={{vertical: "top", horizontal: "right"}}
			open={isMenuOpen}
			onClose={handleMenuClose}
		>
			<MenuItem>{user?.attributes["email"]}</MenuItem>
			<MenuItem>{user?.attributes["custom:emis_login"]}</MenuItem>
			<AmplifySignOut
				style={{
					"--amplify-primary-color": "transparent",
					"--amplify-primary-tint": "transparent",
					"--amplify-primary-shade": "transparent",
					"--amplify-primary-contrast": "black",
				}}
			/>
		</Menu>
	);

	return (
		<React.Fragment>
			<CssBaseline />
			<AppBar
				position="fixed"
				className={clsx(classes.appBar, {
					[classes.appBarShift]: open,
				})}
			>
				<Toolbar>
					<IconButton
						// color="#000000"
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						edge="start"
						className={clsx(classes.menuButton, {
							[classes.hide]: open,
						})}
					>
						<MenuIcon />
					</IconButton>
					<img alt="medloop-logo" src={medloopLogo}></img>
					<div className={classes.grow} />
					{user?.signInUserSession?.accessToken?.payload["cognito:groups"]?.includes("admin") ? (
						<div className={classes.search}>
							<Autocomplete
								id="asynchronous-demo"
								style={{width: 300}}
								open={openSearch}
								onOpen={() => {
									setOpenSearch(true);
								}}
								onClose={() => {
									setOpenSearch(false);
								}}
								clearOnBlur
								getOptionSelected={(option, value) => option.DBID === value.DBID}
								onChange={(option, value) => goToPatientDetails(option, value)}
								getOptionLabel={patient => `${patient.FamilyName}, ${patient.FirstNames} - ${patient.NhsNumber}`}
								options={patients}
								loading={loading}
								value={searchValue}
								renderInput={params => (
									<InputBase
										ref={params.InputProps.ref}
										inputProps={params.inputProps}
										onChange={searchPatients}
										readOnly={loading}
										placeholder="Search Patient"
										classes={{
											root: classes.inputRoot,
											input: classes.inputInput,
										}}
										startAdornment={<SearchIcon color="primary" />}
										endAdornment={params.InputProps.endAdornment}
									/>
								)}
							/>
						</div>
					) : (
						""
					)}
					<div className={classes.grow} />
					<div className={classes.sectionDesktop}>
						<Typography className={classes.practiceName}>{gp?.name}</Typography>
						<Button
							data-amplify-analytics-on="click"
							data-amplify-analytics-name="gp_feedback"
							data-amplify-analytics-attrs={`ods_code:${user?.attributes["custom:gp_guid"]}`}
							variant="contained"
							onClick={handleFeedbackMenuOpen}
							className={classes.feedbackButton}
							color="primary"
							disableElevation
						>
							Feedback
							<InsertEmoticonIcon className={classes.feedbackEmoji} />
						</Button>
						<IconButton
							edge="end"
							aria-label="account of current user"
							aria-controls={menuId}
							aria-haspopup="true"
							onClick={handleProfileMenuOpen}
							// color="#000000"
						>
							<AccountCircle />
						</IconButton>
					</div>
					<IconButton
						data-amplify-analytics-on="click"
						data-amplify-analytics-name="mailbox"
						data-amplify-analytics-attrs={`ods_code:${user?.attributes["custom:gp_guid"]}`}
						component="a"
						href="#/dashboard/review"
					>
						<Badge badgeContent={numMessages} color="error">
							<MailIcon />
						</Badge>
					</IconButton>
				</Toolbar>
			</AppBar>
			{renderMenu}
			<Drawer
				variant="permanent"
				className={clsx(classes.drawer, {
					[classes.drawerOpen]: open,
					[classes.drawerClose]: !open,
				})}
				classes={{
					paper: clsx({
						[classes.drawerOpen]: open,
						[classes.drawerClose]: !open,
					}),
				}}
			>
				<div className={classes.toolbar}>
					<IconButton onClick={handleDrawerClose}>{theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}</IconButton>
				</div>
				<Divider />
				<List>
					<ListItem selected={location.pathname === "/dashboard"} button component="a" href="#/dashboard">
						<ListItemIcon>
							<ListIcon color={location.pathname === "/dashboard" ? "primary" : "inherit"} />
						</ListItemIcon>
						<ListItemText primary="Patient Lists" />
					</ListItem>
					{/* <ListItem button component="a" href="#/addsource">
						<ListItemIcon>
							<LocalHospitalIcon />
						</ListItemIcon>
						<ListItemText primary="Add Source" />
					</ListItem> */}
					<ListItem button selected={location.pathname === "/dashboard/upload"} component="a" href="#/dashboard/upload">
						<ListItemIcon>
							<PublishIcon color={location.pathname === "/dashboard/upload" ? "primary" : "inherit"} />
						</ListItemIcon>
						<ListItemText primary="Upload File" />
					</ListItem>
					{/* <ListItem button component="a" href="#/dashboard/slots">
						<ListItemIcon>
							<PlaylistAddIcon />
						</ListItemIcon>
						<ListItemText primary="Create Slots" />
					</ListItem>
					<ListItem button component="a" href="#/dashboard/myslots">
						<ListItemIcon>
							<PlaylistAddIcon />
						</ListItemIcon>
						<ListItemText primary="My Slots" />
					</ListItem> */}
					<ListItem button selected={location.pathname === "/dashboard/appointments"} component="a" href="#/dashboard/appointments">
						<ListItemIcon>
							<DateRangeIcon color={location.pathname === "/dashboard/appointments" ? "primary" : "inherit"} />
						</ListItemIcon>
						<ListItemText primary="Appointments" />
					</ListItem>
					{user?.signInUserSession?.accessToken?.payload["cognito:groups"]?.includes("admin") ? (
						<ListItem button selected={location.pathname === "/dashboard/pdstest"} component="a" href="#/dashboard/pdstest">
							<ListItemIcon>
								<FindInPageIcon color={location.pathname === "/dashboard/pdstest" ? "primary" : "inherit"} />
							</ListItemIcon>
							<ListItemText primary="PDS Test Page" />
						</ListItem>
					) : (
						""
					)}
				</List>
				<Divider />
			</Drawer>
		</React.Fragment>
	);
}
