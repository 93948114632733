/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:21d0e456-5245-45c3-9cd4-5ac8de14e66c",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_xsxHEl3sr",
    "aws_user_pools_web_client_id": "4dhf836fuad3rf5a3rq4a7upg7",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://j6j5mcfg7nextkmjbe2p36capq.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "surveys",
            "endpoint": "https://natbhit9ui.execute-api.eu-west-2.amazonaws.com/prod",
            "region": "eu-west-2"
        },
        {
            "name": "pimi",
            "endpoint": "https://5k2g1g4psf.execute-api.eu-west-2.amazonaws.com/prod",
            "region": "eu-west-2"
        },
        {
            "name": "appointments",
            "endpoint": "https://ckldzrps13.execute-api.eu-west-2.amazonaws.com/prod",
            "region": "eu-west-2"
        },
        {
            "name": "pdsProxy",
            "endpoint": "https://j5pdhkxcdd.execute-api.eu-west-2.amazonaws.com/prod",
            "region": "eu-west-2"
        }
    ],
    "aws_content_delivery_bucket": "medloop-eoraptor-prod",
    "aws_content_delivery_bucket_region": "eu-west-2",
    "aws_content_delivery_url": "http://medloop-eoraptor-prod.s3-website.eu-west-2.amazonaws.com",
    "aws_user_files_s3_bucket": "medloop-eoraptor-emis-reportsprod-prod",
    "aws_user_files_s3_bucket_region": "eu-west-2",
    "aws_mobile_analytics_app_id": "a7323f9abade4661b6c56ef2904f2a82",
    "aws_mobile_analytics_app_region": "eu-west-1"
};


export default awsmobile;
