import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Box,
	Button,
	CircularProgress,
	Drawer,
	FormControl,
	Grid,
	IconButton,
	LinearProgress,
	MenuItem,
	Paper,
	Select,
	Tab,
	Tabs,
	TextField,
	Tooltip,
	Typography,
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Dialog from "@material-ui/core/Dialog";
import {makeStyles} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import LaunchIcon from "@material-ui/icons/Launch";
import SendIcon from "@material-ui/icons/Send";
import {API, Auth, graphqlOperation} from "aws-amplify";
import clsx from "clsx";
import moment from "moment";
import {useSnackbar} from "notistack";
import PropTypes from "prop-types";
import {parse, stringify} from "query-string";
import React, {Fragment, useCallback, useEffect, useMemo, useState} from "react";
import AppointmentBooking from "../components/AppointmentBooking.js";
import ConfirmDialog from "../components/ConfirmDialog";
import EnhancedTable from "../components/enhancedTable/fullPatientList";
import ModifyBookings from "../components/ModifyBookings.js";
import NHSCell from "../components/nhsCell.js";
import {bulkInvitePatients} from "../graphql/customMutations";
import {locationByGpAndPostCode, slotsByPatient, sourceByGp} from "../graphql/queries";
import {
	controlledPatientBySource,
	controlledPatientByStatus,
	updateControlledPatientStatus,
	onUpdateControlledPatient,
} from "../services/controlledPatient";
import {NOTIFICATION_TYPES} from "../utils/constants";
import {calculateBMIFromProcessedResponse, getActColor, getStatus, getLinkLabel} from "../utils/utils";

const {
	ACT_SCORE,
	AWARENESS,
	CONSULTATION,
	EXACERBATION,
	ACTIVITIES,
	SYMPTOMS,
	SMOKING,
	BREATH,
	RELIEVER,
	BMI,
	BMI_METER,
	PEAK_FLOW,
	DATE_FORMAT,
	CONTROLLED_PATIENT_STATUS,
	DATE_TIME_FORMAT,
	NOTIFICATION_ALLOWED_ON_PATIENT_STATUS,
	NOTIFICATION_ALLOWED_COUNT,
	COVID_SURVEY_TYPE,
	ASTHMA_SURVEY_TYPE,
	CONTROLLED_PATIENT_STATUS_TRANSLATOR,
	controlLevelText,
	HOSPITAL_ADMISSION,
} = require("../utils/constants");

const drawerWidth = 400;

const useStyles = makeStyles(theme => ({
	root: {
		width: "100%",
		backgroundColor: theme.palette.background.paper,
	},
	patientLink: {
		textDecoration: "none",
		color: "inherit",
		fontWeight: "500",
	},
	backdrop: {
		zIndex: 9999,
		color: "#fff",
	},
	inline: {
		display: "inline",
	},
	avatar: {
		backgroundColor: theme.palette.primary.main, //
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	drawerHeader: {
		display: "flex",
		alignItems: "center",
		marginTop: theme.spacing(10),
		marginLeft: theme.spacing(2.5),
		marginRight: theme.spacing(2.5),
		marginBottom: theme.spacing(1),
		justifyContent: "space-between",
	},
	headerText: {
		fontSize: 20,
		fontWeight: "500",
	},
	drawerClose: {
		color: "#0fc3c5",
	},
	drawerContent: {
		padding: theme.spacing(3) - 4,
		display: "flex",
		flexDirection: "column",
		overflow: "auto",
		flex: 1,
	},
	surveyHeading: {
		display: "flex",
		alignItems: "center",
		paddingLeft: theme.spacing(2.5),
	},
	surveyMain: {
		fontSize: 20,
		marginRight: theme.spacing(1),
		fontWeight: "500",
		color: theme.palette.common.black,
	},
	sectionHeader: {
		fontSize: 12,
		color: "rgba(0, 0, 0, 0.87)",
	},
	surveySub: {
		fontWeight: "500",
		fontSize: 20,
		color: "rgba(0, 0, 0, 0.54)",
	},
	leftInput: {
		width: "100%",
		marginRight: theme.spacing(2),
	},
	disabledInput: {
		color: theme.palette.text.secondary, //
	},
	actScore: {
		width: theme.spacing(3),
		height: theme.spacing(3),
		fontSize: 14,
	},
	medicationList: {
		marginRight: theme.spacing(2),
		fontSize: 14,
		color: "rgba(0, 0, 0, 0.87)",
		letterSpacing: "0.15px",
		marginBottom: theme.spacing(1),
	},
	drawerFooter: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		// boxShadow: "0px -4px 12px #0000001a",
		paddingBottom: theme.spacing(1),
		marginTop: 15,
	},
	saveButton: {
		height: "42px",
		padding: "6px 20px",
		minWidth: "128px",
		fontSize: "15px",
		backgroundColor: "#0fc3c5",
		color: "white",
		borderRadius: "4px",
		margin: "16px 0px",
		textTransform: "none",
		"&:hover": {
			backgroundColor: "#0fc3c5",
		},
		"&:disabled": {
			backgroundColor: "rgba(0, 0, 0, 0.26)",
			color: "white",
		},
	},
	link: {
		color: "#0fc3c5",
		textDecoration: "none",
		textTransform: "none",
	},
	pageHeader: {
		fontWeight: "bold",
		paddingBottom: theme.spacing(0.5),
	},
	tab: {
		minWidth: "auto",
		width: "fit-content",
		padding: "0",
		marginRight: theme.spacing(2),
		marginLeft: theme.spacing(2),
	},
	sourceSelect: {
		fontSize: "14px",
	},
	sourceMenu: {
		padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
	},
	paper: {
		transition: theme.transitions.create(["margin", "width"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	paperShift: {
		width: `calc(100% - ${drawerWidth - 68}px)`,
		minWidth: theme.spacing(92),
		transition: theme.transitions.create(["margin", "width"], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginRight: drawerWidth,
	},
	headingAcc: {
		display: "block",
		fontSize: theme.typography.pxToRem(14),
		fontWeight: theme.typography.fontWeightRegular,
	},
	secondaryAccHeading: {
		display: "block",
		fontSize: theme.typography.pxToRem(12),
		color: theme.palette.text.primary, //
	},
	accMoreInfo: {
		fontSize: theme.typography.pxToRem(12),
		color: theme.palette.text.primary, //
	},
	tabBar: {
		display: "flex",
		justifyContent: "space-between",
	},
	bulkNotify: {
		marginRight: theme.spacing(2),
		height: theme.spacing(5),
		minWidth: theme.spacing(14),
	},
	paper: {
		width: "100%",
	},
	decline: {
		marginTop: theme.spacing(4),
	},
	tabContent: {
		minWidth: "auto",
		marginLeft: 16,
		padding: 4,
	},
	cirular: {
		padding: "10px 12px",
		borderRadius: 40,
	},
	reviewStatus: {
		background: "#a3e1d2",
	},
	toInviteStatus: {
		background: "#c7c2f9",
	},
	invitedStatus: {
		background: "#6253d8",
		color: "#ffffff",
	},
	doneStatus: {
		background: "#1c9e84",
		color: "#ffffff",
	},
	actAndMedicationsContainer: {
		overflow: "auto",
	},
	below20Act: {
		background: "#e08a8c",
	},
	below24Act: {
		background: "#ffcd80",
	},
	above25Act: {
		background: "#a3e1d2",
	},
	controlLevelDiv: {
		[theme.breakpoints.up("md")]: {},
	},
	tabPanel: {
		width: "100%",
	},
	tabPanel2: {
		[theme.breakpoints.up("md")]: {
			width: "calc(100% - 390px)",
		},
		width: "100%",
	},
	actLabel: {
		borderRadius: 40,
		padding: 4,
		marginRight: 6,
		color: "rgba(2,9,39, 0.64)",
	},
	textLabel: {
		width: "100%",
		textOverflow: "ellipsis",
		color: "rgba(2,9,39, 0.64)",
	},
	controltextLablel: {
		width: "150%",
		textOverflow: "ellipsis",
		color: "rgba(2,9,39, 0.64)",
	},
}));

function TabPanel(props) {
	const {children, value, index, ...other} = props;

	return (
		<div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
			{value === index && <Box p={2}>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const SURVEY_URL = `${window.location.origin}/#/survey`;

export default function ControlledPatientsPage(props) {
	const classes = useStyles(props);
	const {enqueueSnackbar} = useSnackbar();

	const [user, setUser] = useState(null);
	const [sourceList, setSourceList] = useState([]);

	const [selectedSource, setSelectedSource] = useState("");
	const [selectedPatient, setSelectedPatient] = useState(null);

	const [tabValue, setTabValue] = useState(0);
	const [loading, setLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [changeData, setChangeData] = useState(false);
	const [loadingMedications, setLoadingMedications] = useState(false);

	const [selectedPatients, setSelectedPatients] = useState([]);
	const [openNotifyDialog, setOpenNotifyDialog] = useState(false);
	const [locations, setLocations] = useState([]);
	const [modifyOpen, setModifyOpen] = useState(false);
	const [modifySlot, setModifySlot] = useState(null);
	const [pageSize, setPageSize] = useState(30);
	const [initializeTable, setInitializeTable] = useState(false);
	const [refresh, setRefresh] = useState(false);
	const [notifiedRow, setNotifiedRow] = useState(null);

	const [refreshTable, setRefreshTable] = useState(0);

	const [clientWidth, setClientWidth] = useState(document.body.clientWidth);

	useEffect(() => {
		window.addEventListener("resize", setClientWidthFn);

		return () => {
			window.removeEventListener("resize", setClientWidthFn);
		};
	}, []);

	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
	};

	const setClientWidthFn = () => {
		setClientWidth(document.body.clientWidth);
	};

	/**
	 *
	 * @param {[]} patients - controlled patient received from emis
	 * @returns {[]} formattedPatients
	 */
	const getFormattedPatients = patients => {
		return patients.map(controlledPatient => {
			const {patient, survey_response, gp_ods_code, nhs_number, gp_login, patient_guid, patient_ods_code} = controlledPatient;
			if (!patient) console.log(controlledPatient);
			let vaccinationRequested = false;
			if (survey_response) {
				const {receive_vaccination_1, receive_vaccination_2, receive_vaccination_3} = survey_response;
				vaccinationRequested = receive_vaccination_1 === "YES" || receive_vaccination_2 === "YES" || receive_vaccination_3 === "YES";
			}
			return {
				id: patient_guid,
				patName: patient ? `${patient.surname}, ${patient.given_name}` : "",
				status: controlledPatient.status,
				actScore: survey_response
					? survey_response.act_1 + survey_response.act_2 + survey_response.act_3 + survey_response.act_4 + survey_response.act_5
					: "",
				vaccine: survey_response ? (vaccinationRequested ? "Yes" : "No") : "",
				hospitalAdmissions: survey_response?.hospital_admission ? survey_response.hospital_admission : "",
				smokingStatus: survey_response ? survey_response.smoking_status : "",
				answeredOn: controlledPatient.survey_answered,
				gp_ods_code,
				nhs_number,
				surname: patient ? patient.surname : "",
				given_name: patient ? patient.given_name : "",
				gp_login,
				patient_ods_code,
				hash: controlledPatient.hash,
				control_level: controlledPatient.control_level,
				processed_response: controlledPatient.processed_response,
				survey: controlledPatient.survey,
				phone: patient.phone_number,
				dob: patient.birthdate,
				original_entry: controlledPatient,
				numberOfInvites: controlledPatient.numberOfInvites,
				lastNotificationDate: controlledPatient.lastNotificationDate,
			};
		});
	};

	useEffect(() => {
		if (!user) return;
		const getLocations = async () => {
			try {
				const result = await API.graphql(
					graphqlOperation(locationByGpAndPostCode, {
						gp_guid: user.attributes["custom:gp_guid"],
					})
				);

				console.log(result.data.locationByGPAndPostCode.items);
				setLocations(result.data.locationByGPAndPostCode.items);
			} catch (error) {
				console.log(error);
			} finally {
				// setIsLoading(false);
			}
		};
		getLocations();
	}, [user]);

	const queryAllControlledPatients = useCallback(
		async ({pageSize, currentToken, filter}) => {
			if (!selectedSource || !user) {
				return {
					data: [],
					nextToken: null,
				};
			}
			try {
				let refresh = refreshTable;
				const result = await API.graphql(
					graphqlOperation(controlledPatientBySource, {
						source_id: selectedSource.source_id,
						gp_guid: {
							eq: user.attributes["custom:gp_guid"],
						},
						limit: pageSize,
						nextToken: currentToken,
						filter: filter,
						notificationFilter: {
							type: {
								eq: selectedSource.survey.type === "ASTHMA" ? NOTIFICATION_TYPES.ASTHMA_INVITATION : NOTIFICATION_TYPES.INVITATION,
							},
						},
					})
				);
				//parse
				const parsedList = result.data.controlledPatientBySource.items.map(entry => {
					let sortableDates = entry.notifications.items.map(m => moment(m.sent_on));
					entry.numberOfInvites = entry.notifications.items.length;
					entry.lastNotificationDate = sortableDates.length > 0 ? moment.max(sortableDates) : "";
					entry.survey_response = entry.survey_response && JSON.parse(entry.survey_response);
					entry.processed_response = JSON.parse(entry.processed_response);
					return entry;
				});

				const nextToken = result.data.controlledPatientBySource.nextToken;
				return {
					data: getFormattedPatients(parsedList),
					nextToken,
				};
			} catch (err) {
				console.log(err);
				enqueueSnackbar(
					"There was an error while fetching the patient list. Please try again, if the problem persists, contanct support@medloop.co",
					{variant: "error"}
				);
				return {
					data: [],
					nextToken: null,
				};
			}
		},
		[enqueueSnackbar, selectedSource, user, refreshTable]
	);

	const queryControlledPatientByStatus = useCallback(
		async ({status, pageSize, currentToken}) => {
			if (!selectedSource || !user) {
				return {
					data: [],
					nextToken: null,
				};
			}
			try {
				const result = await API.graphql(
					graphqlOperation(controlledPatientByStatus, {
						status,
						gp_guidSource_id: {eq: {gp_guid: user.attributes["custom:gp_guid"], source_id: selectedSource.source_id}},
						limit: pageSize,
						nextToken: currentToken,
						notificationFilter: {
							type: {
								eq: selectedSource.survey.type === "ASTHMA" ? NOTIFICATION_TYPES.ASTHMA_INVITATION : NOTIFICATION_TYPES.INVITATION,
							},
						},
					})
				);

				const parsedList = result.data.controlledPatientByStatus.items.map(entry => {
					let sortableDates = entry.notifications.items.map(m => moment(m.sent_on));
					entry.numberOfInvites = entry.notifications.items.length;
					entry.lastNotificationDate = moment.max(sortableDates);
					entry.survey_response = JSON.parse(entry.survey_response);
					entry.processed_response = JSON.parse(entry.processed_response);
					return entry;
				});
				const nextToken = result.data.controlledPatientByStatus.nextToken;
				return {
					data: getFormattedPatients(parsedList),
					nextToken,
				};
			} catch (err) {
				console.log(err);
				enqueueSnackbar(
					"There was an error while fetching the patient list. Please try again, Please try again, if the problem persists, contanct support@medloop.co",
					{variant: "error"}
				);
				return {
					data: [],
					nextToken: null,
				};
			}
		},
		[enqueueSnackbar, selectedSource, user]
	);

	const getNotfiedPatients = useCallback(
		async ({pageSize, currentToken}) => {
			let refresh = refreshTable;
			return await queryControlledPatientByStatus({pageSize, currentToken, status: CONTROLLED_PATIENT_STATUS.NOTIFIED});
		},
		[queryControlledPatientByStatus, refreshTable]
	);

	const getPatientsWithControlLevel = useCallback(
		async ({pageSize, currentToken}) => {
			let refresh = refreshTable;
			return await queryControlledPatientByStatus({pageSize, currentToken, status: CONTROLLED_PATIENT_STATUS.PROCESSED});
		},
		[queryControlledPatientByStatus, refreshTable]
	);

	const getPatientsForReview = useCallback(
		async ({pageSize, currentToken}) => {
			let refresh = refreshTable;
			return await queryControlledPatientByStatus({pageSize, currentToken, status: CONTROLLED_PATIENT_STATUS.TO_REVIEW});
		},
		[queryControlledPatientByStatus, refreshTable]
	);

	const getPatientsWithManuallyContact = useCallback(
		async ({pageSize, currentToken}) => {
			let refresh = refreshTable;
			return await queryControlledPatientByStatus({pageSize, currentToken, status: CONTROLLED_PATIENT_STATUS.MANUAL_CONTACT});
		},
		[queryControlledPatientByStatus, refreshTable]
	);

	const getPatientsToInvite = useCallback(
		async ({pageSize, currentToken}) => {
			let refresh = refreshTable;
			return await queryControlledPatientByStatus({pageSize, currentToken, status: CONTROLLED_PATIENT_STATUS.IDENTIFIED});
		},
		[queryControlledPatientByStatus, refreshTable]
	);

	const getBookedPatients = useCallback(
		async ({pageSize, currentToken}) => {
			let refresh = refreshTable;
			return await queryControlledPatientByStatus({pageSize, currentToken, status: CONTROLLED_PATIENT_STATUS.BOOKED});
		},
		[queryControlledPatientByStatus, refreshTable]
	);

	const getDeclinedPatients = useCallback(
		async ({pageSize, currentToken}) => {
			let refresh = refreshTable;
			return await queryControlledPatientByStatus({pageSize, currentToken, status: CONTROLLED_PATIENT_STATUS.DECLINED});
		},
		[queryControlledPatientByStatus, refreshTable]
	);
	useEffect(() => {
		try {
			const {
				location: {search},
			} = props;
			const queryParams = parse(search);
			if (queryParams.pageSize) {
				setPageSize(parseInt(queryParams.pageSize));
			}
		} catch (error) {
			console.log(error);
		} finally {
			setInitializeTable(true);
		}
	}, [props]);

	useEffect(() => {
		if (user) return;
		const getUser = async () => {
			try {
				setIsLoading(true);
				const _user = await Auth.currentAuthenticatedUser();
				setUser(_user);
			} catch (error) {
				console.log(error);
				enqueueSnackbar("Error occurred. Please try again", {variant: "error"});
			} finally {
				setIsLoading(false);
			}
		};
		getUser();
	}, [enqueueSnackbar, user]);

	useEffect(() => {
		if (user) {
			const subscription = API.graphql({
				query: onUpdateControlledPatient,
				variables: {gp_guid: user.attributes["custom:gp_guid"]},
			}).subscribe({
				next: ({provider, value}) => {
					console.log("some update at patient list");
					setRefreshTable(Math.random());
				},
			});
			return () => subscription.unsubscribe();
		}
	}, [enqueueSnackbar, user, setRefreshTable]);

	useEffect(() => {
		if (!user) return;
		const {
			match: {params},
			history,
			location: {search},
		} = props;
		const queryParams = parse(search);
		const go = async () => {
			try {
				setIsLoading(true);
				const result = await API.graphql(
					graphqlOperation(sourceByGp, {
						gp_guid: user.attributes["custom:gp_guid"],
						filter: {
							status: {
								eq: "PROCESSED",
							},
						},
					})
				);

				const list = result.data.sourceByGP.items;
				list.sort(function(a, b) {
					return moment(b.create_at) - moment(a.create_at);
				});
				setSourceList(list);
				if (params.sourceId) {
					let selectedList = list.filter(l => l.source_id === params.sourceId)[0];
					if (selectedList) {
						setSelectedSource(selectedList);
					} else {
						let url = ["/dashboard", stringify(queryParams)];
						history.push(url.join("?"));
					}
				} else {
					setSelectedSource(list[0]);
				}
			} catch (error) {
				console.log(error);
				enqueueSnackbar(
					"There was an error while fetching the source list. Please try again, Please try again, if the problem persists, contanct support@medloop.co",
					{variant: "error"}
				);
			} finally {
				setIsLoading(false);
			}
		};
		go();
	}, [enqueueSnackbar, props, user]);

	const handleChange = event => {
		const {
			history,
			location: {search},
		} = props;
		let url = [`/dashboard/${event.target.value.source_id}`, stringify(parse(search))];
		history.push(url.join("?"));
		setSelectedSource(event.target.value);
		setTabValue(0);
	};

	useEffect(() => {
		if (!selectedPatient) return;
		const loadSlots = async () => {
			try {
				setLoadingMedications(true);
				const result = await API.graphql(
					graphqlOperation(slotsByPatient, {
						patient_guid: selectedPatient.original_entry.patient_guid,
					})
				);
				selectedPatient.slots = result.data.slotsByPatient.items;
			} catch (error) {
				enqueueSnackbar("Error fetching medications patient data, please try again later.", {variant: "error"});
			} finally {
				setLoadingMedications(false);
			}
		};

		const loadMedications = async () => {
			try {
				console.log(selectedPatient);
				setLoadingMedications(true);
				const params = {
					headers: {
						"m-emis-user": user.attributes["custom:emis_login"],
					},
					queryStringParameters: {
						//condition: "ASTHMA",
						status: "CURRENT",
					},
				};
				selectedPatient.active_medications = await API.get(
					"pimi",
					`/pimi/${selectedPatient.gp_ods_code}/patients/${selectedPatient.nhs_number.replace(/\s/g, "")}/medications`,
					params
				);
			} catch (error) {
				enqueueSnackbar("Error fetching medications from emis, please try again later.", {variant: "error"});
			} finally {
				setLoadingMedications(false);
			}
		};

		if (selectedPatient.survey.type === COVID_SURVEY_TYPE) {
			loadSlots();
		} else {
			loadMedications();
		}
	}, [enqueueSnackbar, selectedPatient, user]);

	const saveToEmis = async () => {
		console.log("saving..");
		try {
			setLoading(true);
			const events = {
				events: Object.values(selectedPatient.processed_response),
			};
			console.log(events);
			const params = {
				headers: {
					"m-emis-user": user.attributes["custom:emis_login"],
				},
				body: events,
			};
			let response = await API.post(
				"pimi",
				`/pimi/${selectedPatient.gp_ods_code}/patients/${selectedPatient.nhs_number.replace(/\s/g, "")}/consultation`,
				params
			);
			console.log(response);

			const {patient_guid, source_id, gp_guid} = selectedPatient.original_entry;
			await API.graphql(
				graphqlOperation(updateControlledPatientStatus, {
					input: {
						source_id,
						patient_guid,
						gp_guid,
						status: "PROCESSED",
					},
				})
			);
			selectedPatient.original_entry.status = "PROCESSED";
			selectedPatient.status = "PROCESSED";
			loadSelectedPatient(selectedPatient);
			enqueueSnackbar("Patient Sucessfully saved in Emis", {variant: "success"});
		} catch (error) {
			let errorReason = error.response.data.message
				.split(",")[1]
				.split("Event")[0]
				.split(":")[1];
			enqueueSnackbar(`Error saving in emis, please try again later. Reason: ${errorReason}`, {variant: "error"});
		} finally {
			setRefresh(!refresh);
			setLoading(false);
		}
	};

	const loadSelectedPatient = useCallback(async patient => {
		setSelectedPatient(patient);
	}, []);

	const openModifyDialog = slot => {
		setModifySlot(slot);
		setModifyOpen(true);
	};

	const handleModifyClose = () => {
		setModifyOpen(false);
		setModifySlot(null);
	};

	const updateSlot = async () => {
		setIsLoading(true);
		try {
			const {patient_guid} = selectedPatient.original_entry;
			const result = await API.graphql(
				graphqlOperation(slotsByPatient, {
					patient_guid: patient_guid,
				})
			);
			selectedPatient.slots = result.data.slotsByPatient.items;
			setSelectedPatient(selectedPatient);
		} catch (error) {
			console.log(error);
		} finally {
			setIsLoading(false);
		}
	};

	const getBookedSlots = () => {
		return selectedPatient.slots
			?.filter(e => e.status !== "CANCELLED")
			.map((slot, i) => {
				let start = new Date(slot.year, slot.month - 1, slot.day, slot.hour, slot.minute);
				let end = new Date(start.getTime() + slot.duration * 60000);
				let endString = `${("0" + end.getHours()).slice(-2)}:${("0" + end.getMinutes()).slice(-2)}`;
				return (
					<Grid container spacing={2}>
						<Grid item xs="12">
							<Typography component="div">
								<Box fontSize={14} fontWeight="fontWeightBold" color="textPrimary">
									Scheduled Appointments
								</Box>
							</Typography>
						</Grid>
						<Grid item xs="12">
							<Paper variant="outlined" className={classes.paper}>
								<Box m={2} key={i}>
									<Grid container spacing={2}>
										<Grid item xs="6">
											<Typography component="div">
												<Box fontSize={20} fontWeight="fontWeightBold" color="textPrimary">
													{moment(start).format("ddd, DD.MM.YY")}
												</Box>
											</Typography>
										</Grid>
										<Grid item xs="6">
											<Box display="flex" flexDirection="row-reverse">
												<Typography component="div">
													<Box fontSize={20} color="textPrimary">
														{slot.hour}:{slot.minute} - {endString}
													</Box>
												</Typography>
											</Box>
										</Grid>
										<Grid item xs="6">
											<Box display="flex" flexDirection="row">
												<Typography component="div">
													<Box fontSize={18} color="textPrimary">
														{slot.status}
													</Box>
												</Typography>
											</Box>
										</Grid>
										<Grid item xs="6">
											<Box display="flex" flexDirection="row-reverse">
												<Button
													disableElevation
													color="primary"
													variant="contained"
													disabled={
														moment()
															.utc()
															.diff(moment.utc([slot.year, slot.month - 1, slot.day, slot.hour, slot.minute])) > 0
													}
													onClick={() => openModifyDialog(slot)}
												>
													Edit
												</Button>
											</Box>
										</Grid>
									</Grid>
								</Box>
							</Paper>
						</Grid>
					</Grid>
				);
			});
	};

	const isAlreadyBooked = () => {
		return (
			loadingMedications ||
			(selectedPatient && selectedPatient.slots && selectedPatient.slots.filter(e => e.status !== "CANCELLED").length >= 1)
		);
	};

	const handleNotifyPatient = useCallback(
		async ({original, index}) => {
			setIsLoading(true);
			const {patient_guid, source_id, gp_guid} = original.original_entry;
			try {
				setNotifiedRow(index);
				await API.graphql(
					graphqlOperation(bulkInvitePatients, {
						input: JSON.stringify([
							{
								patientGuid: patient_guid,
								sourceId: source_id,
								gpGuid: gp_guid,
								type: selectedSource.survey.type === "ASTHMA" ? NOTIFICATION_TYPES.ASTHMA_INVITATION : NOTIFICATION_TYPES.INVITATION,
							},
						]),
					})
				);
				enqueueSnackbar("Patient will be notified.", {variant: "success"});
			} catch (error) {
				console.log(error);
				enqueueSnackbar("There was an error while notifying patient.", {variant: "error"});
			} finally {
				setIsLoading(false);
				setNotifiedRow(null);
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[enqueueSnackbar, selectedSource]
	);

	const handleBulkNotify = async () => {
		try {
			let requestParams = selectedPatients.map(patient => {
				const {patient_guid, source_id, gp_guid} = patient.original_entry;
				return {
					patientGuid: patient_guid,
					sourceId: source_id,
					gpGuid: gp_guid,
					type: selectedSource.survey.type === "ASTHMA" ? NOTIFICATION_TYPES.ASTHMA_INVITATION : NOTIFICATION_TYPES.INVITATION,
				};
			});
			console.log(requestParams);
			await API.graphql(
				graphqlOperation(bulkInvitePatients, {
					input: JSON.stringify(requestParams),
				})
			);
			setChangeData(!changeData);
			setSelectedPatients([]);
			enqueueSnackbar(`${selectedPatients.length} patients will be notified.`, {variant: "success"});
		} catch (error) {
			console.log(error);
			enqueueSnackbar("There was an error while notifying the patients. Please try again", {variant: "error"});
		} finally {
			setLoading(false);
		}
	};

	const renderSourceItems = () => {
		if (sourceList.length > 0) {
			return sourceList.map(source => (
				<MenuItem key={source.source_id + source.name} value={source}>
					{decodeURIComponent(source.name) + " - " + moment(source.create_at).format("DD/MM/yyyy")}
				</MenuItem>
			));
		} else {
			return (
				<MenuItem key="None" value="None">
					{"Select a list source"}
				</MenuItem>
			);
		}
	};

	const handleOpenNotifyDialog = () => {
		setOpenNotifyDialog(true);
	};

	const getSelectedPatientsCount = () => {
		if (selectedPatients) {
			return selectedPatients.length;
		}
		return "0";
	};

	const handlePaginationChange = data => {
		const {
			match: {params, url},
			history,
		} = props;
		let finalUrl = [url, stringify(data)];
		history.push(finalUrl.join("?"));
	};

	const columnsConfig = {
		toInvite: useMemo(() => {
			return [
				{
					Header: "Patient Name",
					accessor: "patName",
					numeric: false,
					disablePadding: false,
				},
				{
					Header: "Surgery Code",
					accessor: "patient_ods_code",
					numeric: false,
					disablePadding: false,
					size: "small",
					Cell: ({row}) => {
						return row.original.patient_ods_code ? row.original.patient_ods_code : row.original.gp_ods_code ? row.original.gp_ods_code : "";
					},
				},
				{
					Header: "Status",
					accessor: "status",
					numeric: false,
					disablePadding: false,
					Cell: ({row}) => {
						let statusInfo = row.allCells.find(item => item.column.Header === "Status");
						const source = selectedSource;
						const statusMapper = {
							ASTHMA: (
								<span className={clsx(classes.toInviteStatus, classes.cirular)}>
									{statusInfo && statusInfo.value
										? CONTROLLED_PATIENT_STATUS_TRANSLATOR[statusInfo.value]
											? CONTROLLED_PATIENT_STATUS_TRANSLATOR[statusInfo.value]
											: statusInfo.value
										: ""}
								</span>
							),
							COVID: statusInfo ? statusInfo.value : "",
						};
						return statusMapper[source.survey.type];
					},
				},
				{
					Header: "NHS Number",
					accessor: "nhs_number",
					numeric: false,
					disablePadding: false,
					Cell: ({row}) => {
						return <NHSCell row={row} />;
					},
				},
				{
					Header: "Mobile",
					accessor: "phone",
					numeric: false,
					disablePadding: false,
				},
				{
					Header: "Date of Birth",
					accessor: "dob",
					numeric: false,
					disablePadding: false,
				},
				{
					Header: getLinkLabel(selectedSource),
					accessor: "link",
					numeric: false,
					disablePadding: true,
					size: "small",
					align: "center",
					Cell: ({row}) => {
						const {status} = row.original;
						const isDisabled = !NOTIFICATION_ALLOWED_ON_PATIENT_STATUS.includes(status);
						return (
							<Tooltip title="Copy Booking Link">
								<IconButton
									disabled={isDisabled}
									color="secondary" //
									aria-label="copy"
									onClick={() => {
										navigator.clipboard.writeText(`${SURVEY_URL}/${row.original.hash}`);
										enqueueSnackbar("Booking Link copied successfully.", {variant: "success"});
									}}
								>
									<FileCopyIcon />
								</IconButton>
							</Tooltip>
						);
					},
					disableSortBy: true,
				},
				{
					Header: "Action",
					accessor: "action",
					disableSortBy: true,
					numeric: false,
					disablePadding: true,
					size: "small",
					align: "center",
					Cell: ({row}) => {
						return (
							<Tooltip title="Load Patient Details">
								<IconButton color="secondary" aria-label="load-patient-details" onClick={() => loadSelectedPatient(row.original)}>
									<LaunchIcon />
								</IconButton>
							</Tooltip>
						);
					},
				},
				{
					Header: "Notify",
					accessor: "notify",
					numeric: false,
					disablePadding: true,
					size: "small",
					align: "center",
					Cell: ({row}) => {
						const {status, numberOfInvites} = row.original;
						const isDisabled =
							!NOTIFICATION_ALLOWED_ON_PATIENT_STATUS.includes(status) ||
							numberOfInvites >= NOTIFICATION_ALLOWED_COUNT ||
							notifiedRow === row.index;
						return (
							<Fragment>
								<Tooltip title="Notify Patient">
									<IconButton
										disabled={isDisabled}
										color="secondary" //
										aria-label="notify-patient"
										onClick={() => handleNotifyPatient(row)}
									>
										<SendIcon />
									</IconButton>
								</Tooltip>
							</Fragment>
						);
					},
					disableSortBy: true,
				},
			];
		}, [selectedSource, classes.toInviteStatus, classes.cirular, enqueueSnackbar, loadSelectedPatient, notifiedRow, handleNotifyPatient]),

		manuallyContact: useMemo(() => {
			return [
				{
					Header: "Patient Name",
					accessor: "patName",
					numeric: false,
					disablePadding: false,
				},
				{
					Header: "Surgery Code",
					accessor: "patient_ods_code",
					numeric: false,
					disablePadding: false,
					size: "small",
					Cell: ({row}) => {
						return row.original.patient_ods_code ? row.original.patient_ods_code : row.original.gp_ods_code ? row.original.gp_ods_code : "";
					},
				},
				{
					Header: "Status",
					accessor: "status",
					numeric: false,
					disablePadding: false,
				},
				{
					Header: "NHS Number",
					accessor: "nhs_number",
					numeric: false,
					disablePadding: false,
					Cell: ({row}) => {
						return <NHSCell row={row} />;
					},
				},
				{
					Header: "Mobile",
					accessor: "phone",
					numeric: false,
					disablePadding: false,
				},
				{
					Header: "Date of Birth",
					accessor: "dob",
					numeric: false,
					disablePadding: false,
				},
				{
					Header: getLinkLabel(selectedSource),
					accessor: "link",
					numeric: false,
					disablePadding: true,
					size: "small",
					align: "center",
					Cell: ({row}) => {
						const {status} = row.original;
						const isDisabled = !NOTIFICATION_ALLOWED_ON_PATIENT_STATUS.includes(status);
						return (
							<Tooltip title="Copy Booking Link">
								<IconButton
									disabled={isDisabled}
									color="secondary" //
									aria-label="copy"
									onClick={() => {
										navigator.clipboard.writeText(`${SURVEY_URL}/${row.original.hash}`);
										enqueueSnackbar("Booking Link copied successfully.", {variant: "success"});
									}}
								>
									<FileCopyIcon />
								</IconButton>
							</Tooltip>
						);
					},
					disableSortBy: true,
				},
				{
					Header: "Action",
					accessor: "action",
					disableSortBy: true,
					numeric: false,
					disablePadding: true,
					size: "small",
					align: "center",
					Cell: ({row}) => {
						return (
							<Tooltip title="Load Patient Details">
								<IconButton color="secondary" aria-label="load-patient-details" onClick={() => loadSelectedPatient(row.original)}>
									<LaunchIcon />
								</IconButton>
							</Tooltip>
						);
					},
				},
			];
		}, [enqueueSnackbar, loadSelectedPatient, selectedSource]),

		invited: useMemo(() => {
			return [
				{
					Header: "Patient Name",
					accessor: "patName",
					numeric: false,
					disablePadding: false,
				},
				{
					Header: "Surgery Code",
					accessor: "patient_ods_code",
					numeric: false,
					disablePadding: false,
					size: "small",
					Cell: ({row}) => {
						return row.original.patient_ods_code ? row.original.patient_ods_code : row.original.gp_ods_code ? row.original.gp_ods_code : "";
					},
				},
				{
					Header: "Status",
					accessor: "status",
					numeric: false,
					disablePadding: false,
					Cell: ({row}) => {
						const statusMapper = {
							ASTHMA: (
								<span className={clsx(classes[getStatus(row)], classes.cirular)}>
									{row.original.status
										? CONTROLLED_PATIENT_STATUS_TRANSLATOR[row.original.status]
											? CONTROLLED_PATIENT_STATUS_TRANSLATOR[row.original.status]
											: row.original.status
										: ""}
								</span>
							),
							COVID: row.original.status ? row.original.status : "",
						};
						const source = selectedSource;
						return statusMapper[source.survey.type];
					},
				},
				{
					Header: "NHS Number",
					accessor: "nhs_number",
					numeric: false,
					disablePadding: false,
					Cell: ({row}) => {
						return <NHSCell row={row} />;
					},
				},
				{
					Header: "Last Notification Date",
					accessor: "lastNotificationDate",
					numeric: false,
					disablePadding: false,
					Cell: ({row}) => {
						return row.original.lastNotificationDate ? moment(row.original.lastNotificationDate).format(DATE_FORMAT) : "";
					},
				},
				{
					Header: "Number of invites sent",
					accessor: "numberOfInvites",
					numeric: true,
					disablePadding: false,
				},
				{
					Header: "Mobile",
					accessor: "phone",
					numeric: false,
					disablePadding: false,
				},
				{
					Header: "Date of Birth",
					accessor: "dob",
					numeric: false,
					disablePadding: false,
				},
				{
					Header: getLinkLabel(selectedSource),
					accessor: "link",
					numeric: false,
					disablePadding: true,
					size: "small",
					align: "center",
					Cell: ({row}) => {
						const {status} = row.original;
						const isDisabled = !NOTIFICATION_ALLOWED_ON_PATIENT_STATUS.includes(status);
						return (
							<Tooltip title="Copy Booking Link">
								<IconButton
									disabled={isDisabled}
									color="secondary" //
									aria-label="copy"
									onClick={() => {
										navigator.clipboard.writeText(`${SURVEY_URL}/${row.original.hash}`);
										enqueueSnackbar("Booking Link copied successfully.", {variant: "success"});
									}}
								>
									<FileCopyIcon />
								</IconButton>
							</Tooltip>
						);
					},
					disableSortBy: true,
				},
				{
					Header: "Action",
					accessor: "action",
					disableSortBy: true,
					numeric: false,
					disablePadding: true,
					size: "small",
					align: "center",
					Cell: ({row}) => {
						return (
							<Tooltip title="Load Patient Details">
								<IconButton color="secondary" aria-label="load-patient-details" onClick={() => loadSelectedPatient(row.original)}>
									<LaunchIcon />
								</IconButton>
							</Tooltip>
						);
					},
				},
				{
					Header: "Notify",
					accessor: "notify",
					numeric: false,
					disablePadding: true,
					size: "small",
					align: "center",
					Cell: ({row}) => {
						const {status, numberOfInvites} = row.original;
						const isDisabled =
							!NOTIFICATION_ALLOWED_ON_PATIENT_STATUS.includes(status) ||
							numberOfInvites >= NOTIFICATION_ALLOWED_COUNT ||
							notifiedRow === row.index;
						return (
							<Fragment>
								<Tooltip title="Notify Patient">
									<IconButton
										disabled={isDisabled}
										color="secondary" //
										aria-label="notify-patient"
										onClick={() => handleNotifyPatient(row)}
									>
										<SendIcon />
									</IconButton>
								</Tooltip>
							</Fragment>
						);
					},
					disableSortBy: true,
				},
			];
		}, [classes, selectedSource, enqueueSnackbar, loadSelectedPatient, handleNotifyPatient, notifiedRow]),
		booked: useMemo(() => {
			return [
				{
					Header: "Patient Name",
					accessor: "patName",
					numeric: false,
					disablePadding: false,
				},
				{
					Header: "Surgery Code",
					accessor: "patient_ods_code",
					numeric: false,
					disablePadding: false,
					size: "small",
					Cell: ({row}) => {
						return row.original.patient_ods_code ? row.original.patient_ods_code : row.original.gp_ods_code ? row.original.gp_ods_code : "";
					},
				},
				{
					Header: "Status",
					accessor: "status",
					numeric: false,
					disablePadding: false,
				},
				{
					Header: "NHS Number",
					accessor: "nhs_number",
					numeric: false,
					disablePadding: false,
					Cell: ({row}) => {
						return <NHSCell row={row} />;
					},
				},
				{
					Header: "Consent",
					accessor: "vaccine",
					numeric: false,
					disablePadding: false,
				},
				{
					Header: "Response Date",
					accessor: "answeredOn",
					numeric: false,
					disablePadding: false,
					Cell: ({row}) => {
						return row.original.answeredOn ? moment(row.original.answeredOn).format(DATE_TIME_FORMAT) : "";
					},
				},
				{
					Header: "Mobile",
					accessor: "phone",
					numeric: false,
					disablePadding: false,
				},
				{
					Header: "Date of Birth",
					accessor: "dob",
					numeric: false,
					disablePadding: false,
				},
				{
					Header: getLinkLabel(selectedSource),
					accessor: "link",
					numeric: false,
					disablePadding: true,
					size: "small",
					align: "center",
					Cell: ({row}) => {
						const {status} = row.original;
						const isDisabled = !NOTIFICATION_ALLOWED_ON_PATIENT_STATUS.includes(status);
						return (
							<Tooltip title="Copy Booking Link">
								<IconButton
									disabled={isDisabled}
									color="secondary" //
									aria-label="copy"
									onClick={() => {
										navigator.clipboard.writeText(`${SURVEY_URL}/${row.original.hash}`);
										enqueueSnackbar("Booking Link copied successfully.", {variant: "success"});
									}}
								>
									<FileCopyIcon />
								</IconButton>
							</Tooltip>
						);
					},
					disableSortBy: true,
				},
				{
					Header: "Action",
					accessor: "action",
					disableSortBy: true,
					numeric: false,
					disablePadding: true,
					size: "small",
					align: "center",
					Cell: ({row}) => {
						return (
							<Tooltip title="Load Patient Details">
								<IconButton color="secondary" aria-label="load-patient-details" onClick={() => loadSelectedPatient(row.original)}>
									<LaunchIcon />
								</IconButton>
							</Tooltip>
						);
					},
				},
			];
		}, [enqueueSnackbar, loadSelectedPatient, selectedSource]),

		declined: useMemo(() => {
			return [
				{
					Header: "Patient Name",
					accessor: "patName",
					numeric: false,
					disablePadding: false,
				},
				{
					Header: "Surgery Code",
					accessor: "patient_ods_code",
					numeric: false,
					disablePadding: false,
					size: "small",
					Cell: ({row}) => {
						return row.original.patient_ods_code ? row.original.patient_ods_code : row.original.gp_ods_code ? row.original.gp_ods_code : "";
					},
				},
				{
					Header: "Status",
					accessor: "status",
					numeric: false,
					disablePadding: false,
				},
				{
					Header: "NHS Number",
					accessor: "nhs_number",
					numeric: false,
					disablePadding: false,
					Cell: ({row}) => {
						return <NHSCell row={row} />;
					},
				},
				{
					Header: "Consent",
					accessor: "vaccine",
					numeric: false,
					disablePadding: false,
				},
				{
					Header: "Response Date",
					accessor: "answeredOn",
					numeric: false,
					disablePadding: false,
					Cell: ({row}) => {
						return row.original.answeredOn ? moment(row.original.answeredOn).format(DATE_TIME_FORMAT) : "";
					},
				},
				{
					Header: "Mobile",
					accessor: "phone",
					numeric: false,
					disablePadding: false,
				},
				{
					Header: "Date of Birth",
					accessor: "dob",
					numeric: false,
					disablePadding: false,
				},
				{
					Header: getLinkLabel(selectedSource),
					accessor: "link",
					numeric: false,
					disablePadding: true,
					size: "small",
					align: "center",
					Cell: ({row}) => {
						const {status} = row.original;
						const isDisabled = !NOTIFICATION_ALLOWED_ON_PATIENT_STATUS.includes(status);
						return (
							<Tooltip title="Copy Booking Link">
								<IconButton
									disabled={isDisabled}
									color="secondary" //
									aria-label="copy"
									onClick={() => {
										navigator.clipboard.writeText(`${SURVEY_URL}/${row.original.hash}`);
										enqueueSnackbar("Booking Link copied successfully.", {variant: "success"});
									}}
								>
									<FileCopyIcon />
								</IconButton>
							</Tooltip>
						);
					},
					disableSortBy: true,
				},
				{
					Header: "Action",
					accessor: "action",
					disableSortBy: true,
					numeric: false,
					disablePadding: true,
					size: "small",
					align: "center",
					Cell: ({row}) => {
						return (
							<Tooltip title="Load Patient Details">
								<IconButton color="secondary" aria-label="load-patient-details" onClick={() => loadSelectedPatient(row.original)}>
									<LaunchIcon />
								</IconButton>
							</Tooltip>
						);
					},
				},
			];
		}, [enqueueSnackbar, loadSelectedPatient, selectedSource]),

		vaccinated: useMemo(() => {
			return [
				{
					Header: "Patient Name",
					accessor: "patName",
					numeric: false,
					disablePadding: false,
				},
				{
					Header: "Surgery Code",
					accessor: "patient_ods_code",
					numeric: false,
					disablePadding: false,
					size: "small",
					Cell: ({row}) => {
						return row.original.patient_ods_code ? row.original.patient_ods_code : row.original.gp_ods_code ? row.original.gp_ods_code : "";
					},
				},
				{
					Header: "Status",
					accessor: "status",
					numeric: false,
					disablePadding: false,
				},
				{
					Header: "NHS Number",
					accessor: "nhs_number",
					numeric: false,
					disablePadding: false,
					Cell: ({row}) => {
						return <NHSCell row={row} />;
					},
				},
				{
					Header: "Consent",
					accessor: "vaccine",
					numeric: false,
					disablePadding: false,
				},
				{
					Header: "Response Date",
					accessor: "answeredOn",
					numeric: false,
					disablePadding: false,
					Cell: ({row}) => {
						return row.original.answeredOn ? moment(row.original.answeredOn).format(DATE_TIME_FORMAT) : "";
					},
				},
				{
					Header: "Mobile",
					accessor: "phone",
					numeric: false,
					disablePadding: false,
				},
				{
					Header: "Date of Birth",
					accessor: "dob",
					numeric: false,
					disablePadding: false,
				},
				{
					Header: getLinkLabel(selectedSource),
					accessor: "link",
					numeric: false,
					disablePadding: true,
					size: "small",
					align: "center",
					Cell: ({row}) => {
						const {status} = row.original;
						const isDisabled = !NOTIFICATION_ALLOWED_ON_PATIENT_STATUS.includes(status);
						return (
							<Tooltip title="Copy Booking Link">
								<IconButton
									disabled={isDisabled}
									color="secondary" //
									aria-label="copy"
									onClick={() => {
										navigator.clipboard.writeText(`${SURVEY_URL}/${row.original.hash}`);
										enqueueSnackbar("Booking Link copied successfully.", {variant: "success"});
									}}
								>
									<FileCopyIcon />
								</IconButton>
							</Tooltip>
						);
					},
					disableSortBy: true,
				},
				{
					Header: "Action",
					accessor: "action",
					disableSortBy: true,
					numeric: false,
					disablePadding: true,
					size: "small",
					align: "center",
					Cell: ({row}) => {
						return (
							<Tooltip title="Load Patient Details">
								<IconButton color="secondary" aria-label="load-patient-details" onClick={() => loadSelectedPatient(row.original)}>
									<LaunchIcon />
								</IconButton>
							</Tooltip>
						);
					},
				},
			];
		}, [enqueueSnackbar, loadSelectedPatient, selectedSource]),

		review: useMemo(() => {
			return [
				{
					Header: "Patient Name",
					accessor: "patName",
					numeric: false,
					disablePadding: false,
				},
				{
					Header: "Status",
					accessor: "status",
					numeric: false,
					disablePadding: false,
					Cell: ({row}) => {
						return (
							<span className={clsx(classes[getStatus(row)], classes.cirular)}>
								{row.original.status
									? CONTROLLED_PATIENT_STATUS_TRANSLATOR[row.original.status]
										? CONTROLLED_PATIENT_STATUS_TRANSLATOR[row.original.status]
										: row.original.status
									: ""}
							</span>
						);
					},
				},
				{
					Header: "NHS Number",
					accessor: "nhs_number",
					numeric: false,
					disablePadding: false,
					Cell: ({row}) => {
						return <NHSCell row={row} />;
					},
				},
				{
					Header: clientWidth <= 960 ? "ACT" : "ACT & Control Level",
					accessor: "actScore",
					numeric: false,
					disablePadding: false,
					Cell: ({row}) => {
						let controlLevel = null;
						if (clientWidth <= 960) {
							controlLevel = row.original.actScore;
							return (
								<span className={clsx(classes.cirular, classes.controlLevelDiv, classes[getActColor(row.original.actScore)])}>
									{controlLevel}
								</span>
							);
						} else {
							controlLevel =
								row.original.actScore +
								"    " +
								(controlLevelText[row.original.control_level] ? controlLevelText[row.original.control_level] : row.original.control_level);
							return (
								<div className={clsx(classes.cirular, classes.controlLevelDiv, classes[getActColor(row.original.actScore)])}>
									{controlLevel}
								</div>
							);
						}
					},
				},
				{
					Header: "Hospital admissions",
					accessor: "hospitalAdmissions",
					numeric: false,
					disablePadding: false,
					Cell: ({row}) => {
						const hospitalAdmissions = row?.original?.original_entry?.processed_response?.hospital_admission;
						return hospitalAdmissions?.text ? hospitalAdmissions.text : "";
					},
				},
				{
					Header: "Exacerbation",
					numeric: false,
					disablePadding: false,
					canSort: true,
					Cell: ({row}) => {
						let text = "-";
						//*** */
						if (
							row.original &&
							row.original.original_entry &&
							row.original.original_entry.processed_response &&
							row.original.original_entry.processed_response.exacerbation
						) {
							text = row.original.original_entry.processed_response.exacerbation.text;
						}
						return text;
					},
				},
				{
					Header: "Smoking Status",
					numeric: false,
					disablePadding: false,
					canSort: true,
					Cell: ({row}) => {
						return row.original.smokingStatus;
					},
				},
				{
					Header: getLinkLabel(selectedSource),
					accessor: "link",
					numeric: false,
					disablePadding: true,
					size: "small",
					align: "center",
					Cell: ({row}) => {
						const {status} = row.original;
						const isDisabled = !NOTIFICATION_ALLOWED_ON_PATIENT_STATUS.includes(status);
						return (
							<Tooltip title="Copy Booking Link">
								<IconButton
									disabled={isDisabled}
									color="secondary" //
									aria-label="copy"
									onClick={() => {
										navigator.clipboard.writeText(`${SURVEY_URL}/${row.original.hash}`);
										enqueueSnackbar("Booking Link copied successfully.", {variant: "success"});
									}}
								>
									<FileCopyIcon />
								</IconButton>
							</Tooltip>
						);
					},
					disableSortBy: true,
				},
				{
					Header: "Action",
					accessor: "action",
					disableSortBy: true,
					numeric: false,
					disablePadding: true,
					size: "small",
					align: "center",
					Cell: ({row}) => {
						return (
							<Tooltip title="Load Patient Details">
								<IconButton color="secondary" aria-label="load-patient-details" onClick={() => loadSelectedPatient(row.original)}>
									<LaunchIcon />
								</IconButton>
							</Tooltip>
						);
					},
				},
			];
		}, [clientWidth, classes, enqueueSnackbar, loadSelectedPatient, selectedSource]),

		fullPatient: useMemo(() => {
			return [
				{
					Header: "Patient Name",
					accessor: "patName",
					numeric: false,
					disablePadding: false,
				},
				{
					Header: "Surgery Code",
					accessor: "patient_ods_code",
					numeric: false,
					disablePadding: false,
					size: "small",
					Cell: ({row}) => {
						return row.original.patient_ods_code ? row.original.patient_ods_code : row.original.gp_ods_code ? row.original.gp_ods_code : "";
					},
				},
				{
					Header: "Status",
					accessor: "status",
					numeric: false,
					disablePadding: false,
				},
				{
					Header: "NHS Number",
					accessor: "nhs_number",
					numeric: false,
					disablePadding: false,
					Cell: ({row}) => {
						return <NHSCell row={row} />;
					},
				},
				{
					Header: "Consent",
					accessor: "vaccine",
					numeric: false,
					disablePadding: false,
				},
				{
					Header: "Response Date",
					accessor: "answeredOn",
					numeric: false,
					disablePadding: false,
					Cell: ({row}) => {
						return row.original.answeredOn ? moment(row.original.answeredOn).format(DATE_TIME_FORMAT) : "";
					},
				},
				{
					Header: "Last Notification Date",
					accessor: "lastNotificationDate",
					numeric: false,
					disablePadding: false,
					Cell: ({row}) => {
						return row.original.lastNotificationDate ? moment(row.original.lastNotificationDate).format(DATE_FORMAT) : "";
					},
				},
				{
					Header: "Number of invites sent",
					accessor: "numberOfInvites",
					numeric: true,
					disablePadding: false,
					Cell: ({row}) => {
						return row.original.numberOfInvites !== 0 ? row.original.numberOfInvites : "";
					},
				},
				{
					Header: "Mobile",
					accessor: "phone",
					numeric: false,
					disablePadding: false,
				},
				{
					Header: "Date of Birth",
					accessor: "dob",
					numeric: false,
					disablePadding: false,
				},
				{
					Header: getLinkLabel(selectedSource),
					accessor: "link",
					numeric: false,
					disablePadding: true,
					size: "small",
					align: "center",
					Cell: ({row}) => {
						const {status} = row.original;
						const isDisabled = !NOTIFICATION_ALLOWED_ON_PATIENT_STATUS.includes(status);
						return (
							<Tooltip title="Copy Booking Link">
								<IconButton
									disabled={isDisabled}
									color="secondary" //
									aria-label="copy"
									onClick={() => {
										navigator.clipboard.writeText(`${SURVEY_URL}/${row.original.hash}`);
										enqueueSnackbar("Booking Link copied successfully.", {variant: "success"});
									}}
								>
									<FileCopyIcon />
								</IconButton>
							</Tooltip>
						);
					},
					disableSortBy: true,
				},
				{
					Header: "Action",
					accessor: "action",
					disableSortBy: true,
					numeric: false,
					disablePadding: true,
					size: "small",
					align: "center",
					Cell: ({row}) => {
						return (
							<Tooltip title="Load Patient Details">
								<IconButton color="secondary" aria-label="load-patient-details" onClick={() => loadSelectedPatient(row.original)}>
									<LaunchIcon />
								</IconButton>
							</Tooltip>
						);
					},
				},
				{
					Header: "Notify",
					accessor: "notify",
					numeric: false,
					disablePadding: true,
					size: "small",
					align: "center",
					Cell: ({row}) => {
						const {status, numberOfInvites} = row.original;
						const isDisabled =
							!NOTIFICATION_ALLOWED_ON_PATIENT_STATUS.includes(status) ||
							numberOfInvites >= NOTIFICATION_ALLOWED_COUNT ||
							notifiedRow === row.index;
						return (
							<Fragment>
								<Tooltip title="Notify Patient">
									<IconButton
										disabled={isDisabled}
										color="secondary" //
										aria-label="notify-patient"
										onClick={() => handleNotifyPatient(row)}
									>
										<SendIcon />
									</IconButton>
								</Tooltip>
							</Fragment>
						);
					},
					disableSortBy: true,
				},
			];
		}, [enqueueSnackbar, loadSelectedPatient, handleNotifyPatient, notifiedRow, selectedSource]),
	};

	const tabPanelConfig = useMemo(() => {
		return [
			{
				props: {
					selectable: true,
					setSelectedRows: setSelectedPatients,
					columns: columnsConfig.toInvite,
					fetchRecords: getPatientsToInvite,
					setPagination: handlePaginationChange,
					limit: pageSize,
				},
				label: "To invite",
				types: [COVID_SURVEY_TYPE, ASTHMA_SURVEY_TYPE],
			},
			{
				props: {
					fetchRecords: getPatientsWithManuallyContact,
					columns: columnsConfig.manuallyContact,
					setPagination: handlePaginationChange,
					limit: pageSize,
				},
				label: "To manually contact",
				types: [COVID_SURVEY_TYPE],
			},
			{
				props: {
					fetchRecords: getNotfiedPatients,
					resetData: changeData,
					enqueueSnackbar: enqueueSnackbar,
					selectable: true,
					columns: columnsConfig.invited,
					setSelectedRows: setSelectedPatients,
					setPagination: handlePaginationChange,
					limit: pageSize,
				},
				label: "Invited",
				types: [COVID_SURVEY_TYPE, ASTHMA_SURVEY_TYPE],
			},
			{
				props: {
					setSelectedRows: setSelectedPatients,
					columns: columnsConfig.booked,
					fetchRecords: getBookedPatients,
					resetData: changeData,
					setPagination: handlePaginationChange,
					limit: pageSize,
				},
				label: "Booked",
				types: [COVID_SURVEY_TYPE],
			},
			{
				props: {
					setSelectedRows: setSelectedPatients,
					columns: columnsConfig.declined,
					fetchRecords: getDeclinedPatients,
					setPagination: handlePaginationChange,
					limit: pageSize,
				},
				label: "Declined",
				types: [COVID_SURVEY_TYPE],
			},
			{
				props: {
					fetchRecords: getPatientsWithControlLevel,
					columns: columnsConfig.vaccinated,
					setPagination: handlePaginationChange,
					limit: pageSize,
				},
				label: "Vaccinated",
				types: [COVID_SURVEY_TYPE],
			},
			{
				props: {
					fetchRecords: getPatientsForReview,
					columns: columnsConfig.review,
					setPagination: handlePaginationChange,
					limit: pageSize,
				},
				label: "To Review",
				types: [ASTHMA_SURVEY_TYPE],
			},
			{
				props: {
					fetchRecords: getPatientsWithControlLevel,
					columns: columnsConfig.review,
					setPagination: handlePaginationChange,
					limit: pageSize,
				},
				label: "Done",
				types: [ASTHMA_SURVEY_TYPE],
			},
			{
				props: {
					setSelectedRows: setSelectedPatients,
					resetData: changeData,
					columns: columnsConfig.fullPatient,
					fetchRecords: queryAllControlledPatients,
					enqueueSnackbar: enqueueSnackbar,
					setPagination: handlePaginationChange,
					limit: pageSize,
				},
				label: selectedSource ? `Full Patient List(${selectedSource?.controlled_patient_count})` : `Full Patient List`,
				types: [COVID_SURVEY_TYPE],
			},
		];
	});

	const declineVaccination = async () => {
		try {
			setLoading(true);
			const {patient_guid, source_id, gp_guid} = selectedPatient.original_entry;
			await API.graphql(
				graphqlOperation(updateControlledPatientStatus, {
					input: {
						source_id,
						patient_guid,
						gp_guid,
						survey_answered: new Date(),
						survey_response: JSON.stringify({
							ProxySelf: "manual_appointment",
							patient_consent: ["consent_1", "consent_3", "consent_4"],
							dob: selectedPatient?.dob,
							receive_vaccination_1: "No",
						}),
						status: "DECLINED",
					},
				})
			);
			selectedPatient.vaccine = "NO";
			setSelectedPatient(selectedPatient);
			enqueueSnackbar("Successfully updated patient status", {variant: "success"});
		} catch (err) {
			enqueueSnackbar(
				"There was an error while updating the patient status. Please try again, if the problem persists, contanct support@medloop.co",
				{variant: "error"}
			);
		} finally {
			setLoading(false);
		}
	};

	const getCovidDetails = () => {
		return (
			<React.Fragment>
				<div className={classes.drawerHeader}>
					<Typography className={classes.headerText}>Covid Vaccine Programme</Typography>
					<IconButton className={classes.drawerClose} onClick={async () => setSelectedPatient(null)}>
						<CloseIcon />
					</IconButton>
				</div>
				<Typography className={classes.surveyHeading}>
					<Box className={classes.surveyMain}>
						<b>
							{selectedPatient.given_name} {selectedPatient.surname}
						</b>{" "}
						{selectedPatient.nhs_number}
					</Box>
				</Typography>
				<Box className={classes.drawerContent}>
					{isAlreadyBooked() ? (
						<div></div>
					) : (
						<>
							<Box mb={0} className={classes.sectionHeader}>
								Appointments
							</Box>
							<AppointmentBooking
								setIsLoading={setLoading}
								bookedSlots={selectedPatient?.slots}
								mobile={selectedPatient?.phone}
								dob={selectedPatient?.dob}
								hash={selectedPatient?.hash}
								isAdmin={true}
								onBookingComplete={async slots => {
									const {patient_guid, source_id, gp_guid} = selectedPatient.original_entry;
									let updatedPatient = await API.graphql(
										graphqlOperation(updateControlledPatientStatus, {
											input: {
												source_id,
												patient_guid,
												gp_guid,
												survey_answered: new Date(),
												survey_response: JSON.stringify({
													ProxySelf: "manual_appointment",
													patient_consent: ["consent_1", "consent_3", "consent_4"],
													dob: selectedPatient?.dob,
													receive_vaccination_1: "YES",
												}),
												status: "ANSWERED",
											},
										})
									);
									setIsLoading(true);
									const result = await API.graphql(
										graphqlOperation(slotsByPatient, {
											patient_guid: patient_guid,
										})
									);
									selectedPatient.slots = result.data.slotsByPatient.items;
									setSelectedPatient(selectedPatient);
									setIsLoading(false);
								}}
								showSuccessToast={true}
								showErrorToast={true}
								locations={locations}
								vertical={true}
							/>
						</>
					)}
					{getBookedSlots()}
					{!loadingMedications &&
					selectedPatient.status === CONTROLLED_PATIENT_STATUS.MANUAL_CONTACT &&
					selectedPatient.vaccine === "" &&
					(!selectedPatient.slots || selectedPatient.slots.length === 0) ? (
						<Grid item xs={12} md={12} className={classes.decline}>
							<Button fullWidth onClick={declineVaccination} variant="contained" color="primary">
								Decline Vaccination
							</Button>
						</Grid>
					) : (
						<div></div>
					)}
				</Box>
			</React.Fragment>
		);
	};

	const getLabel = key => {
		if (selectedPatient && selectedPatient.processed_response && selectedPatient.processed_response[key]) {
			return selectedPatient.processed_response[key].displayText;
		}
		return key;
	};

	const getValue = key => {
		if (selectedPatient && selectedPatient.processed_response && selectedPatient.processed_response[key]) {
			return selectedPatient.processed_response[key].text;
		}
		return "-";
	};

	const getReading = key => {
		let rate = "-";
		if (selectedPatient && selectedPatient.processed_response && selectedPatient.processed_response[key]) {
			if (selectedPatient.processed_response[key].numeric_value) {
				rate =
					selectedPatient.processed_response[key].numeric_value.Value + " " + selectedPatient.processed_response[key].numeric_value.Units;
			}
			return rate;
		}
		return "-";
	};

	const showNotifyInBulk = () => {
		try {
			if (selectedSource && tabValue !== null) {
				if (selectedSource?.survey?.type === ASTHMA_SURVEY_TYPE) {
					let tabIndex = -1;
					let show = false;
					tabPanelConfig.forEach(item => {
						if (item.types.includes(ASTHMA_SURVEY_TYPE)) {
							tabIndex += 1;
							if (tabIndex === tabValue) {
								show = item.props.selectable ? true : false;
							}
						}
					});
					return show;
				} else if (selectedSource?.survey?.type === COVID_SURVEY_TYPE) {
					let tabIndex = -1;
					let show = false;
					tabPanelConfig.forEach(item => {
						if (item.types.includes(COVID_SURVEY_TYPE)) {
							tabIndex += 1;
							if (tabIndex === tabValue) {
								show = item.props.selectable ? true : false;
							}
						}
					});
					return show;
				} else {
					return false;
				}
			} else {
				return false;
			}
		} catch (error) {
			console.log(error);
			return false;
		}
	};

	const getMedicationsList = () => {
		if (loadingMedications) {
			return <LinearProgress variant="indeterminate" color="primary"></LinearProgress>; //
		} else if (selectedPatient?.active_medications) {
			return selectedPatient.active_medications?.Medication?.map(medication => {
				return (
					<Accordion TransitionProps={{unmountOnExit: true}}>
						<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
							<Box>
								<Typography className={classes.headingAcc} variant="h5">
									{medication.Drug.PreparationID.Term}
								</Typography>
								<Typography className={classes.secondaryAccHeading} variant="caption">
									{medication.Dosage}
								</Typography>
							</Box>
						</AccordionSummary>
						<AccordionDetails>
							<Box>
								<Typography className={classes.accMoreInfo} component="div" variant="p">
									Last Issue Date: {medication.DateLastIssue}
								</Typography>
								<Typography className={classes.accMoreInfo} component="div" variant="p">
									Prescription Type: {medication.PrescriptionType}
								</Typography>
							</Box>
						</AccordionDetails>
					</Accordion>
				);
			});
		}
	};

	const getSurveyAnswerDetails = () => {
		return (
			<React.Fragment>
				<div className={classes.drawerHeader}>
					<Typography className={classes.headerText}>{selectedSource.survey.type} Summary</Typography>
					<IconButton className={classes.drawerClose} onClick={async () => setSelectedPatient(null)}>
						<CloseIcon />
					</IconButton>
				</div>
				<Typography className={classes.surveyHeading}>
					<Box className={classes.surveyMain}>
						<b>
							{selectedPatient.given_name} {selectedPatient.surname}
						</b>{" "}
						{selectedPatient.nhs_number}
					</Box>
				</Typography>

				<React.Fragment>
					<div className={classes.actAndMedicationsContainer}>
						{selectedPatient.status === CONTROLLED_PATIENT_STATUS.TO_REVIEW ? (
							<Box className={classes.drawerContent}>
								<Typography color="textPrimary">
									<Box fontWeight="fontWeightBold" fontSize={20}>
										ACT: {selectedPatient.actScore}
									</Box>
								</Typography>

								<TextField
									key={ACT_SCORE}
									variant={"outlined"}
									margin={"dense"}
									label={"Control Level"}
									fullWidth
									marginBottom={"10px"}
									disabled
									InputProps={{
										startAdornment: (
											<Fragment>
												<span className={clsx(classes.actLabel, classes[getActColor(selectedPatient.actScore)])}>
													{selectedPatient.actScore}
												</span>
												<span className={classes.controltextLablel}>
													{controlLevelText[selectedPatient.control_level]
														? controlLevelText[selectedPatient.control_level]
														: selectedPatient.control_level}
												</span>
											</Fragment>
										),
										classes: {
											disabled: classes.disabledInput,
										},
									}}
								/>
								<TextField
									key={AWARENESS}
									variant={"outlined"}
									margin={"dense"}
									label={getLabel(AWARENESS)}
									fullWidth
									value={getValue(AWARENESS)}
									disabled
									InputProps={{classes: {disabled: classes.disabledInput}}}
								/>
								<Box display="flex">
									{selectedPatient.processed_response ? (
										<Box className={classes.leftInput}>
											<TextField
												key={ACT_SCORE}
												variant={"outlined"}
												margin={"dense"}
												label={getLabel(ACTIVITIES)}
												value={getValue(ACTIVITIES)}
												disabled
												InputProps={{
													classes: {
														disabled: classes.disabledInput,
													},
												}}
											/>
										</Box>
									) : (
										""
									)}

									<TextField
										key={SYMPTOMS}
										variant={"outlined"}
										margin={"dense"}
										label={getLabel(SYMPTOMS)}
										fullWidth
										value={getValue(SYMPTOMS)}
										disabled
										InputProps={{classes: {disabled: classes.disabledInput}}}
									/>
								</Box>
								<Box display="flex">
									<Box className={classes.leftInput}>
										<TextField
											key={AWARENESS}
											variant={"outlined"}
											margin={"dense"}
											label={getLabel(RELIEVER)}
											value={getValue(RELIEVER)}
											disabled
											InputProps={{classes: {disabled: classes.disabledInput}}}
										/>
									</Box>
									<TextField
										key={BREATH}
										variant={"outlined"}
										margin={"dense"}
										label={getLabel(BREATH)}
										fullWidth
										value={getValue(BREATH)}
										disabled
										InputProps={{classes: {disabled: classes.disabledInput}}}
									/>
								</Box>
								<Box display="flex">
									<Box className={classes.leftInput}>
										<TextField
											disabled
											InputProps={{classes: {disabled: classes.disabledInput}}}
											key={"Hospital Admission"}
											variant={"outlined"}
											margin={"dense"}
											label={"Hospital Admission"}
											value={getValue(HOSPITAL_ADMISSION)}
											fullWidth
										/>
									</Box>
									<TextField
										disabled
										InputProps={{classes: {disabled: classes.disabledInput}}}
										key={EXACERBATION}
										variant={"outlined"}
										margin={"dense"}
										label={getLabel(EXACERBATION)}
										value={getValue(EXACERBATION)}
										fullWidth
									/>
								</Box>
								<Box display="flex">
									<Box className={classes.leftInput}>
										<TextField
											key={"BMI"}
											variant={"outlined"}
											margin={"dense"}
											label={"BMI"}
											disabled
											InputProps={{
												startAdornment: (
													<Fragment>
														{selectedPatient && selectedPatient.processed_response ? (
															<span className={classes.textLabel}>
																{calculateBMIFromProcessedResponse(selectedPatient.processed_response)}
															</span>
														) : (
															""
														)}
													</Fragment>
												),
												classes: {
													disabled: classes.disabledInput,
												},
											}}
										/>
									</Box>
									<TextField
										key={SMOKING}
										disabled
										InputProps={{classes: {disabled: classes.disabledInput}}}
										variant={"outlined"}
										margin={"dense"}
										label={"Smoking status"}
										value={selectedPatient && selectedPatient.smokingStatus ? selectedPatient.smokingStatus : "-"}
										fullWidth
									/>
								</Box>
								<Box display="flex">
									<Box className={classes.leftInput}>
										<TextField
											key={CONSULTATION}
											disabled
											InputProps={{classes: {disabled: classes.disabledInput}}}
											variant={"outlined"}
											margin={"dense"}
											value={getValue(CONSULTATION)}
											label={getLabel(CONSULTATION)}
										/>
									</Box>
									<TextField
										key={PEAK_FLOW}
										variant={"outlined"}
										margin={"dense"}
										label={"Peak flow"}
										fullWidth
										value={getReading(PEAK_FLOW)}
										disabled
										InputProps={{
											classes: {disabled: classes.disabledInput},
										}}
									/>
								</Box>
							</Box>
						) : (
							""
						)}

						<Box className={classes.drawerContent} mt={2}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Typography>
										<Box fontSize={18} fontWeight="fontWeightBold" color="textPrimary">
											MEDICATIONS
										</Box>
									</Typography>
								</Grid>
								<Grid item xs={12}>
									{getMedicationsList()}
								</Grid>
							</Grid>
						</Box>
					</div>
					<Box className={classes.drawerFooter}>
						<Button onClick={saveToEmis} disabled={selectedPatient.status !== "TO_REVIEW"} className={classes.saveButton}>
							Save and file to EMIS
						</Button>
					</Box>
				</React.Fragment>
			</React.Fragment>
		);
	};

	const getPatientDetails = () => {
		if (selectedSource.survey.type === COVID_SURVEY_TYPE) {
			return getCovidDetails();
		}
		return getSurveyAnswerDetails();
	};

	return (
		<React.Fragment>
			<Box margin={2}>
				<Typography component="h5" variant="h5" className={classes.pageHeader}>
					Patient Manager
				</Typography>
				<FormControl>
					<Select
						id="demo-simple-select-outlined"
						value={sourceList.length > 0 ? selectedSource : "None"}
						onChange={handleChange}
						//disabled={selectedSource.length == 0}
						variant="outlined"
						classes={{
							root: classes.sourceSelect,
							select: classes.sourceMenu,
						}}
					>
						{renderSourceItems()}
					</Select>
				</FormControl>
			</Box>
			{isLoading && <LinearProgress variant="indeterminate" color="primary" />}
			<div className={classes.tabBar}>
				<Tabs value={tabValue} indicatorColor="primary" textColor="primary" onChange={handleTabChange} aria-label="patient-tabs">
					{tabPanelConfig
						.filter(config => config.types.includes(selectedSource?.survey?.type))
						.map((config, index) => {
							return <Tab className={classes.tabContent} key={index} {...a11yProps(index)} label={config.label}></Tab>;
						})}
				</Tabs>
				<ConfirmDialog
					title={"Notify selected patients"}
					content={"You are about to send sms invitation to " + getSelectedPatientsCount() + " patient(s), Are you sure?"}
					open={openNotifyDialog}
					setOpen={setOpenNotifyDialog}
					onConfirm={handleBulkNotify}
				/>
				{showNotifyInBulk() ? (
					<Button
						variant="contained"
						color="secondary"
						disabled={!(selectedPatients && selectedPatients.length)}
						className={classes.bulkNotify}
						startIcon={<SendIcon />}
						onClick={handleOpenNotifyDialog}
					>
						Notify selected
					</Button>
				) : null}
			</div>
			{initializeTable
				? tabPanelConfig
						.filter(config => config.types.includes(selectedSource?.survey?.type))
						.map((config, index) => {
							return (
								<TabPanel className={selectedPatient ? classes.tabPanel2 : classes.tabPanel} key={index} value={tabValue} index={index}>
									<EnhancedTable {...config.props} selectedPatient={selectedPatient} />
								</TabPanel>
							);
						})
				: null}

			{selectedPatient && (
				<Drawer
					className={classes.drawer}
					variant="persistent"
					anchor="right"
					open={Boolean(selectedPatient)}
					classes={{
						paper: classes.drawerPaper,
					}}
				>
					{getPatientDetails()}
				</Drawer>
			)}
			<Dialog open={modifyOpen} onClose={handleModifyClose} aria-labelledby="complete-dialog">
				<ModifyBookings
					oldSlot={modifySlot}
					user={user}
					handleClose={handleModifyClose}
					setLoadingBackdrop={setLoading}
					refreshEvents={updateSlot}
				/>
			</Dialog>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</React.Fragment>
	);
}
