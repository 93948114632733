import React, {useEffect, useState, useCallback, useRef} from "react";

import {useSnackbar} from "notistack";
import moment from "moment";

import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";

import {API, graphqlOperation, Auth} from "aws-amplify";

import {listSlotss, controlledPatientByHash, slotsByStatus, locationByGpAndPostCode} from "../graphql/queries";
import {updateSlots, createSlots, updateControlledPatient, deleteSlots} from "../graphql/mutations";
import {
	Box,
	Typography,
	Button,
	Grid,
	CircularProgress,
	Backdrop,
	TextField,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Select,
	MenuItem,
	Avatar,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {makeStyles, withStyles, useTheme} from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import {useHistory} from "react-router-dom";

import {useJsonToCsv} from "react-json-csv";

import "../App.css";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import CakeIcon from "@material-ui/icons/Cake";
import PhoneIcon from "@material-ui/icons/Phone";
import IconButton from "@material-ui/core/IconButton";
import SmsIcon from "@material-ui/icons/Sms";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import ModifyBookings from "../components/ModifyBookings.js";
import SlotFilter from "../components/SlotFilter";

const useStyles = makeStyles(theme => ({
	dialogTitle: {
		padding: theme.spacing(0),
		paddingBottom: theme.spacing(2),
		fontSize: 24,
		fontWeight: 500,
		color: theme.palette.secondary.dark,
	},
	dialogContent: {
		padding: theme.spacing(0),
		overflowY: "unset",
	},
	dialogSub: {
		paddingBottom: theme.spacing(0.5),
		fontSize: 16,
		marginBottom: theme.spacing(0),
		color: theme.palette.secondary.dark,
	},
	dialogContentGroup: {
		padding: theme.spacing(2, 0),
	},
	dialogFooter: {
		padding: theme.spacing(0),
	},
	closeButton: {
		cursor: "pointer",
		float: "right",
	},
}));
export default function CompleteAppointments(props) {
	const classes = useStyles();
	const {enqueueSnackbar} = useSnackbar();

	const {getSlots, setLoading, callback, isOpen, setIsOpen} = props;

	const [values, setValues] = React.useState({
		comments: "",
	});

	const handleClose = () => setIsOpen(false);

	const handleChange = prop => event => {
		event.preventDefault();
		setValues({...values, [prop]: event.target.value});
	};

	const clearMessage = () => {
		setValues({comments: ""});
	};

	const updateStatus = async ({slot, from_status, to_status}) => {
		try {
			var input = {
				id: slot.id,
				patient_hash: slot.patient_hash,
				gp_guid: slot.gp_guid,
				status: to_status,
				year: slot.year,
				month: slot.month,
				day: slot.day,
				hour: slot.hour,
				minute: slot.minute,
				staff_id: slot.location.staff_id,
			};
			const res = await API.graphql(
				graphqlOperation(updateSlots, {
					input: input,
					...(from_status && {condition: {status: {eq: from_status}}}),
				})
			);
			return res;
		} catch (error) {
			console.log(error);
			enqueueSnackbar("Error occurred. Please try again", {variant: "error"});
		}
	};

	const complete = async _slots => {
		//TODO handle all errors at once.
		await Promise.all(
			_slots.map(slot => {
				return updateStatus({slot: slot, from_status: "BOOKED", to_status: "COMPLETED"});
			})
		);
	};

	const submit = async () => {
		try {
			setLoading(true);
			let _slots = getSlots().map(slot => {
				slot["comments"] = values.comments;
				return slot;
			});
			await complete(_slots);
			callback();
			enqueueSnackbar("Appointment(s) Completed", {variant: "success"});
			//await saveToEmis(info.event.extendedProps.slot.patient_hash, values.comments);
		} catch (error) {
			console.log(error);
			enqueueSnackbar("Error occurred. Please try again", {variant: "error"});
		} finally {
			handleClose();
			clearMessage();
			setLoading(false);
		}
	};
	return (
		<Dialog
			open={isOpen}
			onClose={() => {
				handleClose();
				clearMessage();
			}}
			aria-labelledby="complete-dialog"
		>
			<DialogTitle id="form-dialog-title">Are you sure you want to complete the selected vaccination(s)?</DialogTitle>
			<DialogContent>
				<Grid container spacing={1}>
					<Grid item md={12} xs={12}>
						<TextField
							autoFocus
							margin="dense"
							label="Consultation Comments"
							multiline
							rows={5}
							value={values.comments}
							onChange={handleChange("comments")}
							fullWidth
							variant="outlined"
						/>
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions>
				<Button
					onClick={() => {
						handleClose();
						clearMessage();
					}}
					color="primary"
				>
					Cancel
				</Button>

				<Button onClick={() => submit()} color="primary">
					Submit
				</Button>
			</DialogActions>
		</Dialog>
	);
}
