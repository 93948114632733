import {Grid, Box, CircularProgress, TextField, Typography, Button} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import ICONS from "../utils/icons";
import React, {useState} from "react";
import {API} from "aws-amplify";
import ConfirmDialog from "../components/ConfirmDialog.js";
import {useSnackbar} from "notistack";
import Backdrop from "@material-ui/core/Backdrop";
import withWidth, {isWidthUp} from "@material-ui/core/withWidth";

const useStyle = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		padding: theme.spacing(2),
		backgroundColor: theme.palette.background.paper,
	},
	// root: {
	// 	flex: "1",
	// 	display: "flex",
	// 	flexDirection: "column",
	// 	alignItems: "center",
	// 	justifyContent: "center",
	// 	backgroundImage: `url(${LoginBG})`,
	// 	"& .MuiTextField-root": {
	// 		margin: theme.spacing(1),
	// 		width: 200,
	// 	},
	// },
	subTitle: {
		letterSpacing: "0.75px",
		fontSize: "18px",
		fontWeight: 300,
		textAlign: "center",
		color: "white",
		marginBottom: "3%",
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
	},
}));

const PatientCancellation = props => {
	const classes = useStyle();
	const {
		match: {params},
	} = props;

	const [values, setValues] = useState({
		mobile: "",
		dob: "",
	});

	const [confirmDelete, setConfirmDelete] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [isSuccess, setIsSuccess] = useState(false);
	const [isError, setisError] = useState(false);
	const {enqueueSnackbar} = useSnackbar();

	const handleChange = prop => event => {
		setisError(false);
		setValues({...values, [prop]: event.target.value});
	};

	const handleCancellationSubmit = async () => {
		try {
			setIsLoading(true);
			const requestParams = {
				body: {
					// phone: values.mobile,
					birthdate: values.dob,
				},
			};
			const result = await API.del("appointments", `/slots/${params.hash}`, requestParams);
			console.log("response", result);
			setIsSuccess(true);
		} catch (error) {
			console.log(error);
			enqueueSnackbar(error?.response?.data?.error,  {variant: "error"});
		} finally {
			setIsLoading(false);
		}
	};

	const handleSubmit = async () => {
		setConfirmDelete(true);
	};

	const showMedloopLogo = () => {
		console.log(props.width);
		if (isWidthUp("md", props.width)) {
			return (
				<Box textAlign="right">
					<Typography color="textPrimary">
						<Box display="flex" alignContent="center" justifyContent="flex-end">
							<span>In Collaboration with </span>
							<span style={{marginLeft: "8px"}}>{ICONS.MEDLOOP_LOGO_GREEN}</span>
						</Box>
					</Typography>
				</Box>
			);
		}
		return (
			<Box display="flex" flexDirection="row-reverse">
				{ICONS.MEDLOOP_LOGO_SMALL}
			</Box>
		);
	};

	const getSuccessView = () => {
		return (
			<Grid container justify="center" spacing={3}>
				<Grid item xs={12} md={8}>
					{/* <Box m={2} textAlign="right">
						<Typography color="textPrimary">
							<Box display="flex" alignContent="right" justifyContent="flex-end">
								<span>In Collaboration with </span>
								<span style={{marginLeft: "8px"}}>{ICONS.MEDLOOP_LOGO_GREEN}</span>
							</Box>
						</Typography>
					</Box> */}
					{/* <Grid> */}
					<Box display="flex" justifyContent="center" mt={8}>
						<Typography color="textPrimary" variant="h4" gutterBottom>
							Your appoinment has been cancelled successfully
						</Typography>
					</Box>
					<Box display="flex" alignContent="left" justifyContent="center" m={2}>
						<span>We will send you a message confirming this.</span>
					</Box>
					{/* </Grid> */}
				</Grid>
			</Grid>
		);
	};

	return (
		<React.Fragment>
			<div className={classes.root}>
				<Grid container spacing={3} justify="center">
					<Grid item md={8} xs={12}>
						{showMedloopLogo()}
					</Grid>
					<Grid item md={8} xs={12}>
						{!isSuccess ? (
							<Grid container justify="center" spacing={3}>
								<Grid item md={8} xs={12}>
									{/* <Box m={2} textAlign="right">
										<Typography color="textPrimary">
											<Box display="flex" alignContent="right" justifyContent="flex-end">
												<span>In Collaboration with </span>
												<span style={{marginLeft: "8px"}}>{ICONS.MEDLOOP_LOGO_GREEN}</span>
											</Box>
										</Typography>
									</Box> */}
									{/* <Grid> */}
									<Box display="flex" alignContent="left" justifyContent="space-between" m={2}>
										<Typography color="textPrimary" variant="h4" gutterBottom>
											Are you sure you would like to cancel appointments?
										</Typography>
									</Box>
									<Box display="flex" alignContent="left" justifyContent="space-between" m={2}>
										<span>
											If so, please, provide your date of birth below to confirm cancellation of all covid vaccination appointments:
										</span>
									</Box>
									{/* </Grid> */}
								</Grid>
								<Grid item md={8} xs={12}>
									<form noValidate autoComplete="off">
										{/* <Box m={1} display="flex" textAlign="left" alignContent="left">
								<TextField
									autoFocus
									margin="dense"
									id="mobile"
									label="Mobile Number"
									fullWidth
									error={isError}
									helperText={isError ? "Please, inform the mobile number used to register in your GP." : ""}
									defaultValue={values.mobile}
									onChange={handleChange("mobile")}
									InputLabelProps={{
										shrink: true,
									}}
									variant="outlined"
								/>
							</Box> */}
										<Box m={1} display="flex" textAlign="left" alignContent="left">
											<TextField
												id="dob"
												label="Brith Date"
												type="date"
												fullWidth
												error={isError}
												helperText={isError ? "Please, inform the Date of Birth used to register in your GP." : ""}
												defaultValue={values.dob}
												onChange={handleChange("dob")}
												InputLabelProps={{
													shrink: true,
												}}
												variant="outlined"
											/>
										</Box>
									</form>
									<Box m={1} display="right" textAlign="right" alignContent="right">
										<Button className={classes.submitButton} variant="contained" color="secondary" onClick={handleSubmit}>
											Submit
										</Button>
									</Box>
								</Grid>
							</Grid>
						) : (
							getSuccessView()
						)}
					</Grid>
				</Grid>

				<Backdrop className={classes.backdrop} open={isLoading}>
					<CircularProgress color="inherit" />
				</Backdrop>
				<ConfirmDialog
					title={"Cancel appoinments"}
					content={"You are about to cancel your appoinments, Are you sure?"}
					open={confirmDelete}
					setOpen={setConfirmDelete}
					onConfirm={handleCancellationSubmit}
				/>
			</div>
		</React.Fragment>
	);
};
export default withWidth()(PatientCancellation);
