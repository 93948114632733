import React, {useEffect, useState} from "react";

import {makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import {API, Auth, graphqlOperation} from "aws-amplify";
import {patientByNhsNumber, listControlledPatients} from "../graphql/queries";

import {
	Button,
	TextField,
	Box,
	Backdrop,
	CircularProgress,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	Paper,
	Chip,
	Divider,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import {Chart, ArgumentAxis, ValueAxis, LineSeries, Title, Legend} from "@devexpress/dx-react-chart-material-ui";
import {withStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {ArgumentScale, Animation} from "@devexpress/dx-react-chart";
import {curveCatmullRom, line} from "d3-shape";
import {scalePoint} from "d3-scale";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {useSnackbar} from "notistack";

const useStyles = makeStyles(theme => ({
	root: {
		width: "100%",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		// backgroundColor: theme.palette.background.paper,
	},
	inline: {
		display: "inline",
	},
	avatar: {
		backgroundColor: "theme.palette.primary.main", // done
	},
	paper: {
		padding: theme.spacing(4),
		width: "100%",
	},
	buttonWrap: {
		textAlign: "center",
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
	},
	drawerContent: {
		maxHeight: "300px",
		display: "flex",
		flexDirection: "column",
		overflow: "auto",
		flex: 1,
	},
}));

export default function PDSTest(props) {
	const classes = useStyles();
	const [loading, setLoading] = useState(false);
	const [patient, setPatient] = useState({});
	const [values, setValues] = useState({
		family: "",
		gender: "",
		birthdate: "",
		nhsNumber: "",
		given: "",
	});
	const {enqueueSnackbar} = useSnackbar();
	const search = async () => {
		const requestParams = {
			queryStringParameters: {
				...(values.family && {family: values.family}),
				...(values.given && {given: values.given}),
				...(values.gender && {gender: values.gender}),
				...(values.birthdate && {birthdate: `eq${values.birthdate}`}),
				// "_fuzzy-match": true
			},
		};
		try {
			let result;
			if (values.nhsNumber) {
				result = await API.get("pdsProxy", `/pds/Patient/${values.nhsNumber}`);
			} else {
				result = await API.get("pdsProxy", `/pds/Patient`, requestParams);
				console.log(result);
			}

			setPatient(result);
		} catch (error) {
			console.log(error);
			enqueueSnackbar("error talking to nhs", {variant: "error"});
		}
	};

	const handleChange = prop => event => {
		setValues({...values, [prop]: event.target.value});
	};

	return (
		<div className={classes.paper}>
			<Grid container justify="center" spacing={2}>
				<Grid item md={6} xs={12}>
					<Grid container justify="center" spacing={2}>
						<Grid item md={12} xs={12}>
							<Typography component="div">
								<Box fontSize={20} fontWeight="fontWeightBold" color="textPrimary">
									PDS Test Page
								</Box>
							</Typography>
						</Grid>

						<Grid item md={12} xs={12}>
							<TextField
								onChange={handleChange("birthdate")}
								value={values.birthdate}
								fullWidth
								id="outlined-search1"
								label="birthdate"
								variant="outlined"
							/>
						</Grid>

						<Grid item md={12} xs={12}>
							<TextField
								onChange={handleChange("gender")}
								value={values.gender}
								fullWidth
								id="outlined-search4"
								label="gender"
								variant="outlined"
							/>
						</Grid>

						{/* <Grid item md={12} xs={12}>
							<TextField
								onChange={handleChange("given")}
								value={values.given}
								fullWidth
								id="outlined-search2"
								label="Name"
								variant="outlined"
							/>
						</Grid> */}

						<Grid item md={12} xs={12}>
							<TextField
								onChange={handleChange("family")}
								value={values.family}
								fullWidth
								id="outlined-search2"
								label="Surname"
								variant="outlined"
							/>
						</Grid>

						<Grid item md={12} xs={12}>
							<TextField
								onChange={handleChange("nhsNumber")}
								value={values.nhsNumber}
								fullWidth
								id="outlined-search3"
								label="NHS Number"
								variant="outlined"
							/>
						</Grid>

						<Grid item md={12} xs={12}>
							<Button onClick={search} fullWidth id="outlined-bt" color="primary" variant="outlined">
								Search
							</Button>
						</Grid>
					</Grid>
				</Grid>
				<Grid item md={6} xs={12}>
					<Grid container justify="center" spacing={2}>
						<Grid item md={12} xs={12}>
							<Typography component="div">
								<Box fontSize={20} fontWeight="fontWeightBold" color="textPrimary">
									Results
								</Box>
							</Typography>
						</Grid>
						<Grid item md={12} xs={12}>
							<Typography component="div">
								<Box fontSize={14} fontWeight="fontWeightBold" color="textPrimary">
									<div>
										<pre>{JSON.stringify(patient, null, 2)}</pre>
									</div>
								</Box>
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</div>
	);
}
