import DateFnsUtils from "@date-io/date-fns";
import {Box, Button, CircularProgress, Grid, MenuItem, Select, Typography} from "@material-ui/core";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import {makeStyles, withStyles} from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {API, graphqlOperation} from "aws-amplify";
import {useSnackbar} from "notistack";
import React, {useEffect, useState} from "react";
import "survey-react/survey.css";
import {adminCancelAppointment} from "../graphql/mutations";
import {sendCustomSms, updateSlots} from "../graphql/mutations";
import {locationByGpAndPostCode} from "../graphql/queries";
import {getDateFormatted} from "../utils/utils.js";

const useStyles = makeStyles(theme => ({
	bookingWrapper: {
		display: "flex",
	},
	bookingRow: {
		display: "flex",
		justifyContent: "space-between",
		padding: theme.spacing(1.5, 0),
	},
	slots: {
		flex: 1,
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
		paddingTop: theme.spacing(2),
	},
	slotsWrapper: {
		maxHeight: "300px",
		overflow: "auto",
	},
	appoinmentHeader: {
		display: "flex",
		flexDirection: "column",
		marginTop: theme.spacing(2),
	},
	appoinmentinfo: {
		paddingBottom: theme.spacing(1),
	},
	dayRed: {
		"& .MuiPickersDay-day": {
			backgroundColor: "#E08A8C",
		},
	},
	dayGreen: {
		"& .MuiPickersDay-day": {
			backgroundColor: "#AEE8E4",
		},
	},
	dayYellow: {
		"& .MuiPickersDay-day": {
			backgroundColor: "#FFCD80",
		},
	},
	daySelected: {
		"& .MuiPickersDay-day": {
			backgroundColor: "#1A9A92",
		},
	},
	locationIcon: {
		marginRight: theme.spacing(3.25),
		color: "rgba(0,0,0,0.6)",
	},
	bookingSection: {
		paddingTop: theme.spacing(2),
	},
	actions: {
		display: "flex",
		justifyContent: "space-between",
		width: "100%",
		paddingTop: theme.spacing(3),
		padding: theme.spacing(2, 3),
	},
	cancelButton: {
		backgroundColor: "rgba(248, 97, 87, 0.08)",
		color: "rgba(216, 81, 79, 0.87)",
		borderRadius: theme.spacing(1),
	},
	exitButton: {
		backgroundColor: "rgba(46, 54, 90, 0.08)",
		color: "rgba(46, 54, 90, 0.87)",
		marginRight: theme.spacing(2),
		borderRadius: theme.spacing(1),
	},
	subtitle: {
		fontSize: 16,
		lineHeight: 1.75,
		letterSpacing: "0.15px",
		color: "rgba(2, 9, 39, 0.87)",
	},
	previousBooked: {
		height: theme.spacing(5),
		borderRadius: theme.spacing(0.5),
		backgroundColor: "#f5f5fe",
		display: "flex",
		justifyContent: "flex-start",
		alignItems: "center",
		marginTop: theme.spacing(3),
		marginBottom: theme.spacing(3),
		padding: theme.spacing(0, 2),
	},
	paddingLeft: {
		paddingLeft: theme.spacing(1.5),
		color: "#6253d8",
	},
	closeButton: {
		position: "absolute",
		right: theme.spacing(1),
		top: theme.spacing(1),
		color: theme.palette.grey[500],
	},
}));
const styles = {
	root: {
		display: "flex",
		width: "100%",
	},
	closeButton: {
		position: "absolute",
		right: "8px",
		top: "8px",
		color: "500",
	},
};

const DialogTitle = withStyles(styles)(props => {
	const {children, classes, patient_detail, onClose, ...other} = props;
	return (
		<MuiDialogTitle disableTypography className={classes.root} {...other}>
			<Typography variant="h6">{`Modify booking for ${patient_detail?.patient_name} ${patient_detail?.patient_surname}`}</Typography>
			{onClose ? (
				<IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
					<CloseIcon />
				</IconButton>
			) : null}
		</MuiDialogTitle>
	);
});

export default function ModifyBooking(props) {
	const classes = useStyles();
	const {oldSlot, user, handleClose, setLoadingBackdrop, refreshEvents} = props;
	const [slotDate, setSlotDate] = useState(null);
	const [selectedSlot, setSelectedSlot] = useState(null);
	const [loadingSlots, setLoadingSlots] = useState(false);
	const [slots, setSlots] = useState([]);

	const [locations, setLocations] = useState([]);
	const [location, setLocation] = useState(null);
	const [availabilityMap, setAvailabilityMap] = useState({});
	const [selectedMonth, setSelectedMonth] = useState(null);
	const [loading, setLoading] = useState(false);
	const {enqueueSnackbar} = useSnackbar();
	const [isSameSlot, setIsSameSlot] = useState(false);

	useEffect(() => {
		setLoading(true);
		const getLocations = async () => {
			try {
				const result = await API.graphql(
					graphqlOperation(locationByGpAndPostCode, {
						gp_guid: user.attributes["custom:gp_guid"],
					})
				);

				console.log(result.data.locationByGPAndPostCode.items);
				setLocations(result.data.locationByGPAndPostCode.items);
				setLocation(result.data.locationByGPAndPostCode.items[0]);
			} catch (error) {
				console.log(error);
			} finally {
				setLoading(false);
			}
		};
		getLocations();
	}, [user.attributes]);

	useEffect(() => {
		if (!oldSlot) return;
		const date = getDateFromSlot(oldSlot);
		const monthDate = getDateFromSlot(oldSlot);
		setSlotDate(date);
		const today = new Date();
		if (monthDate.getMonth() === today.getMonth()) {
			monthDate.setDate(today.getDate());
		} else {
			monthDate.setDate(1);
		}
		setSelectedMonth(monthDate);
	}, [oldSlot]);

	const getDateFromSlot = value => {
		return new Date(value.year + "-" + value.month + "-" + value.day + "T" + value.hour + ":" + value.minute);
	};

	useEffect(() => {
		if (!selectedMonth || !oldSlot) return;
		const date = getDateFormatted(selectedMonth);
		if (!location || !location.staff_id) return;
		const getAvailabilityMap = async () => {
			try {
				setLoadingSlots(true);
				const requestParams = {
					queryStringParameters: {
						date: date,
						location: location.staff_id,
					},
				};
				let response = await API.get("appointments", `/slots/availability/${oldSlot.patient_hash}`, requestParams);
				console.log("map", response);
				setAvailabilityMap(response.slotsAvailabilityMap);
			} catch (error) {
				console.log(error);
			} finally {
				setLoadingSlots(false);
			}
		};
		getAvailabilityMap();
	}, [selectedMonth, location, oldSlot]);

	useEffect(() => {
		if (!slotDate || !oldSlot) return;
		const date = getDateFormatted(slotDate);
		if (!location || !location.staff_id) return;
		const getSlots = async () => {
			try {
				setLoadingSlots(true);
				const requestParams = {
					queryStringParameters: {
						date: date,
						location: location.staff_id,
					},
				};
				let response = await API.get("appointments", `/slots/${oldSlot.patient_hash}`, requestParams);
				setSlots(response.slots);
			} catch (error) {
				console.log(error);
			} finally {
				setLoadingSlots(false);
			}
		};
		getSlots();
	}, [slotDate, location, oldSlot]);

	const handleSlotClick = (value, type) => {
		checkIfSameSlot(value) ? setIsSameSlot(true) : setIsSameSlot(false);
		setSelectedSlot(value);
	};

	const checkIfSameSlot = value => {
		if (value && oldSlot && location) {
			return (
				location.staff_id === oldSlot.staff_id &&
				value.year === oldSlot.year &&
				value.month === oldSlot.month &&
				value.day === oldSlot.day &&
				value.hour === oldSlot.hour &&
				value.minute === oldSlot.minute
			);
		}
		return false;
	};

	const handleSlotDateChange = async (value, type) => {
		setSelectedSlot(null);
		setSlotDate(value);
	};

	const handleChangeLocation = (event, type) => {
		setLocation(event.target.value);
		setSlots([]);
	};

	const getDateKey = date => {
		let year = date.getFullYear();
		let month = ("0" + (date.getMonth() + 1)).slice(-2);
		let day = ("0" + date.getDate()).slice(-2);
		return `${year}#${month}#${day}`;
	};

	const disableDates = day => {
		let key = getDateKey(day);
		let numAppointments = availabilityMap[key];
		if (!numAppointments || numAppointments[key] === 0) return true;
		return false;
	};

	const applyDayCss = (day, selectedDate, dayInCurrentMonth, dayComponent) => {
		let key = getDateKey(day);
		let selectedKey = getDateKey(selectedDate);
		let d = ("0" + day.getDate()).slice(-2);

		let numAppointments = availabilityMap[key];
		//availabilityMap;
		if (day.getTime() === selectedDate.getTime()) {
			return <div className={classes.daySelected}>{dayComponent}</div>;
		}
		if (numAppointments > 10) {
			return <div className={classes.dayGreen}>{dayComponent}</div>;
		} else if (numAppointments >= 5) {
			return <div className={classes.dayYellow}>{dayComponent}</div>;
		} else if (numAppointments > 0) {
			return <div className={classes.dayRed}>{dayComponent}</div>;
		}
		return dayComponent;
	};

	//TODO we can return a promise where it's resolved only when the available slots are loaded.
	//onMonthChange
	//(date: DateIOType) => void | Promise<void>
	//Callback firing on month change. Return promise to render spinner till it will not be resolved
	const changeMonth = date => {
		setSlotDate(null);
		setSlots([]);
		let now = new Date();
		if (date.getTime() < now.getTime()) {
			setSelectedMonth(now);
		}
		setSelectedMonth(date);
	};

	const handleCancelAppointment = async () => {
		setLoadingBackdrop(true);
		try {
			await API.graphql(
				graphqlOperation(adminCancelAppointment, {
					paramsStringified: JSON.stringify([
						{
							patientGuid: oldSlot.patient_guid,
							gpGuid: oldSlot.gp_guid,
							sourceId: JSON.parse(oldSlot.reason).source_id,
							slotId: oldSlot.id,
						},
					]),
				})
			);
			enqueueSnackbar("Slot cancelled successfully.", {variant: "success"});
			handleClose();
			refreshEvents();
		} catch (e) {
			enqueueSnackbar("Error cancelling slot", {variant: "error"});
		} finally {
			setLoadingBackdrop(false);
		}
	};

	const sendNotif = async (slot, values, type) => {
		// TO:DO Add sender ID here
		try {
			await API.graphql(
				graphqlOperation(sendCustomSms, {
					paramsStringified: JSON.stringify({
						patientGuid: slot.patient_guid,
						gpGuid: slot.gp_guid,
						sourceId: JSON.parse(slot.reason).source_id,
						type,
						content: values.message,
					}),
				})
			);
			enqueueSnackbar("SMS sucessfully sent.", {variant: "success"});
			handleClose();
		} catch (err) {
			console.log(`ERROR: ${JSON.stringify(err)}`);
			enqueueSnackbar("Error sending SMS", {variant: "error"});
		}
	};

	const handleModifyAppointment = async () => {
		setLoadingBackdrop(true);
		let success = true;
		const reason = oldSlot.reason;
		let patient_detail = JSON.parse(oldSlot.reason);
		const patient_guid = oldSlot.patient_guid;
		const patient_hash = oldSlot.patient_hash;
		const title = oldSlot.title;
		const staff_id = oldSlot.location.staff_id;
		let messageString = `Hello ${patient_detail.patient_name}, Your covid-19 vaccination information is updated, new dates are: `;
		try {
			const oldSlot1 = {...oldSlot};
			const initial_status = oldSlot1.status;
			oldSlot1.status = "AVAILABLE";
			oldSlot1.staff_id = oldSlot1.location.staff_id;
			delete oldSlot1.location;
			oldSlot1.patient_guid = null;
			oldSlot1.patient_hash = null;
			delete oldSlot1.comments;
			delete oldSlot1.reason;
			delete oldSlot1.patient_ods_code;
			delete oldSlot1.title;
			delete oldSlot1.parent;
			await API.graphql(
				graphqlOperation(updateSlots, {
					input: oldSlot1,
					...(initial_status && {condition: {status: {eq: initial_status}}}),
				})
			);
		} catch (err) {
			enqueueSnackbar("Error modifying appointments", {variant: "error"});
			console.log(err);
			success = false;
		}
		if (success) {
			try {
				const initial_status2 = selectedSlot.status;
				selectedSlot.status = "BOOKED";
				selectedSlot.staff_id = location.staff_id;
				selectedSlot.patient_guid = patient_guid;
				selectedSlot.patient_hash = patient_hash;
				selectedSlot.reason = reason;
				selectedSlot.title = title;
				delete selectedSlot.__typename;
				delete selectedSlot.parent;
				await API.graphql(
					graphqlOperation(updateSlots, {
						input: selectedSlot,
						...(initial_status2 && {condition: {status: {eq: initial_status2}}}),
					})
				);
				messageString += `${selectedSlot.day}.${selectedSlot.month}.${selectedSlot.year} `;
				sendNotif(selectedSlot, {message: messageString}, "MODIFY_BOOKING");
				refreshEvents();
				handleClose();
			} catch (err) {
				enqueueSnackbar("Error modifying appointments", {variant: "error"});
				console.log(err);
				revertAppointmentToBooked(oldSlot, patient_guid, patient_hash, reason, title, staff_id);
				success = false;
			}
		}
		setLoadingBackdrop(false);
	};

	const revertAppointmentToBooked = async (slot, patient_guid, patient_hash, reason, patient_title, staff_id) => {
		try {
			const initial_status = slot.status;
			slot.status = "BOOKED";
			slot.patient_guid = patient_guid;
			slot.patient_hash = patient_hash;
			slot.staff_id = staff_id;
			slot.reason = reason;
			slot.title = patient_title;
			delete slot.__typename;
			await API.graphql(
				graphqlOperation(updateSlots, {
					input: slot,
					...(initial_status && {condition: {status: {eq: initial_status}}}),
				})
			);
		} catch (err) {
			console.log(err);
			enqueueSnackbar("Error cancelling appointments", {variant: "error"});
		}
	};

	const bookAppointmentPanel = () => {
		const patient_detail = oldSlot && oldSlot.reason ? JSON.parse(oldSlot.reason) : "";
		return (
			<Grid container justify="center" className={classes.root}>
				<DialogTitle patient_detail={patient_detail} onClose={handleClose}></DialogTitle>
				<DialogContent>
					<Grid>
						<Typography className={classes.subtitle}>{"Update the date, time & location for the vaccination"}</Typography>
						<Grid container className={classes.previousBooked}>
							<InfoOutlinedIcon style={{color: "#6253d8"}} />
							<Typography
								className={classes.paddingLeft}
							>{`currently booked slot - ${location?.name}, ${oldSlot?.day}.${oldSlot?.month}.${oldSlot?.year}, ${oldSlot?.hour}:${oldSlot?.minute}`}</Typography>
						</Grid>
						<Grid item>
							<Typography>Locations</Typography>
							<Select
								fullWidth
								margin="dense"
								IconComponent={() => <LocationOnIcon className={classes.locationIcon} />}
								variant="outlined"
								// label="Location"
								id="demo-simple-select"
								value={location}
								onChange={e => handleChangeLocation(e)}
							>
								{locations.map(location => (
									<MenuItem key={location.staff_id} value={location}>
										{location.name}
									</MenuItem>
								))}
							</Select>
						</Grid>
						<Grid item className={classes.bookingSection}>
							<MuiPickersUtilsProvider utils={DateFnsUtils}>
								<KeyboardDatePicker
									// disableToolbar
									fullWidth
									allowKeyboardControl={true}
									inputVariant="outlined"
									format="MM-dd-yyyy"
									margin="dense"
									label="Date"
									id="date-picker-inline"
									value={slotDate}
									onChange={e => handleSlotDateChange(e, "first")}
									KeyboardButtonProps={{
										"aria-label": "change date",
									}}
									onMonthChange={changeMonth}
									renderDay={applyDayCss}
									shouldDisableDate={disableDates}
									minDate={Date.now()}
									autoOk
								/>
							</MuiPickersUtilsProvider>
						</Grid>
						<Grid className={classes.slots}>
							{loadingSlots ? (
								<Box style={{width: "100%"}} display="flex" justifyContent="center">
									<CircularProgress color="inherit" />
								</Box>
							) : slots.length > 0 ? (
								<Grid container spacing={2} className={classes.slotsWrapper}>
									{slots.map(slot => {
										if (checkIfSameSlot(slot)) {
											return <div></div>;
										}
										return (
											<Grid item xs={6} md={4}>
												<Button
													fullWidth
													disabled={selectedSlot?.id === slot.id}
													variant="contained"
													size="medium"
													disableElevation
													onClick={() => handleSlotClick(slot, "first")}
												>{`${slot.hour}:${slot.minute}`}</Button>
											</Grid>
										);
									})}
								</Grid>
							) : (
								<Box style={{width: "100%"}} display="flex" justifyContent="center">
									<Typography align="center" component="div">
										<Box fontSize={20} color="textPrimary">
											{slotDate ? `No Slots Available on: ${slotDate.toLocaleDateString()}` : ""}
										</Box>
									</Typography>
								</Box>
							)}
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions className={classes.actions}>
					<Grid item>
						<Button variant="contained" disableElevation className={classes.cancelButton} onClick={() => handleCancelAppointment()}>
							Cancel Appointment
						</Button>
					</Grid>
					<Grid item>
						<Button variant="contained" disableElevation className={classes.exitButton} onClick={() => handleClose()}>
							Exit
						</Button>
						<Button
							variant="contained"
							disabled={!selectedSlot || !slotDate || isSameSlot}
							style={{borderRadius: "8px"}}
							disableElevation
							className={classes.saveButton}
							color="primary"
							onClick={() => handleModifyAppointment()}
							autoFocus
						>
							Save and notify
						</Button>
					</Grid>
				</DialogActions>
			</Grid>
		);
	};

	return bookAppointmentPanel();
}
