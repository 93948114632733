/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listSources = /* GraphQL */ `
  query ListSources(
    $source_id: String
    $filter: ModelSourceFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSources(
      source_id: $source_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        source_id
        type
        name
        create_at
        survey_id
        provider
        gp_guid
        error
        status
        controlled_patient_count
        survey {
          id
          type
          content
          name
        }
      }
      nextToken
    }
  }
`;
export const getSource = /* GraphQL */ `
  query GetSource($source_id: String!) {
    getSource(source_id: $source_id) {
      source_id
      type
      name
      create_at
      survey_id
      provider
      gp_guid
      error
      status
      controlled_patient_count
      survey {
        id
        type
        content
        name
      }
    }
  }
`;
export const sourceByGp = /* GraphQL */ `
  query SourceByGp(
    $gp_guid: String
    $typeName: ModelSourceByGPCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSourceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sourceByGP(
      gp_guid: $gp_guid
      typeName: $typeName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        source_id
        type
        name
        create_at
        survey_id
        provider
        gp_guid
        error
        status
        controlled_patient_count
        survey {
          id
          type
          content
          name
        }
      }
      nextToken
    }
  }
`;
export const getControlledPatient = /* GraphQL */ `
  query GetControlledPatient($patient_guid: ID!, $source_id: String!) {
    getControlledPatient(patient_guid: $patient_guid, source_id: $source_id) {
      patient_guid
      source_id
      version
      nhs_number
      gp_guid
      gp_ods_code
      gp_login
      created_at
      survey_answered
      status
      initial_status
      survey_id
      survey_type
      survey_response
      hash
      control_level
      processed_response
      patient_ods_code
      source {
        source_id
        type
        name
        create_at
        survey_id
        provider
        gp_guid
        error
        status
        controlled_patient_count
        survey {
          id
          type
          content
          name
        }
      }
      survey {
        id
        type
        content
        name
      }
      notifications {
        items {
          id
          gp_guid
          patient_guid
          source_id
          sent_on
          channel
          type
          sent_successfully
        }
        nextToken
      }
      patient {
        patient_guid
        version
        gp_guid
        doctor_guid
        given_name
        surname
        nhs_number
        email
        phone_number
        device_token
        medloop_sub_id
        communication_channel
        birthdate
        sex
        doctor {
          doctor_guid
          version
          contract_start_date
          given_name
          job_category_code
          job_category_name
          gp_guid
          surname
          title
        }
        gp {
          gp_guid
          name
          version
          ods_code
          login
          provider
          gp_contact_number
          api_key
        }
      }
      gp {
        gp_guid
        name
        version
        ods_code
        login
        provider
        gp_contact_number
        api_key
      }
    }
  }
`;
export const listControlledPatients = /* GraphQL */ `
  query ListControlledPatients(
    $patient_guid: ID
    $source_id: ModelStringKeyConditionInput
    $filter: ModelControlledPatientFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listControlledPatients(
      patient_guid: $patient_guid
      source_id: $source_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        patient_guid
        source_id
        version
        nhs_number
        gp_guid
        gp_ods_code
        gp_login
        created_at
        survey_answered
        status
        initial_status
        survey_id
        survey_type
        survey_response
        hash
        control_level
        processed_response
        patient_ods_code
        source {
          source_id
          type
          name
          create_at
          survey_id
          provider
          gp_guid
          error
          status
          controlled_patient_count
        }
        survey {
          id
          type
          content
          name
        }
        notifications {
          nextToken
        }
        patient {
          patient_guid
          version
          gp_guid
          doctor_guid
          given_name
          surname
          nhs_number
          email
          phone_number
          device_token
          medloop_sub_id
          communication_channel
          birthdate
          sex
        }
        gp {
          gp_guid
          name
          version
          ods_code
          login
          provider
          gp_contact_number
          api_key
        }
      }
      nextToken
    }
  }
`;
export const controlledPatientBySource = /* GraphQL */ `
  query ControlledPatientBySource(
    $source_id: String
    $gp_guid: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelControlledPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    controlledPatientBySource(
      source_id: $source_id
      gp_guid: $gp_guid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        patient_guid
        source_id
        version
        nhs_number
        gp_guid
        gp_ods_code
        gp_login
        created_at
        survey_answered
        status
        initial_status
        survey_id
        survey_type
        survey_response
        hash
        control_level
        processed_response
        patient_ods_code
        source {
          source_id
          type
          name
          create_at
          survey_id
          provider
          gp_guid
          error
          status
          controlled_patient_count
        }
        survey {
          id
          type
          content
          name
        }
        notifications {
          nextToken
        }
        patient {
          patient_guid
          version
          gp_guid
          doctor_guid
          given_name
          surname
          nhs_number
          email
          phone_number
          device_token
          medloop_sub_id
          communication_channel
          birthdate
          sex
        }
        gp {
          gp_guid
          name
          version
          ods_code
          login
          provider
          gp_contact_number
          api_key
        }
      }
      nextToken
    }
  }
`;
export const controlledPatientByHash = /* GraphQL */ `
  query ControlledPatientByHash(
    $hash: String
    $sortDirection: ModelSortDirection
    $filter: ModelControlledPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    controlledPatientByHash(
      hash: $hash
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        patient_guid
        source_id
        version
        nhs_number
        gp_guid
        gp_ods_code
        gp_login
        created_at
        survey_answered
        status
        initial_status
        survey_id
        survey_type
        survey_response
        hash
        control_level
        processed_response
        patient_ods_code
        source {
          source_id
          type
          name
          create_at
          survey_id
          provider
          gp_guid
          error
          status
          controlled_patient_count
        }
        survey {
          id
          type
          content
          name
        }
        notifications {
          nextToken
        }
        patient {
          patient_guid
          version
          gp_guid
          doctor_guid
          given_name
          surname
          nhs_number
          email
          phone_number
          device_token
          medloop_sub_id
          communication_channel
          birthdate
          sex
        }
        gp {
          gp_guid
          name
          version
          ods_code
          login
          provider
          gp_contact_number
          api_key
        }
      }
      nextToken
    }
  }
`;
export const controlledPatientByStatus = /* GraphQL */ `
  query ControlledPatientByStatus(
    $status: ControlledPatientStatus
    $gp_guidSource_id: ModelControlledPatientByStatusCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelControlledPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    controlledPatientByStatus(
      status: $status
      gp_guidSource_id: $gp_guidSource_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        patient_guid
        source_id
        version
        nhs_number
        gp_guid
        gp_ods_code
        gp_login
        created_at
        survey_answered
        status
        initial_status
        survey_id
        survey_type
        survey_response
        hash
        control_level
        processed_response
        patient_ods_code
        source {
          source_id
          type
          name
          create_at
          survey_id
          provider
          gp_guid
          error
          status
          controlled_patient_count
        }
        survey {
          id
          type
          content
          name
        }
        notifications {
          nextToken
        }
        patient {
          patient_guid
          version
          gp_guid
          doctor_guid
          given_name
          surname
          nhs_number
          email
          phone_number
          device_token
          medloop_sub_id
          communication_channel
          birthdate
          sex
        }
        gp {
          gp_guid
          name
          version
          ods_code
          login
          provider
          gp_contact_number
          api_key
        }
      }
      nextToken
    }
  }
`;
export const listSurveys = /* GraphQL */ `
  query ListSurveys(
    $id: ID
    $filter: ModelSurveyFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSurveys(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        type
        content
        name
      }
      nextToken
    }
  }
`;
export const getSurvey = /* GraphQL */ `
  query GetSurvey($id: ID!) {
    getSurvey(id: $id) {
      id
      type
      content
      name
    }
  }
`;
export const surveyByTypeName = /* GraphQL */ `
  query SurveyByTypeName(
    $type: SurveyType
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSurveyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    surveyByTypeName(
      type: $type
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        content
        name
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      gp_guid
      patient_guid
      source_id
      sent_on
      channel
      type
      sent_successfully
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $id: ID
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listNotifications(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        gp_guid
        patient_guid
        source_id
        sent_on
        channel
        type
        sent_successfully
      }
      nextToken
    }
  }
`;
export const notificationBySource = /* GraphQL */ `
  query NotificationBySource(
    $source_id: String
    $patient_guid: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    notificationBySource(
      source_id: $source_id
      patient_guid: $patient_guid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        gp_guid
        patient_guid
        source_id
        sent_on
        channel
        type
        sent_successfully
      }
      nextToken
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $staff_id: String
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLocations(
      staff_id: $staff_id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        staff_id
        gp_guid
        post_code
        address
        city
        name
        phone
      }
      nextToken
    }
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($staff_id: String!) {
    getLocation(staff_id: $staff_id) {
      staff_id
      gp_guid
      post_code
      address
      city
      name
      phone
    }
  }
`;
export const locationByGpAndPostCode = /* GraphQL */ `
  query LocationByGpAndPostCode(
    $gp_guid: ID
    $post_code: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    locationByGPAndPostCode(
      gp_guid: $gp_guid
      post_code: $post_code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        staff_id
        gp_guid
        post_code
        address
        city
        name
        phone
      }
      nextToken
    }
  }
`;
export const getSlots = /* GraphQL */ `
  query GetSlots($id: ID!) {
    getSlots(id: $id) {
      id
      gp_guid
      year
      month
      day
      hour
      minute
      staff_id
      duration
      patient_guid
      patient_hash
      version
      location_details
      reason
      status
      title
      patient_ods_code
      comments
      location {
        staff_id
        gp_guid
        post_code
        address
        city
        name
        phone
      }
    }
  }
`;
export const listSlotss = /* GraphQL */ `
  query ListSlotss(
    $id: ID
    $filter: ModelSlotsFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listSlotss(
      id: $id
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        gp_guid
        year
        month
        day
        hour
        minute
        staff_id
        duration
        patient_guid
        patient_hash
        version
        location_details
        reason
        status
        title
        patient_ods_code
        comments
        location {
          staff_id
          gp_guid
          post_code
          address
          city
          name
          phone
        }
      }
      nextToken
    }
  }
`;
export const slotsByStatus = /* GraphQL */ `
query SlotsByStatus(
  $gp_guid: ID
  $statusYearMonthDayHourMinuteStaff_id: ModelSlotsByStatusCompositeKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelSlotsFilterInput
  $limit: Int
  $nextToken: String
) {
  slotsByStatus(
    gp_guid: $gp_guid
    statusYearMonthDayHourMinuteStaff_id: $statusYearMonthDayHourMinuteStaff_id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      gp_guid
      year
      month
      day
      hour
      minute
      staff_id
      duration
      patient_guid
      patient_hash
      version
      location_details
      reason
      status
      title
        patient_ods_code
        comments
        location {
          staff_id
          gp_guid
          post_code
          address
          city
          name
          phone
        }
    }
    nextToken
  }
}
`;
export const slotsByStaff = /* GraphQL */ `
  query SlotsByStaff(
    $gp_guid: ID
    $staff_idStatusYearMonthDayHourMinute: ModelSlotsBySaffCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSlotsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    slotsByStaff(
      gp_guid: $gp_guid
      staff_idStatusYearMonthDayHourMinute: $staff_idStatusYearMonthDayHourMinute
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        gp_guid
        year
        month
        day
        hour
        minute
        staff_id
        duration
        patient_guid
        patient_hash
        version
        location_details
        reason
        status
        title
        patient_ods_code
        comments
        location {
          staff_id
          gp_guid
          post_code
          address
          city
          name
          phone
        }
      }
      nextToken
    }
  }
`;
export const slotsByGp = /* GraphQL */ `
  query SlotsByGp(
    $gp_guid: ID
    $yearMonthDayHourMinuteStaff_id: ModelSlotsByGPCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSlotsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    slotsByGp(
      gp_guid: $gp_guid
      yearMonthDayHourMinuteStaff_id: $yearMonthDayHourMinuteStaff_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        gp_guid
        year
        month
        day
        hour
        minute
        staff_id
        duration
        patient_guid
        patient_hash
        version
        location_details
        reason
        status
        title
        patient_ods_code
        comments
        location {
          staff_id
          gp_guid
          post_code
          address
          city
          name
          phone
        }
      }
      nextToken
    }
  }
`;
export const slotsByPatient = /* GraphQL */ `
  query SlotsByPatient(
    $patient_guid: String
    $gp_guidStatusYearMonthDayHourMinuteStaff_id: ModelSlotsByPatientCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSlotsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    slotsByPatient(
      patient_guid: $patient_guid
      gp_guidStatusYearMonthDayHourMinuteStaff_id: $gp_guidStatusYearMonthDayHourMinuteStaff_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        gp_guid
        year
        month
        day
        hour
        minute
        staff_id
        duration
        patient_guid
        patient_hash
        version
        location_details
        reason
        status
        title
        patient_ods_code
        comments
        location {
          staff_id
          gp_guid
          post_code
          address
          city
          name
          phone
        }
      }
      nextToken
    }
  }
`;
export const slotsByHash = /* GraphQL */ `
  query SlotsByHash(
    $patient_hash: String
    $gp_guidStatusYearMonthDayHourMinuteStaff_id: ModelSlotsByHashCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelSlotsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    slotsByHash(
      patient_hash: $patient_hash
      gp_guidStatusYearMonthDayHourMinuteStaff_id: $gp_guidStatusYearMonthDayHourMinuteStaff_id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        gp_guid
        year
        month
        day
        hour
        minute
        staff_id
        duration
        patient_guid
        patient_hash
        version
        location_details
        reason
        status
        title
        patient_ods_code
        comments
        location {
          staff_id
          gp_guid
          post_code
          address
          city
          name
          phone
        }
      }
      nextToken
    }
  }
`;
export const getPatient = /* GraphQL */ `
  query GetPatient($patient_guid: ID!, $version: String!, $gp_guid: ID!) {
    getPatient(
      patient_guid: $patient_guid
      version: $version
      gp_guid: $gp_guid
    ) {
      patient_guid
      version
      gp_guid
      doctor_guid
      given_name
      surname
      nhs_number
      email
      phone_number
      device_token
      medloop_sub_id
      communication_channel
      birthdate
      sex
      doctor {
        doctor_guid
        version
        contract_start_date
        given_name
        job_category_code
        job_category_name
        gp_guid
        surname
        title
        patients {
          nextToken
        }
        gp {
          gp_guid
          name
          version
          ods_code
          login
          provider
          gp_contact_number
          api_key
        }
      }
      gp {
        gp_guid
        name
        version
        ods_code
        login
        provider
        gp_contact_number
        api_key
      }
    }
  }
`;
export const listPatients = /* GraphQL */ `
  query ListPatients(
    $patient_guid: ID
    $versionGp_guid: ModelPatientPrimaryCompositeKeyConditionInput
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listPatients(
      patient_guid: $patient_guid
      versionGp_guid: $versionGp_guid
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        patient_guid
        version
        gp_guid
        doctor_guid
        given_name
        surname
        nhs_number
        email
        phone_number
        device_token
        medloop_sub_id
        communication_channel
        birthdate
        sex
        doctor {
          doctor_guid
          version
          contract_start_date
          given_name
          job_category_code
          job_category_name
          gp_guid
          surname
          title
        }
        gp {
          gp_guid
          name
          version
          ods_code
          login
          provider
          gp_contact_number
          api_key
        }
      }
      nextToken
    }
  }
`;
export const patientByNhsNumber = /* GraphQL */ `
  query PatientByNhsNumber(
    $nhs_number: String
    $version: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    patientByNhsNumber(
      nhs_number: $nhs_number
      version: $version
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        patient_guid
        version
        gp_guid
        doctor_guid
        given_name
        surname
        nhs_number
        email
        phone_number
        device_token
        medloop_sub_id
        communication_channel
        birthdate
        sex
        doctor {
          doctor_guid
          version
          contract_start_date
          given_name
          job_category_code
          job_category_name
          gp_guid
          surname
          title
        }
        gp {
          gp_guid
          name
          version
          ods_code
          login
          provider
          gp_contact_number
          api_key
        }
      }
      nextToken
    }
  }
`;
export const patientByGp = /* GraphQL */ `
  query PatientByGp(
    $gp_guid: ID
    $version: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    patientByGp(
      gp_guid: $gp_guid
      version: $version
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        patient_guid
        version
        gp_guid
        doctor_guid
        given_name
        surname
        nhs_number
        email
        phone_number
        device_token
        medloop_sub_id
        communication_channel
        birthdate
        sex
        doctor {
          doctor_guid
          version
          contract_start_date
          given_name
          job_category_code
          job_category_name
          gp_guid
          surname
          title
        }
        gp {
          gp_guid
          name
          version
          ods_code
          login
          provider
          gp_contact_number
          api_key
        }
      }
      nextToken
    }
  }
`;
export const patientByDoctor = /* GraphQL */ `
  query PatientByDoctor(
    $doctor_guid: ID
    $version: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelPatientFilterInput
    $limit: Int
    $nextToken: String
  ) {
    patientByDoctor(
      doctor_guid: $doctor_guid
      version: $version
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        patient_guid
        version
        gp_guid
        doctor_guid
        given_name
        surname
        nhs_number
        email
        phone_number
        device_token
        medloop_sub_id
        communication_channel
        birthdate
        sex
        doctor {
          doctor_guid
          version
          contract_start_date
          given_name
          job_category_code
          job_category_name
          gp_guid
          surname
          title
        }
        gp {
          gp_guid
          name
          version
          ods_code
          login
          provider
          gp_contact_number
          api_key
        }
      }
      nextToken
    }
  }
`;
export const listDoctors = /* GraphQL */ `
  query ListDoctors(
    $doctor_guid: ID
    $version: ModelStringKeyConditionInput
    $filter: ModelDoctorFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listDoctors(
      doctor_guid: $doctor_guid
      version: $version
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        doctor_guid
        version
        contract_start_date
        given_name
        job_category_code
        job_category_name
        gp_guid
        surname
        title
        patients {
          nextToken
        }
        gp {
          gp_guid
          name
          version
          ods_code
          login
          provider
          gp_contact_number
          api_key
        }
      }
      nextToken
    }
  }
`;
export const getDoctor = /* GraphQL */ `
  query GetDoctor($doctor_guid: ID!, $version: String!) {
    getDoctor(doctor_guid: $doctor_guid, version: $version) {
      doctor_guid
      version
      contract_start_date
      given_name
      job_category_code
      job_category_name
      gp_guid
      surname
      title
      patients {
        items {
          patient_guid
          version
          gp_guid
          doctor_guid
          given_name
          surname
          nhs_number
          email
          phone_number
          device_token
          medloop_sub_id
          communication_channel
          birthdate
          sex
        }
        nextToken
      }
      gp {
        gp_guid
        name
        version
        ods_code
        login
        provider
        gp_contact_number
        api_key
      }
    }
  }
`;
export const doctorByJob = /* GraphQL */ `
  query DoctorByJob(
    $job_category_name: String
    $version: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDoctorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    doctorByJob(
      job_category_name: $job_category_name
      version: $version
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        doctor_guid
        version
        contract_start_date
        given_name
        job_category_code
        job_category_name
        gp_guid
        surname
        title
        patients {
          nextToken
        }
        gp {
          gp_guid
          name
          version
          ods_code
          login
          provider
          gp_contact_number
          api_key
        }
      }
      nextToken
    }
  }
`;
export const doctorByGp = /* GraphQL */ `
  query DoctorByGp(
    $gp_guid: ID
    $version: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDoctorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    doctorByGp(
      gp_guid: $gp_guid
      version: $version
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        doctor_guid
        version
        contract_start_date
        given_name
        job_category_code
        job_category_name
        gp_guid
        surname
        title
        patients {
          nextToken
        }
        gp {
          gp_guid
          name
          version
          ods_code
          login
          provider
          gp_contact_number
          api_key
        }
      }
      nextToken
    }
  }
`;
export const listGPs = /* GraphQL */ `
  query ListGPs(
    $gp_guid: ID
    $version: ModelStringKeyConditionInput
    $filter: ModelGPFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGPs(
      gp_guid: $gp_guid
      version: $version
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        gp_guid
        name
        version
        ods_code
        login
        provider
        gp_contact_number
        api_key
      }
      nextToken
    }
  }
`;
export const getGp = /* GraphQL */ `
  query GetGp($gp_guid: ID!, $version: String!) {
    getGP(gp_guid: $gp_guid, version: $version) {
      gp_guid
      name
      version
      ods_code
      login
      provider
      gp_contact_number
      api_key
    }
  }
`;
export const gpByOdsCode = /* GraphQL */ `
  query GpByOdsCode(
    $ods_code: String
    $versionGp_guid: ModelGPByOdsCodeCompositeKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGPFilterInput
    $limit: Int
    $nextToken: String
  ) {
    gpByOdsCode(
      ods_code: $ods_code
      versionGp_guid: $versionGp_guid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        gp_guid
        name
        version
        ods_code
        login
        provider
        gp_contact_number
        api_key
      }
      nextToken
    }
  }
`;
export const gpByApiKey = /* GraphQL */ `
  query GpByApiKey(
    $api_key: String
    $sortDirection: ModelSortDirection
    $filter: ModelGPFilterInput
    $limit: Int
    $nextToken: String
  ) {
    gpByApiKey(
      api_key: $api_key
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        gp_guid
        name
        version
        ods_code
        login
        provider
        gp_contact_number
        api_key
      }
      nextToken
    }
  }
`;
