import DateFnsUtils from "@date-io/date-fns";
import {Box, Button, CircularProgress, Grid, MenuItem, Select, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import {API, graphqlOperation} from "aws-amplify";
import * as moment from "moment";
import React, {useEffect, useState} from "react";
import "survey-react/survey.css";
import {useSnackbar} from "notistack";

import {adminBookAppointment} from "../graphql/mutations";
import {getDateFormatted} from "../utils/utils.js";

const useStyles = makeStyles(theme => ({
	bookingWrapper: {
		display: "flex",
	},
	bookingRow: {
		display: "flex",
		justifyContent: "space-between",
		padding: theme.spacing(1.5, 0),
	},
	slots: {
		flex: 1,
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
	},
	appoinmentHeader: {
		display: "flex",
		flexDirection: "column",
		marginTop: theme.spacing(2),
	},
	appoinmentinfo: {
		paddingBottom: theme.spacing(1),
	},
	dayRed: {
		"& .MuiPickersDay-day": {
			backgroundColor: "#E08A8C",
		},
	},
	dayGreen: {
		"& .MuiPickersDay-day": {
			backgroundColor: "#AEE8E4",
		},
	},
	dayYellow: {
		"& .MuiPickersDay-day": {
			backgroundColor: "#FFCD80",
		},
	},
	daySelected: {
		"& .MuiPickersDay-day": {
			backgroundColor: "#1A9A92",
		},
	},
}));

export default function AppointmentBooking(props) {
	const classes = useStyles();
	const {enqueueSnackbar} = useSnackbar();

	let date = new Date();
	const [slotDate, setSlotDate] = useState(date);
	const [secondSlotDate, setSecondSlotDate] = useState(date);
	const [loadingSlots, setLoadingSlots] = useState(false);
	const [slots, setSlots] = useState([]);

	const [selectedSlot, setSelectedSlot] = useState(null);
	const {mobile, dob, hash, onBookingComplete, setIsLoading, locations, isAdmin, showSuccessToast, showErrorToast} = props;
	const [location, setLocation] = useState(locations[0]);
	const [availabilityMap, setAvailabilityMap] = useState({});
	const [selectedMonth, setSelectedMonth] = useState(date);

	useEffect(() => {
		const date = getDateFormatted(selectedMonth);
		if (!location || !location.staff_id) return;
		const getAvailabilityMap = async () => {
			try {
				setLoadingSlots(true);
				const requestParams = {
					queryStringParameters: {
						date: date,
						location: location.staff_id,
					},
				};
				let response = await API.get("appointments", `/slots/availability/${hash}`, requestParams);
				console.log("map", response);
				setAvailabilityMap(response.slotsAvailabilityMap);
			} catch (error) {
				console.log(error);
			} finally {
				setLoadingSlots(false);
			}
		};
		getAvailabilityMap();
	}, [selectedMonth, location, hash]);

	useEffect(() => {
		if (!slotDate) return;
		const date = getDateFormatted(slotDate);
		if (!location || !location.staff_id) return;
		const getSlots = async () => {
			try {
				setLoadingSlots(true);
				const requestParams = {
					queryStringParameters: {
						date: date,
						location: location.staff_id,
					},
				};
				let response = await API.get("appointments", `/slots/${hash}`, requestParams);
				setSlots(response.slots);
			} catch (error) {
				console.log(error);
			} finally {
				setLoadingSlots(false);
			}
		};
		getSlots();
	}, [slotDate, location, hash]);

	const bookAppointment = async () => {
		setIsLoading(true);
		const requestParams = {
			body: {
				birthdate: dob,
				slotId: selectedSlot.id,
			},
		};
		try {
			if (isAdmin) {
				console.log("In appointmentBooking.. isAdmin ", isAdmin);
				const requestParams = {
					patientBirthdate: dob,
					slotId: selectedSlot.id,
					patientHash: hash,
				};
				await API.graphql(
					graphqlOperation(adminBookAppointment, {
						paramsStringified: JSON.stringify(requestParams),
					})
				);
			} else {
				const requestParams = {
					body: {
						birthdate: dob,
						slotId: selectedSlot.id,
					},
				};
				await API.post("appointments", `/slots/${hash}`, requestParams);
			}
			if (showSuccessToast) {
				enqueueSnackbar("Successfully booked an appointment", {variant: "success"});
			}
			await onBookingComplete(selectedSlot, location);
		} catch (error) {
			console.log(error);
			if (showErrorToast) {
				let errorText = "Failed to book an appointment";
				if(error.response && error.response.data && error.response.data.error){
					errorText = error.response.data.error;
				}
				enqueueSnackbar(errorText, {variant: "error"});
			}
		} finally {
			setIsLoading(false);
		}
		console.log(requestParams);
	};

	const validationCheck = date => {
		if (moment(secondSlotDate).diff(moment(date), "days") < 28) {
			date.setDate(date.getDate() + 28);
			setSecondSlotDate(date);
		}
	};

	const handleSlotClick = (slot, type) => {
		console.log("Slot: " + JSON.stringify(slot), slot);
		if (type === "first") {
			setSelectedSlot(slot);
			// if (selectedSecondSlot && selectedSecondSlot.id === slot.id) {
			// 	setSelectedSecondSlot(null);
			// }
			let date = new Date(slotDate);
			// setSecondSlotsFetched(true);
			validationCheck(date);
		} else if (type === "second") {
			//setSelectedSecondSlot(slot);
		}
	};

	const handleSlotDateChange = async (value, type) => {
		setSelectedSlot(null);
		setSlotDate(value);
		validationCheck(date);
	};

	const handleChangeLocation = (event, type) => {
		if (type === "first") {
			setLocation(event.target.value);
			setSlots([]);
		} else if (type === "second") {
		}
	};

	//(day: DateIOType), (selectedDate: DateIOType), (dayInCurrentMonth: boolean), (dayComponent: Element);

	const getDateKey = date => {
		let year = date.getFullYear();
		let month = ("0" + (date.getMonth() + 1)).slice(-2);
		let day = ("0" + date.getDate()).slice(-2);
		return `${year}#${month}#${day}`;
	};

	const disableDates = day => {
		let key = getDateKey(day);
		let numAppointments = availabilityMap[key];
		if (!numAppointments || numAppointments[key] === 0) return true;
		return false;
	};

	const applyDayCss = (day, selectedDate, dayInCurrentMonth, dayComponent) => {
		let key = getDateKey(day);
		let selectedKey = getDateKey(selectedDate);
		let d = ("0" + day.getDate()).slice(-2);

		let numAppointments = availabilityMap[key];
		//availabilityMap;
		if (day.getTime() === selectedDate.getTime()) {
			return <div className={classes.daySelected}>{dayComponent}</div>;
		}
		if (numAppointments > 10) {
			return <div className={classes.dayGreen}>{dayComponent}</div>;
		} else if (numAppointments >= 5) {
			return <div className={classes.dayYellow}>{dayComponent}</div>;
		} else if (numAppointments > 0) {
			return <div className={classes.dayRed}>{dayComponent}</div>;
		}
		return dayComponent;
	};

	//TODO we can return a promise where it's resolved only when the available slots are loaded.
	//onMonthChange
	//(date: DateIOType) => void | Promise<void>
	//Callback firing on month change. Return promise to render spinner till it will not be resolved
	const changeMonth = date => {
		setSlotDate(null);
		setSlots([]);
		let now = new Date();
		if (date.getTime() < now.getTime()) {
			setSelectedMonth(now);
		}
		setSelectedMonth(date);
	};

	const bookAppointmentPanel = () => {
		return (
			<Grid spacing={2} container justify="center" className={classes.bookingWrapper}>
				<Grid item xs={12} md={12}>
					<Select
						fullWidth
						margin="dense"
						variant="outlined"
						IconComponent={() => <LocationOnIcon />}
						labelId="demo-simple-select-label"
						id="demo-simple-select"
						value={location}
						onChange={e => handleChangeLocation(e, "first")}
					>
						{locations.map(location => (
							<MenuItem key={location.staff_id} value={location}>
								{location.name}
							</MenuItem>
						))}
					</Select>
				</Grid>
				<Grid item xs={12} md={12}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<KeyboardDatePicker
							// disableToolbar
							fullWidth
							allowKeyboardControl={true}
							inputVariant="outlined"
							format="MM-dd-yyyy"
							margin="dense"
							id="date-picker-inline"
							value={slotDate}
							onChange={e => handleSlotDateChange(e, "first")}
							KeyboardButtonProps={{
								"aria-label": "change date",
							}}
							onMonthChange={changeMonth}
							renderDay={applyDayCss}
							shouldDisableDate={disableDates}
							minDate={Date.now()}
							autoOk
						/>
					</MuiPickersUtilsProvider>
				</Grid>
				<Grid item xs={12} md={12}>
					<Grid container spacing={2}>
						{loadingSlots ? (
							<Box style={{width: "100%"}} display="flex" justifyContent="center">
								<CircularProgress color="inherit" />
							</Box>
						) : slots.length > 0 ? (
							slots.map(slot => (
								<Grid item xs={6} md={4}>
									<Button
										fullWidth
										disabled={selectedSlot?.id === slot.id}
										variant="contained"
										size="medium"
										onClick={() => handleSlotClick(slot, "first")}
									>{`${slot.hour}:${slot.minute}`}</Button>
								</Grid>
							))
						) : (
							<Box style={{width: "100%"}} display="flex" justifyContent="center">
								<Typography align="center" component="div">
									<Box fontSize={20} color="textPrimary">
										{slotDate ? `No Slots Available on: ${slotDate.toLocaleDateString()}` : ""}
									</Box>
								</Typography>
							</Box>
						)}
					</Grid>
				</Grid>
				{selectedSlot ? (
					<Grid item xs={12} md={12}>
						<Button fullWidth onClick={bookAppointment} variant="contained" color="primary">
							Book Covid Appointment
						</Button>
					</Grid>
				) : null}
			</Grid>
		);
	};

	return bookAppointmentPanel();
}
