const bulkInvitePatients = /* GraphQL */ `
	mutation BulkInvitePatients($input: String!) {
		bulkInvitePatients(paramsStringified: $input)
	}
`;

const sendCustomSMS = /* GraphQL */ `
	mutation SendCustomSMS($input: String!) {
		sendCustomSMS(paramsStringified: $input)
	}
`;

const adminCancelAppointment = /* GraphQL */ `
	mutation AdminCancelAppointment($input: String!) {
		adminCancelAppointment(paramsStringified: $input)
	}
`;

const adminBookAppointment = /* GraphQL */ `
	mutation AdminBookAppointment($input: String!) {
		adminBookAppointment(paramsStringified: $input)
	}
`;

const saveAndFileToEmis = /* GraphQL */ `
	mutation SaveAndFileToEmis($input: String!) {
		saveAndFileToEmis(paramsStringified: $input)
	}
`;

module.exports = {
	bulkInvitePatients,
	saveAndFileToEmis,
	sendCustomSMS,
	adminCancelAppointment,
	adminBookAppointment,
};
