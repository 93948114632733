import {Box, Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import React from "react";
import "../App.css";
import AmplifyLogin from "../components/AmplifyLogin";
import LoginBG from "../login_background.jpg";
import medloopWhiteLogo from "../medloop-logo-white-copy.svg";

const useStyle = makeStyles(theme => ({
	root: {
		flex: "1",
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
		justifyContent: "center",
		backgroundImage: `url(${LoginBG})`,
		backgroundPosition: "center",
		backgroundRepeat: "no-repeat",
		backgroundSize: "cover",
	},
	subTitle: {
		letterSpacing: "0.75px",
		fontSize: "18px",
		fontWeight: 300,
		textAlign: "center",
		color: "white",
		marginBottom: "3%",
	},
}));

export default function LoginPage(props) {
	const classes = useStyle();
	return (
		<React.Fragment>
			<div className={classes.root}>
				<Box>
					<img alt="medloop-white-logo" height={59} src={medloopWhiteLogo}></img>
				</Box>
				<Typography className={classes.subTitle}>The Future of Connected Healthcare</Typography>
				<AmplifyLogin></AmplifyLogin>
			</div>
		</React.Fragment>
	);
}
