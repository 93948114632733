import React from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";

export default function ConfirmDialog(props) {
	const {title, content, open, setOpen, onConfirm} = props;

	return (
		<Dialog open={open} onClose={() => setOpen(false)} aria-labelledby="confirm-dialog">
			<DialogTitle id={"confirm-title" + title}>{title}</DialogTitle>
			<DialogContent>
				<DialogContentText id="alert-dialog-description">{content}</DialogContentText>
			</DialogContent>
			<DialogActions>
				<Button onClick={() => setOpen(false)} color="secondary">
					Cancel
				</Button>
				<Button
					onClick={() => {
						setOpen(false);
						onConfirm();
					}}
					color="secondary"
					autoFocus
				>
					Confirm
				</Button>
			</DialogActions>
		</Dialog>
	);
}
