import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import {API, graphqlOperation, Auth} from "aws-amplify";

import {createSlots} from "../graphql/mutations";

import {useHistory} from "react-router-dom";

import Typography from "@material-ui/core/Typography";
import {TextField} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	margin: {
		margin: theme.spacing(1),
	},
	withoutLabel: {
		marginTop: theme.spacing(3),
	},
	textField: {
		width: "25ch",
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	button: {
		margin: theme.spacing(1),
	},
}));

export default function InputAdornments() {
	const classes = useStyles();
	const history = useHistory();
	const [values, setValues] = React.useState({
		year: "",
		month: "",
		day: "",
		hour: "",
		minute: "",
		staff_id: "",
		duration: "",
		version: "V_0",
		location_details: "",
		reason: "",
		status: "AVAILABLE",
	});

	const handleChange = prop => event => {
		setValues({...values, [prop]: event.target.value});
	};

	const submitSlot = async () => {
		try {
			const user = await Auth.currentAuthenticatedUser();
			values.gp_guid = user.attributes["custom:gp_guid"];
			await API.graphql(graphqlOperation(createSlots, {input: values}));
			//iterateo ver dates
			//call multiple times the create
			history.push("/");
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<div className={classes.root}>
			<Typography variant="h4" component="h5" gutterBottom>
				New GP
			</Typography>
			<Grid container justify="center" spacing={1}>
				<Grid item md={12}>
					<TextField label="Year" value={values.year} onChange={handleChange("year")} fullWidth variant="outlined" />
					<TextField label="month" value={values.month} onChange={handleChange("month")} fullWidth variant="outlined" />
					<TextField label="day" value={values.day} onChange={handleChange("day")} fullWidth variant="outlined" />
					<TextField label="hour" value={values.hour} onChange={handleChange("hour")} fullWidth variant="outlined" />
					<TextField label="minute" value={values.minute} onChange={handleChange("minute")} fullWidth variant="outlined" />
					<TextField label="staff_id" value={values.staff_id} onChange={handleChange("staff_id")} fullWidth variant="outlined" />
					<TextField label="duration" value={values.duration} onChange={handleChange("duration")} fullWidth variant="outlined" />
					<TextField
						label="location_details"
						value={values.location_details}
						onChange={handleChange("location_details")}
						fullWidth
						variant="outlined"
					/>
					<TextField label="reason" value={values.reason} onChange={handleChange("reason")} fullWidth variant="outlined" />
				</Grid>
			</Grid>
			<Grid container alignItems="flex-start" justify="flex-end" direction="row">
				<Button variant="contained" color="secondary" onClick={submitSlot} className={classes.button} startIcon={<CloudUploadIcon />}>
					Create Slot
				</Button>
			</Grid>
		</div>
	);
}
