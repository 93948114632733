import React, {useEffect, useState, useCallback, useMemo, Fragment} from "react";
import moment from "moment";
import {API, Auth, graphqlOperation} from "aws-amplify";
import Moment from "moment";
import {useSnackbar} from "notistack";

import {makeStyles} from "@material-ui/core/styles";
import clsx from "clsx";

import {
	Box,
	Button,
	CircularProgress,
	Drawer,
	FormControl,
	IconButton,
	MenuItem,
	Select,
	Tab,
	TextField,
	Tabs,
	Paper,
	Typography,
	LinearProgress,
	Tooltip,
	Grid,
	Hidden,
	Divider,
	Accordion,
	AccordionSummary,
	AccordionDetails,
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import CloseIcon from "@material-ui/icons/Close";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import LaunchIcon from "@material-ui/icons/Launch";
import SendIcon from "@material-ui/icons/Send";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import ConfirmDialog from "../components/ConfirmDialog";

import PropTypes from "prop-types";

import {sourceByGp, slotsByPatient, locationByGpAndPostCode} from "../graphql/queries";
import {bulkInvitePatients} from "../graphql/customMutations";

import EnhancedTable from "../components/enhancedTable/fullPatientList";
import {controlledPatientBySource, controlledPatientByStatus, updateControlledPatientStatus} from "../services/controlledPatient";
import AppointmentBooking from "../components/AppointmentBooking.js";
import {calculateBMIFromProcessedResponse, getStatus, getActColor} from "../utils/utils";

import NHSCell from "../components/nhsCell.js";

const {
	ACT_SCORE,
	AWARENESS,
	CONSULTATION,
	EXACERBATION,
	ACTIVITIES,
	SYMPTOMS,
	SMOKING,
	BREATH,
	RELIEVER,
	BMI,
	BMI_METER,
	PEAK_FLOW,
	DATE_FORMAT,
	CONTROLLED_PATIENT_STATUS,
	DATE_TIME_FORMAT,
	NOTIFICATION_ALLOWED_ON_PATIENT_STATUS,
	NOTIFICATION_ALLOWED_COUNT,
	COVID_SURVEY_TYPE,
	ASTHMA_SURVEY_TYPE,
	CONTROLLED_PATIENT_STATUS_TRANSLATOR,
	controlLevelText,
	HOSPITAL_ADMISSION,
} = require("../utils/constants");

const drawerWidth = 400;

const useStyles = makeStyles(theme => ({
	root: {
		width: "100%",
		backgroundColor: theme.palette.background.paper,
	},
	patientLink: {
		textDecoration: "none",
		color: "inherit",
		fontWeight: "500",
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
	},
	inline: {
		display: "inline",
	},
	avatar: {
		backgroundColor: theme.palette.primary.main, //
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
	},
	drawerHeader: {
		display: "flex",
		alignItems: "center",
		marginTop: theme.spacing(10),
		marginLeft: theme.spacing(2.5),
		marginRight: theme.spacing(2.5),
		marginBottom: theme.spacing(1),
		justifyContent: "space-between",
	},
	headerText: {
		fontSize: 20,
		fontWeight: "500",
	},
	drawerClose: {
		color: "#0fc3c5",
	},
	drawerContent: {
		padding: theme.spacing(3) - 4,
		display: "flex",
		flexDirection: "column",
		overflow: "auto",
		flex: 1,
	},
	surveyHeading: {
		display: "flex",
		alignItems: "center",
		paddingLeft: theme.spacing(2.5),
	},
	surveyMain: {
		fontSize: 20,
		marginRight: theme.spacing(1),
		fontWeight: "500",
		color: theme.palette.common.black,
	},
	sectionHeader: {
		fontSize: 12,
		color: "rgba(0, 0, 0, 0.87)",
	},
	surveySub: {
		fontWeight: "500",
		fontSize: 20,
		color: "rgba(0, 0, 0, 0.54)",
	},
	leftInput: {
		width: "100%",
		marginRight: theme.spacing(2),
	},
	disabledInput: {
		color: theme.palette.text.secondary, //
	},
	actScore: {
		width: theme.spacing(3),
		height: theme.spacing(3),
		fontSize: 14,
	},
	medicationList: {
		marginRight: theme.spacing(2),
		fontSize: 14,
		color: "rgba(0, 0, 0, 0.87)",
		letterSpacing: "0.15px",
		marginBottom: theme.spacing(1),
	},
	drawerFooter: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		// boxShadow: "0px -4px 12px #0000001a",
		paddingBottom: theme.spacing(1),
	},
	saveButton: {
		height: "42px",
		padding: "6px 20px",
		minWidth: "128px",
		fontSize: "15px",
		backgroundColor: "#0fc3c5",
		color: "white",
		borderRadius: "4px",
		margin: "16px 0px",
		textTransform: "none",
		"&:hover": {
			backgroundColor: "#0fc3c5",
		},
		"&:disabled": {
			backgroundColor: "rgba(0, 0, 0, 0.26)",
			color: "white",
		},
	},
	link: {
		color: "#0fc3c5",
		textDecoration: "none",
		textTransform: "none",
	},
	pageHeader: {
		fontWeight: "bold",
		paddingBottom: theme.spacing(0.5),
	},
	tab: {
		minWidth: "auto",
		width: "fit-content",
		padding: "0",
		marginRight: theme.spacing(2),
		marginLeft: theme.spacing(2),
	},
	sourceSelect: {
		fontSize: "14px",
	},
	sourceMenu: {
		padding: `${theme.spacing(1)}px ${theme.spacing(2)}px`,
	},
	paper: {
		transition: theme.transitions.create(["margin", "width"], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	paperShift: {
		width: `calc(100% - ${drawerWidth - 68}px)`,
		minWidth: theme.spacing(92),
		transition: theme.transitions.create(["margin", "width"], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginRight: drawerWidth,
	},
	headingAcc: {
		display: "block",
		fontSize: theme.typography.pxToRem(14),
		fontWeight: theme.typography.fontWeightRegular,
	},
	secondaryAccHeading: {
		display: "block",
		fontSize: theme.typography.pxToRem(12),
		color: theme.palette.text.primary, //
	},
	accMoreInfo: {
		fontSize: theme.typography.pxToRem(12),
		color: theme.palette.text.primary, //
	},
	tabBar: {
		display: "flex",
		justifyContent: "space-between",
	},
	bulkNotify: {
		marginRight: theme.spacing(2),
		height: theme.spacing(5),
		minWidth: theme.spacing(14),
	},
	paper: {
		width: "100%",
	},
	decline: {
		marginTop: theme.spacing(4),
	},
	cirular: {
		padding: "10px 15px",
		borderRadius: 40,
	},
	reviewStatus: {
		background: "#a3e1d2",
	},
	toInviteStatus: {
		background: "#c7c2f9",
	},
	invitedStatus: {
		background: "#6253d8",
	},
	doneStatus: {
		background: "#1c9e84",
	},
	actAndMedicationsContainer: {
		overflow: "auto",
	},
	below20Act: {
		background: "#e08a8c",
	},
	below24Act: {
		background: "#ffcd80",
	},
	above25Act: {
		background: "#a3e1d2",
	},
	controlLevelDiv: {
		[theme.breakpoints.up("md")]: {},
	},
	tabPanel: {
		width: "100%",
	},
	tabPanel2: {
		[theme.breakpoints.up("md")]: {
			width: "calc(100% - 390px)",
		},
		width: "100%",
	},
	actLabel: {
		borderRadius: 40,
		padding: 4,
		marginRight: 6,
		color: "rgba(2,9,39, 0.64)",
	},
	textLabel: {
		width: "100%",
		textOverflow: "ellipsis",
		color: "rgba(2,9,39, 0.64)",
	},
	controltextLablel: {
		width: "150%",
		textOverflow: "ellipsis",
		color: "rgba(2,9,39, 0.64)",
	},
}));

function TabPanel(props) {
	const {children, value, index, ...other} = props;

	return (
		<div role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
			{value === index && <Box p={2}>{children}</Box>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

const SURVEY_URL = `${window.location.origin}/#/survey`;

export default function ControlledPatientsPage(props) {
	const classes = useStyles();
	const {enqueueSnackbar} = useSnackbar();

	const [user, setUser] = useState(null);

	const [selectedPatient, setSelectedPatient] = useState(null);

	const [tabValue, setTabValue] = useState(0);
	const [loading, setLoading] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const [changeData, setChangeData] = useState(false);
	const [loadingMedications, setLoadingMedications] = useState(false);
	const [pageSize, setPageSize] = useState(30);

	const [selectedPatients, setSelectedPatients] = useState([]);
	const [openNotifyDialog, setOpenNotifyDialog] = useState(false);
	const [refresh, setRefresh] = useState(false);

	const [clientWidth, setClientWidth] = useState(document.body.clientWidth);

	const [refreshTable, setRefreshTable] = useState(0);

	useEffect(() => {
		window.addEventListener("resize", setClientWidthFn);

		return () => {
			window.removeEventListener("resize", setClientWidthFn);
		};
	}, []);

	const setClientWidthFn = () => {
		setClientWidth(document.body.clientWidth);
	};

	const handleTabChange = (event, newValue) => {
		setTabValue(newValue);
	};

	/**
	 *
	 * @param {[]} patients - controlled patient received from emis
	 * @returns {[]} formattedPatients
	 */
	const getFormattedPatients = patients => {
		console.table(patients);
		return patients.map(controlledPatient => {
			const {patient, survey_response, gp_ods_code, nhs_number, gp_login, patient_guid, patient_ods_code} = controlledPatient;
			if (!patient) console.log(controlledPatient);
			let vaccinationRequested = false;
			if (survey_response) {
				const {receive_vaccination_1, receive_vaccination_2, receive_vaccination_3} = survey_response;
				vaccinationRequested = receive_vaccination_1 === "YES" || receive_vaccination_2 === "YES" || receive_vaccination_3 === "YES";
			}
			return {
				id: patient_guid,
				patName: patient ? `${patient.surname}, ${patient.given_name}` : "",
				status: controlledPatient.status,
				actScore: survey_response
					? survey_response.act_1 + survey_response.act_2 + survey_response.act_3 + survey_response.act_4 + survey_response.act_5
					: "",
				vaccine: survey_response ? (vaccinationRequested ? "Yes" : "No") : "",
				hospitalAdmissions: survey_response?.hospital_admission ? survey_response.hospital_admission : "",
				smokingStatus: survey_response ? survey_response.smoking_status : "",
				answeredOn: controlledPatient.survey_answered,
				gp_ods_code,
				nhs_number: nhs_number ? nhs_number : controlledPatient.patient.nhs_number,
				surname: patient ? patient.surname : "",
				given_name: patient ? patient.given_name : "",
				gp_login,
				patient_ods_code,
				hash: controlledPatient.hash,
				control_level: controlledPatient.control_level,
				processed_response: controlledPatient.processed_response,
				survey: controlledPatient.survey,
				phone: patient.phone_number,
				dob: patient.birthdate,
				source: `${controlledPatient.survey_type}-${controlledPatient.source.name}`,
				original_entry: controlledPatient,
				numberOfInvites: controlledPatient.numberOfInvites,
				lastNotificationDate: controlledPatient.lastNotificationDate,
			};
		});
	};

	const queryControlledPatientByStatus = useCallback(
		async ({status, pageSize, currentToken}) => {
			console.log("get");
			if (!user) {
				return {
					data: [],
					nextToken: null,
				};
			}
			try {
				const result = await API.graphql(
					graphqlOperation(controlledPatientByStatus, {
						status,
						gp_guidSource_id: {beginsWith: {gp_guid: user.attributes["custom:gp_guid"]}},
						limit: pageSize,
						nextToken: currentToken,
						notificationFilter: {
							type: {
								eq: "INVITATION",
							},
						},
					})
				);
				console.table(result);
				const parsedList = result.data.controlledPatientByStatus.items
					//.filter(entry => entry.survey_type === "ASTHMA")
					.map(entry => {
						let sortableDates = entry.notifications.items.map(m => moment(m.sent_on));
						entry.numberOfInvites = entry.notifications.items.length;
						entry.lastNotificationDate = moment.max(sortableDates);
						entry.survey_response = JSON.parse(entry.survey_response);
						entry.processed_response = JSON.parse(entry.processed_response);
						return entry;
					});
				const nextToken = result.data.controlledPatientByStatus.nextToken;
				return {
					data: getFormattedPatients(parsedList),
					nextToken,
				};
			} catch (err) {
				console.log(err);
				enqueueSnackbar(
					"There was an error while fetching the patient list. Please try again, Please try again, if the problem persists, contanct support@medloop.co",
					{variant: "error"}
				);
				return {
					data: [],
					nextToken: null,
				};
			}
		},
		[enqueueSnackbar, user]
	);

	const getPatientsForReview = useCallback(
		async ({pageSize, currentToken}) => {
			let refresh = refreshTable;
			return await queryControlledPatientByStatus({pageSize, currentToken, status: CONTROLLED_PATIENT_STATUS.TO_REVIEW});
		},
		[queryControlledPatientByStatus, refreshTable]
	);

	useEffect(() => {
		if (user) return;
		const getUser = async () => {
			try {
				setIsLoading(true);
				const _user = await Auth.currentAuthenticatedUser();
				setUser(_user);
			} catch (error) {
				console.log(error);
				enqueueSnackbar("Error occurred. Please try again", {variant: "error"});
			} finally {
				setIsLoading(false);
			}
		};
		getUser();
	}, [enqueueSnackbar, user]);

	useEffect(() => {
		if (!selectedPatient) return;

		const loadMedications = async () => {
			try {
				console.log(selectedPatient);
				setLoadingMedications(true);
				const params = {
					headers: {
						"m-emis-user": selectedPatient.gp_login,
					},
					queryStringParameters: {
						//condition: "ASTHMA",
						status: "CURRENT",
					},
				};
				selectedPatient.active_medications = await API.get(
					"pimi",
					`/pimi/${selectedPatient.gp_ods_code}/patients/${selectedPatient.nhs_number.replace(/\s/g, "")}/medications`,
					params
				);
			} catch (error) {
				enqueueSnackbar("Error fetching medications from emis, please try again later.", {variant: "error"});
			} finally {
				setLoadingMedications(false);
			}
		};
		loadMedications();
	}, [enqueueSnackbar, selectedPatient]);

	const saveToEmis = async () => {
		console.log("saving..");
		try {
			setLoading(true);
			const events = {
				events: Object.values(selectedPatient.processed_response),
			};
			console.log(events);
			const params = {
				headers: {
					"m-emis-user": user.attributes["custom:emis_login"],
				},
				body: events,
			};
			let response = await API.post(
				"pimi",
				`/pimi/${selectedPatient.gp_ods_code}/patients/${selectedPatient.nhs_number.replace(/\s/g, "")}/consultation`,
				params
			);
			console.log(response);

			const {patient_guid, source_id, gp_guid} = selectedPatient.original_entry;
			await API.graphql(
				graphqlOperation(updateControlledPatientStatus, {
					input: {
						source_id,
						patient_guid,
						gp_guid,
						status: "PROCESSED",
					},
				})
			);
			selectedPatient.original_entry.status = "PROCESSED";
			selectedPatient.status = "PROCESSED";
			loadSelectedPatient(selectedPatient);
			enqueueSnackbar("Patient Sucessfully saved in Emis", {variant: "success"});
			setSelectedPatient(null);
		} catch (error) {
			enqueueSnackbar("Error saving in emis, please try again later.", {variant: "error"});
		} finally {
			setRefresh(!refresh);
			setLoading(false);
			//should refactor this and add it in subscription , for now using timeout
			setTimeout(() => {
				setRefreshTable(Math.random());
			}, 1200);
		}
	};

	const loadSelectedPatient = useCallback(async patient => {
		setSelectedPatient(patient);
	}, []);

	const getSelectedPatientsCount = () => {
		if (selectedPatients) {
			return selectedPatients.length;
		}
		return "0";
	};

	const columnsConfig = {
		review: useMemo(() => {
			return [
				{
					Header: "Patient Name",
					accessor: "patName",
					numeric: false,
					disablePadding: false,
				},
				// {
				// 	Header: "Surgery Code",
				// 	accessor: "patient_ods_code",
				// 	numeric: false,
				// 	disablePadding: false,
				// 	size: "small",
				// 	Cell: ({row}) => {
				// 		return row.original.patient_ods_code ? row.original.patient_ods_code : row.original.gp_ods_code ? row.original.gp_ods_code : "";
				// 	},
				// },
				{
					Header: "Status",
					accessor: "status",
					numeric: false,
					disablePadding: false,
					Cell: ({row}) => {
						return (
							<span className={clsx(classes[getStatus(row)], classes.cirular)}>
								{row.original.status
									? CONTROLLED_PATIENT_STATUS_TRANSLATOR[row.original.status]
										? CONTROLLED_PATIENT_STATUS_TRANSLATOR[row.original.status]
										: row.original.status
									: ""}
							</span>
						);
					},
				},
				{
					Header: "NHS Number",
					accessor: "nhs_number",
					numeric: false,
					disablePadding: false,
					Cell: ({row}) => {
						return <NHSCell row={row} />;
					},
				},
				{
					Header: "Control Level",
					accessor: "control_level",
					numeric: false,
					disablePadding: false,
					Cell: ({row}) => {
						return (
							<span className={clsx(classes.cirular, classes.controlLevelDiv, classes[getActColor(row.original.actScore)])}>
								{controlLevelText[row.original.control_level] ? controlLevelText[row.original.control_level] : row.original.control_level}
							</span>
						);
					},
				},
				{
					Header: "Review Type",
					accessor: "survey_type",
					numeric: false,
					disablePadding: false,
					Cell: ({row}) => {
						return <span>{row.original.survey.type}</span>;
					},
				},
				{
					Header: "Action",
					accessor: "action",
					disableSortBy: true,
					numeric: false,
					disablePadding: true,
					size: "small",
					align: "center",
					Cell: ({row}) => {
						return (
							<Tooltip title="Load Patient Details">
								<IconButton color="secondary" aria-label="load-patient-details" onClick={() => loadSelectedPatient(row.original)}>
									<LaunchIcon />
								</IconButton>
							</Tooltip>
						);
					},
				},
			];
		}, [classes, loadSelectedPatient]),
	};

	const tabPanelConfig = [
		{
			props: {
				fetchRecords: getPatientsForReview,
				columns: columnsConfig.review,
				limit: pageSize,
			},
			label: "To Review",
		},
	];

	const getLabel = key => {
		if (selectedPatient && selectedPatient.processed_response && selectedPatient.processed_response[key]) {
			return selectedPatient.processed_response[key].displayText;
		}
		return key;
	};

	const getValue = key => {
		if (selectedPatient && selectedPatient.processed_response && selectedPatient.processed_response[key]) {
			return selectedPatient.processed_response[key].text;
		}
		return "-";
	};

	const getReading = key => {
		let rate = "-";
		if (selectedPatient && selectedPatient.processed_response && selectedPatient.processed_response[key]) {
			if (selectedPatient.processed_response[key].numeric_value) {
				rate =
					selectedPatient.processed_response[key].numeric_value.Value + " " + selectedPatient.processed_response[key].numeric_value.Units;
			}
			return rate;
		}
		return "-";
	};

	const getMedicationsList = () => {
		if (loadingMedications) {
			return <LinearProgress variant="indeterminate" color="primary"></LinearProgress>; //
		} else if (selectedPatient?.active_medications) {
			return selectedPatient.active_medications?.Medication?.map(medication => {
				return (
					<Accordion TransitionProps={{unmountOnExit: true}}>
						<AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel-content" id="panel-header">
							<Box>
								<Typography className={classes.headingAcc} variant="h5">
									{medication.Drug.PreparationID.Term}
								</Typography>
								<Typography className={classes.secondaryAccHeading} variant="caption">
									{medication.Dosage}
								</Typography>
							</Box>
						</AccordionSummary>
						<AccordionDetails>
							<Box>
								<Typography className={classes.accMoreInfo} component="div" variant="p">
									Last Issue Date: {medication.DateLastIssue}
								</Typography>
								<Typography className={classes.accMoreInfo} component="div" variant="p">
									Prescription Type: {medication.PrescriptionType}
								</Typography>
							</Box>
						</AccordionDetails>
					</Accordion>
				);
			});
		}
	};

	const getAsthmaDetails = () => {
		return (
			<React.Fragment>
				<Typography color="textPrimary">
					<Box fontWeight="fontWeightBold" fontSize={20}>
						ACT: {selectedPatient.actScore}
					</Box>
				</Typography>

				<TextField
					key={ACT_SCORE}
					variant={"outlined"}
					margin={"dense"}
					label={"Control Level"}
					fullWidth
					marginBottom={"10px"}
					disabled
					InputProps={{
						startAdornment: (
							<Fragment>
								<span className={clsx(classes.actLabel, classes[getActColor(selectedPatient.actScore)])}>{selectedPatient.actScore}</span>
								<span className={classes.controltextLablel}>
									{controlLevelText[selectedPatient.control_level]
										? controlLevelText[selectedPatient.control_level]
										: selectedPatient.control_level}
								</span>
							</Fragment>
						),
						classes: {
							disabled: classes.disabledInput,
						},
					}}
				/>
				<TextField
					key={AWARENESS}
					variant={"outlined"}
					margin={"dense"}
					label={getLabel(AWARENESS)}
					fullWidth
					value={getValue(AWARENESS)}
					disabled
					InputProps={{classes: {disabled: classes.disabledInput}}}
				/>
				<Box display="flex">
					{selectedPatient.processed_response ? (
						<Box className={classes.leftInput}>
							<TextField
								key={ACT_SCORE}
								variant={"outlined"}
								margin={"dense"}
								label={getLabel(ACTIVITIES)}
								value={getValue(ACTIVITIES)}
								disabled
								InputProps={{
									classes: {
										disabled: classes.disabledInput,
									},
								}}
							/>
						</Box>
					) : (
						""
					)}

					<TextField
						key={SYMPTOMS}
						variant={"outlined"}
						margin={"dense"}
						label={getLabel(SYMPTOMS)}
						fullWidth
						value={getValue(SYMPTOMS)}
						disabled
						InputProps={{classes: {disabled: classes.disabledInput}}}
					/>
				</Box>
				<Box display="flex">
					<Box className={classes.leftInput}>
						<TextField
							key={AWARENESS}
							variant={"outlined"}
							margin={"dense"}
							label={getLabel(RELIEVER)}
							value={getValue(RELIEVER)}
							disabled
							InputProps={{classes: {disabled: classes.disabledInput}}}
						/>
					</Box>
					<TextField
						key={BREATH}
						variant={"outlined"}
						margin={"dense"}
						label={getLabel(BREATH)}
						fullWidth
						value={getValue(BREATH)}
						disabled
						InputProps={{classes: {disabled: classes.disabledInput}}}
					/>
				</Box>
				<Box display="flex">
					<Box className={classes.leftInput}>
						<TextField
							disabled
							InputProps={{classes: {disabled: classes.disabledInput}}}
							key={"Hospital Admission"}
							variant={"outlined"}
							margin={"dense"}
							label={"Hospital Admission"}
							value={getValue(HOSPITAL_ADMISSION)}
							fullWidth
						/>

						{/* <TextField
											key={CONSULTATION}
											disabled
											InputProps={{classes: {disabled: classes.disabledInput}}}
											variant={"outlined"}
											margin={"dense"}
											value={getValue(CONSULTATION)}
											label={getLabel(CONSULTATION)}
										/> */}
					</Box>
					<TextField
						disabled
						InputProps={{classes: {disabled: classes.disabledInput}}}
						key={EXACERBATION}
						variant={"outlined"}
						margin={"dense"}
						label={getLabel(EXACERBATION)}
						value={getValue(EXACERBATION)}
						fullWidth
					/>
				</Box>
				<Box display="flex">
					<Box className={classes.leftInput}>
						<TextField
							key={"BMI"}
							variant={"outlined"}
							margin={"dense"}
							label={"BMI"}
							disabled
							InputProps={{
								startAdornment: (
									<Fragment>
										{selectedPatient && selectedPatient.processed_response ? (
											<span className={classes.textLabel}>{calculateBMIFromProcessedResponse(selectedPatient.processed_response)}</span>
										) : (
											""
										)}
									</Fragment>
								),
								classes: {
									disabled: classes.disabledInput,
								},
							}}
						/>
					</Box>
					<TextField
						key={SMOKING}
						disabled
						InputProps={{classes: {disabled: classes.disabledInput}}}
						variant={"outlined"}
						margin={"dense"}
						label={"Smoking status"}
						value={selectedPatient && selectedPatient.smokingStatus ? selectedPatient.smokingStatus : "-"}
						fullWidth
					/>
				</Box>
				<Box display="flex">
					<Box className={classes.leftInput}>
						<TextField
							key={CONSULTATION}
							disabled
							InputProps={{classes: {disabled: classes.disabledInput}}}
							variant={"outlined"}
							margin={"dense"}
							value={getValue(CONSULTATION)}
							label={getLabel(CONSULTATION)}
						/>
					</Box>
					<TextField
						key={PEAK_FLOW}
						variant={"outlined"}
						margin={"dense"}
						label={"Peak flow"}
						fullWidth
						value={getReading(PEAK_FLOW)}
						disabled
						InputProps={{
							classes: {disabled: classes.disabledInput},
						}}
					/>
				</Box>
			</React.Fragment>
		);
	};

	const inviteVideoConsult = async () => {
		//implement
	};

	const getEConsultationDetails = () => {
		//console.log(selectedPatient.original_entry.survey_response)

		return (
			<React.Fragment>
				<Typography color="textPrimary">
					<Box fontWeight="fontWeightBold" fontSize={20}>
						E-Consultation
					</Box>
				</Typography>
				{Object.keys(selectedPatient.original_entry.survey_response).map(key => {
					return (
						<Typography color="textPrimary">
							<Box fontWeight="fontWeightBold" fontSize={20}>
								{`${key}: ${selectedPatient.original_entry.survey_response[key]}`}
							</Box>
						</Typography>
					);
				})}
				<Box className={classes.drawerFooter}>
					<Button onClick={inviteVideoConsult} disabled={selectedPatient.status !== "TO_REVIEW"} className={classes.saveButton}>
						Invite For Video Consultation
					</Button>
				</Box>
			</React.Fragment>
		);
	};

	const getSurveyAnswerDetails = () => {
		return (
			<React.Fragment>
				<div className={classes.drawerHeader}>
					<Typography className={classes.headerText}>{selectedPatient.survey.type} Summary</Typography>
					<IconButton className={classes.drawerClose} onClick={async () => setSelectedPatient(null)}>
						<CloseIcon />
					</IconButton>
				</div>
				<Typography className={classes.surveyHeading}>
					<Box className={classes.surveyMain}>
						<b>
							{selectedPatient.given_name} {selectedPatient.surname}
						</b>{" "}
						{selectedPatient.nhs_number}
					</Box>
				</Typography>

				<React.Fragment>
					<div className={classes.actAndMedicationsContainer}>
						{selectedPatient.status === CONTROLLED_PATIENT_STATUS.TO_REVIEW ? (
							<Box className={classes.drawerContent}>
								{selectedPatient.survey.type === "ASTHMA" ? getAsthmaDetails() : getEConsultationDetails()}
							</Box>
						) : (
							""
						)}

						<Box className={classes.drawerContent} mt={2}>
							<Grid container spacing={2}>
								<Grid item xs={12}>
									<Typography>
										<Box fontSize={18} fontWeight="fontWeightBold" color="textPrimary">
											MEDICATIONS
										</Box>
									</Typography>
								</Grid>
								<Grid item xs={12}>
									{getMedicationsList()}
								</Grid>
							</Grid>
						</Box>
					</div>
					<Box className={classes.drawerFooter}>
						<Button onClick={saveToEmis} disabled={selectedPatient.status !== "TO_REVIEW"} className={classes.saveButton}>
							Save and file to EMIS
						</Button>
					</Box>
				</React.Fragment>
			</React.Fragment>
		);
	};

	const getPatientDetails = () => {
		return getSurveyAnswerDetails();
	};

	return (
		<React.Fragment>
			{isLoading && <LinearProgress variant="indeterminate" color="primary" />}
			<div className={classes.tabBar}>
				<Tabs value={tabValue} indicatorColor="primary" textColor="primary" onChange={handleTabChange} aria-label="patient-tabs">
					{tabPanelConfig.map((config, index) => {
						return <Tab key={index} {...a11yProps(index)} label={config.label}></Tab>;
					})}
				</Tabs>
			</div>
			{tabPanelConfig.map((config, index) => {
				return (
					<TabPanel className={selectedPatient ? classes.tabPanel2 : classes.tabPanel} key={index} value={tabValue} index={index}>
						<EnhancedTable {...config.props} />
					</TabPanel>
				);
			})}

			{selectedPatient && (
				<Drawer
					className={classes.drawer}
					variant="persistent"
					anchor="right"
					open={Boolean(selectedPatient)}
					classes={{
						paper: classes.drawerPaper,
					}}
				>
					{getPatientDetails()}
				</Drawer>
			)}
			<Backdrop className={classes.backdrop} open={loading}>
				<CircularProgress color="inherit" />
			</Backdrop>
		</React.Fragment>
	);
}
