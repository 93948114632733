import React from "react";

// import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import {makeStyles} from "@material-ui/core/styles";
import {Tooltip} from "@material-ui/core";

import {useSnackbar} from "notistack";

const useStyles = makeStyles(theme => ({
	nhsCell: {
		"&:hover": {
			cursor: "pointer",
			textDecoration: "underline",
			textUnderlinePosition: "under",
		},
	},
	nhsCopyIcon: {
		position: "relative",
		top: 7,
		left: 6,
		width: 18,
		fill: "rgba(2, 9, 39, 0.64)",
	},
}));

export default function NHSCell(props) {
	const classes = useStyles();
	const {enqueueSnackbar} = useSnackbar();
	let {row} = props;

	// const [hoveredCell , setHoveredCell] = useState(null);
	// let showIcon = false ;

	// if(hoveredCell === row.original.nhs_number){
	// 	showIcon = true ;
	// }

	return (
		<div
			className={classes.nhsCell}
			onClick={() => {
				let number = row.original.nhs_number.replace(/\s/g, "");
				navigator.clipboard.writeText(number);
				enqueueSnackbar("NHS number copied successfully.", {variant: "success"});
			}}
		>
			<Tooltip title="Copy NHS number">
				<span className={classes.nhsCell}>{row.original.nhs_number}</span>
			</Tooltip>
		</div>
	);
}
