import {API, graphqlOperation, Auth} from "aws-amplify";
import {listSlotss, controlledPatientByHash, slotsByStatus, locationByGpAndPostCode} from "../graphql/queries";
import {updateSlots, createSlots, updateControlledPatient, deleteSlots} from "../graphql/mutations";
import {useJsonToCsv} from "react-json-csv";
const {saveAsCsv} = useJsonToCsv();

const days = {
	SUNDAY: "SUNDAY",
	MONDAY: "MONDAY",
	TUESDAY: "TUESDAY",
	WEDNESDAY: "WEDNESDAY",
	THURSDAY: "THURSDAY",
	FRIDAY: "FRIDAY",
	SATURDAY: "SATURDAY",
};

export const getCSV = async (user, setLoading, enqueueSnackbar) => {
	setLoading(true);
	try {
		const res = (
			await API.graphql(
				graphqlOperation(slotsByStatus, {
					gp_guid: user.attributes["custom:gp_guid"],
					statusYearMonthDayHourMinuteStaff_id: {
						beginsWith: {
							status: "BOOKED",
						},
					},
				})
			)
		).data.slotsByStatus.items;

		const res2 = (
			await API.graphql(
				graphqlOperation(slotsByStatus, {
					gp_guid: user.attributes["custom:gp_guid"],
					statusYearMonthDayHourMinuteStaff_id: {
						beginsWith: {
							status: "CANCELLED",
						},
					},
				})
			)
		).data.slotsByStatus.items;

		const res3 = (
			await API.graphql(
				graphqlOperation(slotsByStatus, {
					gp_guid: user.attributes["custom:gp_guid"],
					statusYearMonthDayHourMinuteStaff_id: {
						beginsWith: {
							status: "COMPLETED",
						},
					},
				})
			)
		).data.slotsByStatus.items;

		let fields = {
			id: "ID",
			gp_guid: "GP GUID",
			year: "YEAR",
			month: "MONTH",
			day: "DAY",
			hour: "HOUR",
			minute: "MINUTE",
			patient_guid: "PATIENT GUID",
			status: "STATUS",
			comments: "COMMENTS",
		};
		let result = [...res, ...res2, ...res3];
		saveAsCsv({data: result, fields, filename: "appointment-report"});
	} catch (error) {
		console.log(error);
		enqueueSnackbar("Something went wrong. Please try again.", {variant: "error"});
	} finally {
		setLoading(false);
	}
};

export const createBatchSlots = async (values, setLoading, enqueueSnackbar, setBatchCreateOpen, calendarRef) => {
	const {startDate, endDate, startTime, endTime, duration, reason, workingDays, location} = values;
	const availableDays = Object.keys(days).reduce((acc, day, ind) => {
		if (workingDays[day]) acc.push(ind);
		return acc;
	}, []);

	if (!availableDays.length) return;
	try {
		setLoading(true);
		const user = await Auth.currentAuthenticatedUser();

		let startDt = new Date(startDate);
		let endDt = new Date(endDate);
		const oneDayInMs = 24 * 60 * 60 * 1000;
		const startDtEpoch = startDt.getTime();
		let numberOfDays = (endDt.getTime() - startDtEpoch) / oneDayInMs;
		let slots = [];
		let tempArr;
		let startTimeMs = 0;
		tempArr = startTime.split(":");
		startTimeMs = startTimeMs + Number(tempArr[0]) * 60 * 60 * 1000 + Number(tempArr[1]) * 60 * 1000;

		let endTimeMs = 0;
		tempArr = endTime.split(":");
		endTimeMs = endTimeMs + Number(tempArr[0]) * 60 * 60 * 1000 + Number(tempArr[1]) * 60 * 1000;

		let startDtStartTimeEpoch = startDtEpoch + startTimeMs;
		let startDtEndTimeEpoch = startDtEpoch + endTimeMs;

		for (let index = 0; index <= numberOfDays; index++) {
			console.log("Computing for day " + (index + 1));
			let st = startDtStartTimeEpoch + index * oneDayInMs;
			let tempStartDate = new Date(st);
			if (availableDays.indexOf(tempStartDate.getDay()) == -1) {
				continue;
			}
			let et = startDtEndTimeEpoch + index * oneDayInMs;
			for (let timeEpoch = st; timeEpoch < et; timeEpoch += duration * 60 * 1000) {
				let dt = new Date(timeEpoch);
				slots.push({
					year: String(dt.getUTCFullYear()),
					month: String(dt.getUTCMonth() + 1).padStart(2, 0),
					day: String(dt.getUTCDate()).padStart(2, 0),
					hour: String(dt.getUTCHours()).padStart(2, 0),
					minute: String(dt.getUTCMinutes()).padStart(2, 0),
					duration,
					location_details: "deprecated",
					reason: reason,
					title: "covid",
					version: "V_0",
					staff_id: location.staff_id,
					gp_guid: user.attributes["custom:gp_guid"],
					status: "AVAILABLE",
				});
			}
		}

		for (const slot of slots) {
			await API.graphql(graphqlOperation(createSlots, {input: slot}));
		}
		if (calendarRef) {
			calendarRef.current.getApi().refetchEvents();
		}
		setBatchCreateOpen(false);
		enqueueSnackbar("Successfully created available appointments.", {variant: "success"});
	} catch (error) {
		console.log(error);
		enqueueSnackbar("Error occurred. Please try again", {variant: "error"});
	} finally {
		setLoading(false);
	}
};
