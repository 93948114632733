import React from "react";

import {makeStyles} from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Paper from "@material-ui/core/Paper";
import {useTable, usePagination, useSortBy} from "react-table";
import TablePaginationActions from "./enhancedTable/tablePaginationActions";

function EnhancedTableHead(props) {
	const {headerGroup, classes} = props;

	return (
		<TableHead>
			<TableRow {...headerGroup.getHeaderGroupProps()}>
				{headerGroup.headers.map(column => (
					<TableCell
						{...column.getHeaderProps(column.getSortByToggleProps())}
						key={column.id}
						align={column.align ? column.align : column.numeric ? "right" : "left"}
						padding={column.disablePadding ? "none" : "default"}
						sortDirection={column.isSorted ? column.isSortedDesc : false}
					>
						<TableSortLabel
							active={column.isSorted}
							hideSortIcon={column.disableSortBy}
							direction={column.isSorted ? (column.isSortedDesc ? "desc" : "asc") : "asc"}
						>
							{column.render("Header")}
							{column.isSorted ? (
								<span className={classes.visuallyHidden}>{column.isSortedDesc ? "sorted descending" : "sorted ascending"}</span>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

const useStyles = makeStyles(theme => ({
	container: {
		minHeight: "324px",
		maxHeight: "calc(100vh - 432px)",
	},
	visuallyHidden: {
		border: 0,
		clip: "rect(0 0 0 0)",
		height: 1,
		margin: -1,
		overflow: "hidden",
		padding: 0,
		position: "absolute",
		top: 20,
		width: 1,
	},
}));

export default function SourceTable(props) {
	const classes = useStyles();
	const {columns, data} = props;
	const {
		getTableProps,
		getTableBodyProps,
		headerGroups,
		prepareRow,
		page,
		canPreviousPage,
		canNextPage,
		nextPage,
		previousPage,
		setPageSize,
		state: {pageIndex, pageSize},
	} = useTable(
		{
			columns,
			data,
			initialState: {pageIndex: 0},
		},
		useSortBy,
		usePagination
	);

	const handleChangeRowsPerPage = event => {
		setPageSize(parseInt(event.target.value, 10));
	};

	return (
		<div className={classes.root}>
			<Paper elevation={0} className={classes.paper}>
				<TableContainer className={classes.container}>
					<Table
						{...getTableProps()}
						className={classes.table}
						aria-labelledby="tableTitle"
						size="medium"
						stickyHeader
						aria-label="enhanced table"
					>
						<EnhancedTableHead headerGroup={headerGroups[0]} classes={classes} rowCount={page.length} />
						<TableBody {...getTableBodyProps()}>
							{page.map((row, i) => {
								prepareRow(row);
								return (
									<TableRow {...row.getRowProps()} hover tabIndex={-1} key={row.original.id}>
										{row.cells.map(cell => {
											return (
												<TableCell
													{...cell.getCellProps()}
													className={classes.tableCell}
													key={cell.column.id + "_jsd" + cell.row.id + "1"}
													align={cell.column.align ? cell.column.align : cell.column.numeric ? "right" : "left"}
													size={cell.column.size ? cell.column.size : "medium"}
													padding={cell.column.disablePadding ? "none" : "default"}
												>
													{cell.render("Cell")}
												</TableCell>
											);
										})}
									</TableRow>
								);
							})}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[10, 20, 30, 50]}
					component="div"
					count={data.length}
					rowsPerPage={pageSize}
					page={pageIndex}
					onChangeRowsPerPage={handleChangeRowsPerPage}
					ActionsComponent={props => (
						<TablePaginationActions
							onNext={nextPage}
							onPrevious={previousPage}
							hasNext={canNextPage}
							hasPrevious={canPreviousPage}
							{...props}
						/>
					)}
				/>
			</Paper>
		</div>
	);
}
