const {createMuiTheme} = require("@material-ui/core");

export const theme = createMuiTheme({
	palette: {
		common: {
			black: "#000000",
			white: "#ffffff",
		},
		primary: {
			main: "#1DCABF",
			dark: "#1A9A92",
			light: "#aee8e4",
			background: "rgba(29, 202, 191, 0.08)",
			contrastText: "#ffffff",
		},
		secondary: {
			main: "#4D567F",
			dark: "#2E365A",
			light: "#8289A2",
			background: "rgba(77, 86, 127, 0.08)",
			contrastText: "#ffffff",
		},
		success: {
			main: "#49E3C4",
			dark: "#1C9E84",
			light: "#A3E1D2",
			background: "rgba(255, 255, 255, 0.9)",
			contrastText: "#ffffff",
		},
		info: {
			main: "#a09af5",
			dark: "#6253d8",
			light: "#c7c2f9",
			background: "rgba(255, 255, 255, 0.9)",
			contrastText: "#ffffff",
		},
		warning: {
			main: "#FFB74D",
			dark: "#FF9900",
			light: "#FFCD80",
			background: "rgba(255, 255, 255, 0.9)",
			contrastText: "#ffffff",
		},
		error: {
			main: "#ED4D7A",
			dark: "#D93261",
			light: "#F594B1",
			background: "rgba(255, 255, 255, 0.9)",
			contrastText: "#ffffff",
		},
		grey: {
			50: "#F9F9F9",
			100: "#F2F2F4",
			200: "#E6E7EB",
			300: "#D7D9E0",
			400: "#AAAEBD",
			500: "#8289A2",
			600: "#686F8F",
			700: "#4D567F",
			800: "#2E365A",
			900: "#232741",
			A100: "#D5D5D5",
			A200: "#AAAAAA",
			A400: "#616161",
			A700: "#303030",
		},
		text: {
			primary: "rgba(2, 9, 39, 0.87)",
			secondary: "rgba(2,9,39, 0.64)",
			disabled: "rgba(2,9,39,0.38)",
			hint: "rgba(2,9,39,0.38)",
		},
	},
	typography: {
		// Use the system font instead of the default Roboto font.
		fontFamily: [
			"-apple-system",
			"Roboto",
			"BlinkMacSystemFont",
			'"Segoe UI"',
			'"Helvetica Neue"',
			"Arial",
			"sans-serif",
			'"Apple Color Emoji"',
			'"Segoe UI Emoji"',
			'"Segoe UI Symbol"',
		].join(","),
	},
});
