import React from "react";
import {makeStyles} from "@material-ui/core/styles";

import MenuItem from "@material-ui/core/MenuItem";

import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";

import {API, graphqlOperation} from "aws-amplify";
import {createSource} from "../graphql/mutations";

import Typography from "@material-ui/core/Typography";
import {TextField} from "@material-ui/core";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		flexWrap: "wrap",
	},
	margin: {
		margin: theme.spacing(1),
	},
	withoutLabel: {
		marginTop: theme.spacing(3),
	},
	textField: {
		width: "25ch",
	},
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
	selectEmpty: {
		marginTop: theme.spacing(2),
	},
	button: {
		margin: theme.spacing(1),
	},
}));

export default function InputAdornments() {
	const classes = useStyles();
	const [values, setValues] = React.useState({
		type: "",
		name: "",
		provider: "",
		gp_guid: "",
		source_id: "",
	});

	const handleChange = prop => event => {
		setValues({...values, [prop]: event.target.value});
	};

	const submitSource = async () => {
		try {
			await API.graphql(graphqlOperation(createSource, {input: values}));
		} catch (err) {
			console.log(err);
		}
	};

	return (
		<div className={classes.root}>
			<Typography variant="h4" component="h5" gutterBottom>
				New Patient
			</Typography>
			<Grid container justify="center" spacing={1}>
				<Grid item md={12}>
					<TextField label="Provider" value={values.provider} onChange={handleChange("provider")} fullWidth variant="outlined" />
				</Grid>

				<Grid item md={12}>
					<TextField label="Type" select value={values.type} onChange={handleChange("type")} fullWidth variant="outlined">
						<MenuItem value={"CSV"}>CSV</MenuItem>
						<MenuItem value={"QUERY"}>QUERY</MenuItem>
					</TextField>
				</Grid>
				<Grid item md={12}>
					<TextField label="GP Id" value={values.gp_guid} onChange={handleChange("gp_guid")} fullWidth variant="outlined" />
				</Grid>
				<Grid item md={12}>
					<TextField label="Name" value={values.name} onChange={handleChange("name")} fullWidth variant="outlined" />
				</Grid>
				<Grid item md={12}>
					<TextField label="Source ID" value={values.source_id} onChange={handleChange("source_id")} fullWidth variant="outlined" />
				</Grid>
			</Grid>
			<Grid container alignItems="flex-start" justify="flex-end" direction="row">
				<Button variant="contained" color="secondary" onClick={submitSource} className={classes.button} startIcon={<CloudUploadIcon />}>
					Create Source
				</Button>
			</Grid>
		</div>
	);
}
