import React, {useEffect, useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import {useSnackbar} from "notistack";
import {API, graphqlOperation, Auth} from "aws-amplify";
import {locationByGpAndPostCode} from "../graphql/queries";
import Box from "@material-ui/core/Box";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles(theme => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
	},
}));

export default function SlotFilter(props) {
	const classes = useStyles();
	const [user, setUser] = useState(null);

	const {enqueueSnackbar} = useSnackbar();
	const [auxLocation, setAuxLocation] = React.useState("ALL");
	const [auxStatus, setAuxStatus] = React.useState("ALL");
	const {setGpLocation, setStatus} = props;
	const [locations, setLocations] = React.useState([]);

	const handleChangeLocation = event => {
		setGpLocation(event.target.value === "ALL" ? null : event.target.value);
		setAuxLocation(event.target.value);
	};

	const handleChangeStatus = event => {
		setStatus(event.target.value === "ALL" ? null : event.target.value);
		setAuxStatus(event.target.value);
	};

	useEffect(() => {
		if (!user) return;
		const getLocations = async () => {
			try {
				const result = await API.graphql(
					graphqlOperation(locationByGpAndPostCode, {
						gp_guid: user.attributes["custom:gp_guid"],
					})
				);
				setLocations(result.data.locationByGPAndPostCode.items);
			} catch (error) {
				console.log(error);
			} finally {
				//
			}
		};
		getLocations();
	}, [user]);

	React.useEffect(() => {
		if (user) return;
		const getUser = async () => {
			try {
				const _user = await Auth.currentAuthenticatedUser();
				setUser(_user);
			} catch (error) {
				console.log(error);
				enqueueSnackbar("Error occurred. Please try again", {variant: "error"});
			} finally {
				//
			}
		};
		getUser();
	}, [enqueueSnackbar, user]);

	return (
		<Box display="flex" flexDirection="row-reverse" p={1} m={1} bgcolor="background.paper">
			<FormControl className={classes.formControl}>
				<InputLabel id="location-outlined-label">Location</InputLabel>
				<Select
					labelId="location-outlined-label"
					id="location-outlined"
					value={auxLocation}
					onChange={handleChangeLocation}
					label="Location"
				>
					<MenuItem value="ALL">All Locations</MenuItem>
					{locations.map(_location => (
						<MenuItem value={_location}>{_location.name}</MenuItem>
					))}
				</Select>
			</FormControl>

			<FormControl className={classes.formControl}>
				<InputLabel id="status-outlined-label">Status</InputLabel>
				<Select labelId="status-outlined-label" id="status-outlined" value={auxStatus} onChange={handleChangeStatus} label="Status">
					<MenuItem value="ALL">All Status Type</MenuItem>
					<MenuItem value="AVAILABLE">AVAILABLE</MenuItem>
					<MenuItem value="BOOKED">BOOKED</MenuItem>
					<MenuItem value="CANCELLED">CANCELLED</MenuItem>
					<MenuItem value="COMPLETED">COMPLETED</MenuItem>
				</Select>
			</FormControl>
		</Box>
	);
}
