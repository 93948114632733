import {Box, Button, CircularProgress, Dialog, Grid, Link, Select, Slide, TextareaAutosize, Typography} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import {makeStyles} from "@material-ui/core/styles";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import LaptopIcon from "@material-ui/icons/Laptop";
import LockIcon from "@material-ui/icons/Lock";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import Amplify, {API, Analytics, graphqlOperation} from "aws-amplify";
import React, {useEffect, useState} from "react";
import Badge from "react-download-android";
import BadgeIOS from "react-download-ios";
import * as Survey from "survey-react";
import "survey-react/survey.css";
import AppointmentBooking from "../components/AppointmentBooking.js";
import questionaryImg from "../Questionaire.png";
import {ANDROID_LINK, FAQ_LINK, IOS_LINK, PRIVACY_NOTICE} from "../utils/constants";
import ICONS from "../utils/icons";
import withWidth, {isWidthUp} from "@material-ui/core/withWidth";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import Mockup from "../mockup.jpg";
import appStore from "../app-store.png";
import playStore from "../play-store.png";
import AddToCalendarHOC from "react-add-to-calendar-hoc";
import moment from "moment";
import clsx from "clsx";
import Rating from "@material-ui/lab/Rating";

Survey.StylesManager.applyTheme("modern");

const useStyles = makeStyles(theme => ({
	root: {
		flexGrow: 1,
		padding: theme.spacing(2),
		backgroundColor: theme.palette.background.paper,
	},
	inline: {
		display: "inline",
	},
	avatar: {
		backgroundColor: theme.palette.secondary.main,
	},
	textField: {
		marginLeft: theme.spacing(1),
		marginRight: theme.spacing(1),
		width: 200,
	},
	backdrop: {
		zIndex: theme.zIndex.drawer + 1,
		color: "#fff",
	},
	feedbackWrap: {
		border: "1px solid rgba(0,0,0,0.2)",
		margin: theme.spacing(1) + 2,
		borderRadius: 4,
		padding: theme.spacing(3),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	success: {
		fontSize: 48,
		marginTop: theme.spacing(2),
		color: "#20E8DB",
	},
	feedback: {
		marginBottom: theme.spacing(1),
		width: "94%",
		borderRadius: 6,
		borderColor: theme.palette.primary.light,
		padding: theme.spacing(1),
		fontSize: 16,
		borderWidth: 2,
	},
	thankyou: {
		fontSize: 24,
		color: "rgba(2, 9, 39, 0.87)",
		marginTop: theme.spacing(3),
	},
	feedbackContainer: {
		marginTop: theme.spacing(3),
		minHeight: theme.spacing(33),
		marginBottom: theme.spacing(3),
		display: "flex",

		flexDirection: "column",
		justifyContent: "center",
		alignItems: "flex-end",
	},
	feedbackSubmitted: {
		marginTop: theme.spacing(3),
		minHeight: theme.spacing(33),
		marginBottom: theme.spacing(3),
		display: "flex",

		flexDirection: "row",
		justifyContent: "center",
		alignItems: "center",
	},
	feedbackSent: {
		fontSize: 32,
		marginLeft: theme.spacing(2),
	},
	submitButton: {
		marginTop: theme.spacing(2),
		backgroundColor: "rgba(46, 54, 90, 0.08)",
		color: "#2e365a",
	},
	link: {
		color: theme.palette.primary.dark,
		flex: "1",
	},
	info: {
		color: "rgb(138 138 138)",
		fontSize: 18,
	},
	hero: {
		background: "#1dcabf",
		padding: theme.spacing(3),
		borderRadius: theme.spacing(0.5),
		boxShadow: "0 4px 5px 0 rgba(0,0,0,0.14), 0 1px 10px 0 rgba(0,0,0,0.12), 0 2px 4px -1px rgba(0,0,0,0.20)",
	},
	loaderWrap: {
		height: "100vh",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		flexDirection: "column",
	},
	imgWrap: {
		textAlign: "center",
	},
	startButton: {
		fontSize: "20px",
		textTransform: "none",
		color: "white",
		maxWidth: "320px",
		minWidth: "320px",
	},
	bookingWrapper: {
		display: "flex",
		flexDirection: "column",
	},
	bookingRow: {
		display: "flex",
		flexDirection: "row",
		justifyContent: "space-between",
		alignItems: "center",
		padding: theme.spacing(1.5, 0),
	},
	slots: {
		flex: 1,
		display: "flex",
		flexDirection: "row",
		flexWrap: "wrap",
	},
	headingAcc: {
		display: "block",
		fontSize: 22,
		fontWeight: theme.typography.fontWeightRegular,
	},
	sectionHeader: {
		fontSize: 24,
		color: "rgba(0, 0, 0, 0.87)",
	},
	infoText: {
		fontSize: 16,
		lineHeight: 1.5,
		letterSpacing: "0.15px",
		color: "#595e71",
		marginTop: theme.spacing(3),
	},
	subSection: {
		flex: 1,
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
	},
	addTO: {
		backgroundColor: "rgba(46, 54, 90, 0.08)",
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		// padding: theme.spacing(0, 1),
		borderRadius: "4px",
		color: "#2e365a",
		cursor: "pointer",
		marginTop: theme.spacing(3),
	},
	linkStyles: {
		display: "flex",
		flexDirection: "column",
		color: theme.palette.secondary.dark,
		padding: theme.spacing(0, 1),
	},
	feedbackGrid: {
		margin: theme.spacing(1) + 2,
		marginLeft: theme.spacing(5) + 2,
		borderRadius: 4,
		padding: theme.spacing(3),
		display: "flex",
		flexDirection: "column",
		alignItems: "flex-start",
	},
	wellDoneGrid: {
		margin: theme.spacing(1) + 2,
		marginLeft: theme.spacing(5) + 2,
		borderRadius: 4,
		padding: theme.spacing(3),
		display: "flex",
		flexDirection: "column",
		alignItems: "center",
	},
	giveFeedback: {
		fontSize: 20,
		color: "rgba(2, 9, 39, 0.87)",
		fontWeight: 500,
	},
	feedbackInfo: {
		fontSize: 16,
		color: "#020927",
		marginTop: theme.spacing(1),
	},
	ratingTitle: {
		fontSize: 16,
		color: "rgba(2, 9, 39, 0.87)",
		marginTop: theme.spacing(3),
		fontWeight: 500,
		marginBottom: theme.spacing(1),
	},
	ratingIcon: {
		paddingRight: 6,
	},
	feedbackText: {
		width: "100%",
		resize: "none",
		fontSize: 16,
		fontFamily: theme.typography.fontFamily,
	},
	submitFeedbackButton: {
		marginTop: theme.spacing(1),
		textTransform: "capitalize",
		[theme.breakpoints.up("sm")]: {
			float: "right",
		},
	},
	enabledButton: {
		background: "#0fc3c5",
		color: "#fff",
		"&:hover": {
			background: "#0eabad",
			color: "#fff",
		},
	},
	disbaledButton: {
		color: "rgba(46, 54, 90, 0.87)",
		background: "rgba(46, 54, 90, 0.08)",
	},
	submitWrap: {
		width: "100%",
		padding: "8px 5px",
		boxSizing: "border-box",
		[theme.breakpoints.down("sm")]: {
			textAlign: "center",
		},
	},
}));

const myCss = {
	header: "my-title sv-title sv-container-modern__title",
};

const AddToCalendarDropdown = AddToCalendarHOC(Button, List);

const SurveyPage = props => {
	const classes = useStyles();
	const [model, setModel] = useState(null);
	const [gpName, setGpName] = useState(null);
	const [gpOdsCode, setGpOdsCode] = useState("");
	const [surveyName, setSurveyName] = useState(null);
	const [surveyType, setSurveyType] = useState(null);
	const [dob, setDob] = useState(null);
	const [error, setError] = useState(null);
	const [loading, setLoading] = useState(false);
	const [surveyValidation, setSurveyValidation] = useState(false);
	const [started, setStarted] = React.useState(false);
	const [checked, setChecked] = React.useState(true);
	const [isCompleted, setIsCompleted] = useState(false);
	const [showAppointments, setShowAppointments] = useState(false);
	const [response, setResponse] = useState(null);
	const [responseOptions, setResponseOptions] = useState(null);
	const [locations, setLocations] = useState(null);
	const [bookedSlots, setBookedSlots] = useState([]);
	const [feedBackSlot, setFeedBackSlot] = useState({slot: null, location: null});
	const [isFeedbackSubmitted, setIsFeedbackSubmitted] = useState(false);
	const [rating, setRating] = useState(0);
	const [formSubmitted, setFormSubmitted] = useState(false);
	const [comment, setComment] = useState(null);
	const {
		match: {params},
	} = props;

	const surveyValidatePatientDetails = React.useCallback(
		async (survey, options) => {
			//options.data contains the data for the current page.
			const dob = options.data["dob"];
			if (!dob) {
				options.complete();
				return;
			}
			const requestParams = {
				body: {
					birthdate: dob,
				},
			};

			try {
				setSurveyValidation(true);
				await API.post("surveys", `/surveys/${params.hash}/validate`, requestParams);
				setDob(dob);
				Analytics.record({
					name: "validate_survey_success",
					attributes: {ods_code: gpOdsCode, patient_hash: params.hash, patient_dob: dob.split("-")[0], survey_type: surveyType},
				});
			} catch (error) {
				console.log(error);
				options.errors["dob"] = "Please, inform the Date of Birth used to register in your GP.";
				Analytics.record({
					name: "validate_survey_error",
					attributes: {ods_code: gpOdsCode, patient_hash: params.hash, patient_dob: dob.split("-")[0], survey_type: surveyType},
				});
			}
			setSurveyValidation(false);
			options.complete();
		},
		[gpOdsCode, params.hash, surveyType]
	);

	useEffect(() => {
		setLoading(true);
		setModel(null);
		const getSurvey = async () => {
			try {
				let response = await API.get("surveys", `/surveys/${params.hash}`);
				let survey = new Survey.Model(response.survey.content); //should get the survey via the API response.
				setSurveyType(response.survey.type);
				survey.onServerValidateQuestions.add(surveyValidatePatientDetails);
				setModel(survey);
				setSurveyName(response.survey.name);
				setGpName(response.gp_name);
				setGpOdsCode(response.gp_ods_code);
				setIsCompleted(false);
				setIsFeedbackSubmitted(false);
				Analytics.record({
					name: "load_survey_success",
					attributes: {ods_code: response.gp_ods_code, patient_hash: params.hash, survey_type: response.survey.type},
				});
			} catch (error) {
				console.log(error);
				setError(error?.response?.data?.error);
				Analytics.record({
					name: "load_survey_error",
					attributes: {patient_hash: params.hash},
				});
			} finally {
				setLoading(false);
			}
		};
		getSurvey();
	}, [params, surveyValidatePatientDetails]);

	const submitSurvey = async (surveyResponse, options) => {
		console.log("submit survey");
		setLoading(true);
		try {
			const requestParams = {
				body: {
					birthdate: dob,
					survey_response: surveyResponse.data,
				},
			};
			console.log("API");
			await API.post("surveys", `/surveys/${params.hash}`, requestParams);
			setLoading(false);
			setIsCompleted(true);
			setShowAppointments(false);
			Analytics.record({
				name: "submit_survey_success",
				attributes: {ods_code: gpOdsCode, patient_hash: params.hash, patient_dob: dob.split("-")[0], survey_type: surveyType},
			});
		} catch (error) {
			console.log(error);
			setModel(null);
			setError(error?.response?.data?.error);
			setLoading(false);
			Analytics.record({
				name: "submit_survey_error",
				attributes: {ods_code: gpOdsCode, patient_hash: params.hash, patient_dob: dob.split("-")[0], survey_type: surveyType},
			});
		} finally {
			setLoading(false);
		}
	};

	const getPatientBookedAppointments = async () => {
		setLoading(true);
		try {
			// fetch booked slots for patient
			console.log("fetching booked appointments");
			let resp = await API.get("appointments", `/slots/booked/${params.hash}`);
			const bookedSlots = resp.slots;
			setBookedSlots(bookedSlots);
			setShowAppointments(true);
		} catch (err) {
			console.log(err);
		} finally {
			setLoading(false);
		}
	};

	//Submit Survey via API Gateway
	const onComplete = async (surveyResponse, options) => {
		//Write survey results into database
		const {receive_vaccination_1, receive_vaccination_2, receive_vaccination_3} = surveyResponse.data;
		if (receive_vaccination_1 === "YES" || receive_vaccination_2 === "YES" || receive_vaccination_3 === "YES") {
			setLoading(true);
			const requestParams = {
				body: {
					birthdate: dob,
				},
			};
			try {
				let result = await API.get("appointments", `/slots/${params.hash}/locations`, requestParams);
				console.log(result);
				setLocations(result.locations);
				Analytics.record({
					name: "survey_get_appointments_success",
					attributes: {ods_code: gpOdsCode, patient_hash: params.hash, patient_dob: dob.split("-")[0], survey_type: surveyType},
				});
			} catch (err) {
				console.log(err);
				Analytics.record({
					name: "survey_get_appointments_error",
					attributes: {ods_code: gpOdsCode, patient_hash: params.hash, patient_dob: dob.split("-")[0], survey_type: surveyType},
				});
			} finally {
				setLoading(false);
			}
			getPatientBookedAppointments();
			setResponse(surveyResponse);
			setResponseOptions(options);
		} else {
			submitSurvey(surveyResponse, options);
		}
	};

	const startSurvey = () => {
		setStarted(prev => !prev);
		setIsCompleted(false);
		setIsFeedbackSubmitted(false);
	};

	const hideHelper = () => {
		setChecked(prev => !prev);
	};

	const handleFeedbackSubmit = () => {
		setIsFeedbackSubmitted(true);
	};

	const onBookingComplete = async (slot, location) => {
		setFeedBackSlot({slot: slot, location: location});
		await submitSurvey(response, responseOptions);
		setShowAppointments(false);
	};

	const submitFeedback = () => {
		setFormSubmitted(true);
	};

	const handleCommentChange = event => {
		setComment(event.target.value);
	};

	const getEvent = ({slot, location}) => {
		const starttime = new Date(slot.year + "-" + slot.month + "-" + slot.day + "T" + slot.hour + ":" + slot.minute);
		const endtime = new Date(starttime.getTime() + parseInt(slot.duration) * 60000);
		return {
			description: "Covid vaccination at " + location.name,
			duration: slot.duration,
			duration: moment.duration(moment(endtime).diff(moment(starttime))).asHours(),
			startDatetime: moment(starttime).format("YYYYMMDDTHHmmssZ"),
			endDatetime: moment(endtime).format("YYYYMMDDTHHmmssZ"),
			location: location.name,
			title: "Covid Vaccination",
		};
	};

	const firstPage = surveyType => {
		if (surveyType === "COVID") {
			return (
				<Slide timeout={500} direction="right" in={checked} mountOnEnter unmountOnExit onExited={startSurvey}>
					<Box>
						<Box className={classes.hero}>
							<Grid container justify="center">
								<Grid item md={4} className={classes.imgWrap}>
									<img alt="questionary-img" width="150" src={questionaryImg} />
								</Grid>
								<Grid item md={8} justify="center">
									<Typography align="center" component="div">
										<Box fontSize={24} color="textPrimary">
											Welcome to the {surveyName}
										</Box>
									</Typography>

									<Box mt={3}>
										<Button
											data-amplify-analytics-on="click"
											data-amplify-analytics-name="start_survey"
											data-amplify-analytics-attrs={`ods_code:${gpOdsCode},hash:${params.hash},survey_type:${surveyType}`}
											className={classes.startButton}
											variant="contained"
											color="secondary"
											endIcon={<ArrowForwardIcon />}
											onClick={hideHelper}
										>
											Start booking
										</Button>
									</Box>
								</Grid>
							</Grid>
						</Box>
						<Box my={2}>
							<Typography component="div">
								<Box fontSize={20} color="textPrimary">
									Why an online survey?
								</Box>
							</Typography>
						</Box>
						<Box alignItems="center" justifyContent="center">
							<List>
								<ListItem>
									<ListItemIcon>
										<QueryBuilderIcon fontSize="medium" color="secondary" />
									</ListItemIcon>
									<ListItemText
										primary={<Typography component="div">This is an online booking form for COVID19 vaccination</Typography>}
										secondary={`Your responses will be reviewed by your GP practice.`}
									/>
								</ListItem>

								<ListItem>
									<ListItemIcon>
										<LaptopIcon fontSize="medium" color="secondary" />
									</ListItemIcon>
									<ListItemText
										primary={<Typography component="div">Want to know more about the COVID19 vaccine?</Typography>}
										secondary={
											<div>
												{"Read our "}
												<Link target="_blank" className={classes.link} href={FAQ_LINK}>
													{"frequently asked questions"}
												</Link>
												{"  and find out more about the vaccine, how it was developed and what the vaccine process will be. "}
											</div>
										}
									/>
								</ListItem>

								<ListItem>
									<ListItemIcon>
										<LockIcon fontSize="medium" color="secondary" />
									</ListItemIcon>
									<ListItemText
										primary={
											<Typography component="div">
												Medloop’s NHS Digitally assured platform keeps your data safe and secure. Please read our
												<Link target="_blank" className={classes.link} href={PRIVACY_NOTICE}>
													{" privacy notice"}
												</Link>
												.
											</Typography>
										}
									/>
								</ListItem>
								<Box mt={2}>
									<ListItem selected={true}>
										<ListItemIcon>
											<ErrorOutlineIcon color="secondary" fontSize="medium" />
										</ListItemIcon>
										<ListItemText
											primary={<Typography component="div">Please Note:</Typography>}
											secondary="If you have any urgent medical attention
								please contact the practice or the appropriate resource immediately."
										/>
									</ListItem>
								</Box>
							</List>
						</Box>
					</Box>
				</Slide>
			);
		}
		if (surveyType === "ASTHMA") {
			return (
				<Slide timeout={500} direction="right" in={checked} mountOnEnter unmountOnExit onExited={startSurvey}>
					<Box>
						<Box className={classes.hero}>
							<Grid container justify="center">
								<Grid item md={4} className={classes.imgWrap}>
									<img alt="questionary-img" width="150" src={questionaryImg} />
								</Grid>
								<Grid item md={8} justify="center">
									<Typography align="center" component="div">
										<Box fontSize={24} color="textPrimary">
											Welcome to the {gpName ? gpName + "'s" : ""} {surveyName}
										</Box>
									</Typography>

									<Box mt={3}>
										<Button
											data-amplify-analytics-on="click"
											data-amplify-analytics-name="start_survey"
											data-amplify-analytics-attrs={`ods_code:${gpOdsCode},hash:${params.hash},survey_type:${surveyType}`}
											className={classes.startButton}
											variant="contained"
											color="secondary"
											endIcon={<ArrowForwardIcon />}
											onClick={hideHelper}
										>
											Begin Survey
										</Button>
									</Box>
								</Grid>
							</Grid>
						</Box>
						<Box my={2}>
							<Typography component="div">
								<Box fontSize={20} color="textPrimary">
									Why an online survey?
								</Box>
							</Typography>
						</Box>
						<Box alignItems="center" justifyContent="center">
							<List>
								<ListItem>
									<ListItemIcon>
										<QueryBuilderIcon fontSize="medium" color="secondary" />
									</ListItemIcon>
									<ListItemText
										primary={<Typography component="div">This short survey is intended to save time</Typography>}
										secondary={`This short survey (>10 min) will be reviewed by your practice and may save you from an unnecessary GP appointment, or help expedite any follow-up consultations to save you time.`}
									/>
								</ListItem>

								<ListItem>
									<ListItemIcon>
										<LaptopIcon fontSize="medium" color="secondary" />
									</ListItemIcon>
									<ListItemText
										primary={<Typography component="div">To help you conveniently manage your symptoms</Typography>}
										secondary={`We are using this online survey to make it more convenient for you to manage your symptoms.`}
									/>
								</ListItem>

								<ListItem>
									<ListItemIcon>
										<LockIcon fontSize="medium" color="secondary" />
									</ListItemIcon>
									<ListItemText
										primary={<Typography component="div">To keep your data secure</Typography>}
										secondary={`Your data security is important to us. This data is kept confidential`}
									/>
								</ListItem>
								<Box mt={2}>
									<ListItem selected={true}>
										<ListItemIcon>
											<ErrorOutlineIcon color="secondary" fontSize="medium" />
										</ListItemIcon>
										<ListItemText
											primary={<Typography component="div">Please Note:</Typography>}
											secondary="This survey is only to be completed for routine care. If you have any urgent medical attention please contact the practice or the appropriate resource immediately."
										/>
									</ListItem>
								</Box>
							</List>
						</Box>
					</Box>
				</Slide>
			);
		}
	};

	const feedbackView = () => {
		let yob = dob ? dob.split("-")[0] : null;
		return (
			<>
				{feedBackSlot && feedBackSlot.slot && surveyType === "COVID" ? (
					<Grid className={classes.feedbackWrap}>
						<CheckCircleIcon className={classes.success} color="primary" />
						<Typography className={classes.thankyou} align="center">
							Well done, your COVID vaccination appointment is booked!
						</Typography>
						<Typography className={classes.thankyou} align="center">
							{`${feedBackSlot.slot.hour}:${feedBackSlot.slot.minute}, ${feedBackSlot.slot.day},${feedBackSlot.slot.month},${feedBackSlot.slot.year}`}
						</Typography>
						<Typography align="center">{feedBackSlot.location.name}</Typography>
						<AddToCalendarDropdown
							event={getEvent(feedBackSlot)}
							items={["GOOGLE", "ICAL"]}
							className={classes.addTO}
							linkProps={{
								className: classes.linkStyles,
							}}
						/>
						<Typography className={classes.infoText} align="center">
							Wondering what’s next? You will receive a SMS with the details of your appointment soon.
						</Typography>
					</Grid>
				) : (
					<Grid className={classes.feedbackWrap}>
						<CheckCircleIcon className={classes.success} color="primary" />
						<Typography className={classes.thankyou} align="center">
							Well done, you’ve finished the survey!
						</Typography>
						<Typography className={classes.infoText} align="center">
							Wondering what’s next? We will review your answers and follow up with you directly if needed.
						</Typography>
					</Grid>
				)}
				{formSubmitted ? (
					<Grid className={classes.wellDoneGrid}>
						<Typography className={classes.feedbackInfo}>
							Thanks for your feedback! Your feedback directly helps us improve our product.
						</Typography>
					</Grid>
				) : (
					<Grid className={classes.feedbackGrid}>
						<Typography className={classes.giveFeedback}>Give us your feedback</Typography>
						<Typography className={classes.feedbackInfo}>
							All feedback goes to our product and development teams to help us improve our products.
						</Typography>
						<Typography className={classes.ratingTitle}>How would you rate your experience?</Typography>
						<Rating
							name="simple-controlled"
							classes={{
								icon: classes.ratingIcon,
							}}
							value={rating}
							onChange={(event, newValue) => {
								setRating(newValue);
							}}
						/>
						<Typography className={classes.ratingTitle}>What did you think of the survey?</Typography>
						<TextareaAutosize
							className={classes.feedbackText}
							aria-label="minimum height"
							rowsMin={3}
							onChange={handleCommentChange}
							placeholder=""
						/>
						<div className={classes.submitWrap}>
							<Button
								disabled={rating > 0 ? false : true}
								className={clsx(classes.submitFeedbackButton, rating > 0 ? classes.enabledButton : classes.disbaledButton)}
								onClick={submitFeedback}
								data-amplify-analytics-on="click"
								data-amplify-analytics-name="patient_feedback"
								data-amplify-analytics-attrs={`survey_type:${surveyType},rating:${rating},comment:${comment},time:${new Date()}`}
							>
								Submit Feedback
							</Button>
						</div>
					</Grid>
				)}
				<Grid className={classes.feedbackWrap} style={{padding: "60px"}}>
					<Grid container direction="row" justify="space-evenly">
						<Grid className={classes.subSection}>
							<Box>{ICONS.MEDLOOP_LOGO_LARGE}</Box>
							<Typography className={classes.thankyou}>Download the Medloop app and save time</Typography>
							<Typography className={classes.infoText} style={{marginTop: "16px"}}>
								Book appointments, repeat prescriptions, chat with your practice and access your health data.
							</Typography>
							<Grid container direction="row" justify="space-evenly" style={{marginTop: "28px", display: "flex"}}>
								<Link target="_blank" className={classes.link} href={IOS_LINK}>
									<img
										alt="appStore"
										src={appStore}
										data-amplify-analytics-on="click"
										data-amplify-analytics-name="app_download_link"
										data-amplify-analytics-attrs={`ods_code:${gpOdsCode},hash:${params.hash},patient_dob:${yob},survey_type:${surveyType}`}
									/>
								</Link>
								<Link target="_blank" className={classes.link} href={ANDROID_LINK}>
									<img
										alt="playStore"
										src={playStore}
										data-amplify-analytics-on="click"
										data-amplify-analytics-name="app_download_link"
										data-amplify-analytics-attrs={`ods_code:${gpOdsCode},hash:${params.hash},patient_dob:${yob},survey_type:${surveyType}`}
									/>
								</Link>
							</Grid>
						</Grid>
						<Grid className={classes.subSection}>
							<Box display="flex" justifyContent="center">
								<img alt="mockup" src={Mockup}></img>
							</Box>
						</Grid>
					</Grid>
					<Typography className={classes.infoText} style={{marginTop: "60px"}}>
						To register with Medloop you’ll need your online registration details. Simply contact your GP practice and let them know you
						would like to register for online services. Use these details to connect securely to your GP with Medloop.
					</Typography>
				</Grid>
			</>
		);
	};

	const isAlreadyBooked = () => {
		return bookedSlots && bookedSlots.length >= 1;
	};

	const getBookedSlots = () => {
		return bookedSlots?.map((slot, i) => {
			return (
				<Box key={i}>
					<Typography className={classes.headingAcc} variant="h5">
						{slot.day}/{slot.month}/{slot.year} - {slot.hour}:{slot.minute}
					</Typography>
				</Box>
			);
		});
	};

	const showMedloopLogo = () => {
		if (isWidthUp("md", props.width)) {
			return (
				<Box textAlign="right">
					<Typography color="textPrimary">
						<Box display="flex" alignContent="center" justifyContent="flex-end">
							<span>In Collaboration with </span>
							<span style={{marginLeft: "8px"}}>{ICONS.MEDLOOP_LOGO_GREEN}</span>
						</Box>
					</Typography>
				</Box>
			);
		}
		return (
			<Box display="flex" flexDirection="row-reverse">
				{ICONS.MEDLOOP_LOGO_SMALL}
			</Box>
		);
	};

	const getSurveyTitle = () => {
		if (isWidthUp("md", props.width)) {
			return (
				<Box>
					<Typography component="div">
						<Box fontSize={16} color="textPrimary">
							<b>{gpName ? gpName : "GP Practice Name"}</b>&nbsp;&nbsp;&nbsp;&nbsp;{surveyName}
						</Box>
					</Typography>
				</Box>
			);
		}
		return (
			<Box>
				<Typography component="div">
					<Box fontSize={16} fontWeight="fontWeightBold" color="textPrimary">
						{gpName ? gpName : "GP Practice Name"}
					</Box>
				</Typography>
				<Typography component="div">
					<Box fontSize={16} color="textPrimary">
						{surveyName}
					</Box>
				</Typography>
			</Box>
		);
	};

	const pageLayout = () => {
		if (loading) {
			return (
				<Box className={classes.loaderWrap}>
					<Box>{ICONS.MEDLOOP_LOGO_GREEN}</Box>
					<CircularProgress color="primary" />
				</Box>
			);
		}
		if (model) {
			return (
				<div className={classes.root}>
					<Grid container spacing={3} justify="center">
						<Grid item md={6} xs={10}>
							{getSurveyTitle()}
						</Grid>
						<Grid item md={6} xs={2}>
							{showMedloopLogo()}
						</Grid>
						<Grid item md={8} xs={12}>
							{firstPage(surveyType)}
							<Slide timeout={500} direction="left" in={started} mountOnEnter unmountOnExit>
								<Survey.Survey showCompletedPage={false} model={model} css={myCss} onComplete={onComplete} />
							</Slide>
							{isCompleted ? feedbackView() : <div></div>}
							{showAppointments ? (
								isAlreadyBooked() ? (
									<>
										<Box mt={4} mb={2} className={classes.sectionHeader}>
											Appointment Booked
										</Box>
										<Typography>{getBookedSlots()}</Typography>
									</>
								) : (
									<AppointmentBooking
										setIsLoading={setSurveyValidation}
										bookedSlots={bookedSlots}
										dob={dob}
										locations={locations}
										hash={params.hash}
										isAdmin={false}
										showSuccessToast={false}
										showErrorToast={true}
										onBookingComplete={onBookingComplete}
									/>
								)
							) : (
								""
							)}
						</Grid>
						<Backdrop className={classes.backdrop} open={surveyValidation}>
							<CircularProgress color="inherit" />
						</Backdrop>
					</Grid>
				</div>
			);
		}
		if (error) {
			return (
				<Box>
					<Typography variant="h3" gutterBottom>
						{error}
					</Typography>
					<Badge
						id="com.medloop.v1"
						data-amplify-analytics-on="click"
						data-amplify-analytics-name="app_download_link"
						data-amplify-analytics-attrs={`hash:${params.hash}`}
					/>
					<BadgeIOS
						id="id1459846548"
						data-amplify-analytics-on="click"
						data-amplify-analytics-name="app_download_link"
						data-amplify-analytics-attrs={`hash:${params.hash}`}
					/>
				</Box>
			);
		}
	};

	return <Box pb={3}>{pageLayout()}</Box>;
};

export default withWidth()(SurveyPage);
