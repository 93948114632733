import React, {useEffect, useState, useCallback, useRef} from "react";

import {useSnackbar} from "notistack";

import {API, graphqlOperation, Auth} from "aws-amplify";

import {locationByGpAndPostCode} from "../graphql/queries";
import {createSlots} from "../graphql/mutations";
import * as moment from "moment";
import {
	Box,
	Typography,
	Button,
	Grid,
	CircularProgress,
	Backdrop,
	TextField,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	Select,
	MenuItem,
	Avatar,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import Tooltip from "@material-ui/core/Tooltip";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import {makeStyles, withStyles, useTheme} from "@material-ui/core/styles";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";

import {useHistory} from "react-router-dom";

import {useJsonToCsv} from "react-json-csv";

import "../App.css";
import QueryBuilderIcon from "@material-ui/icons/QueryBuilder";
import CalendarTodayIcon from "@material-ui/icons/CalendarToday";
import TimelapseIcon from "@material-ui/icons/Timelapse";
import CakeIcon from "@material-ui/icons/Cake";
import PhoneIcon from "@material-ui/icons/Phone";
import IconButton from "@material-ui/core/IconButton";
import SmsIcon from "@material-ui/icons/Sms";
import AccountBoxIcon from "@material-ui/icons/AccountBox";
import LocalHospitalIcon from "@material-ui/icons/LocalHospital";
import ModifyBookings from "../components/ModifyBookings.js";
import SlotFilter from "../components/SlotFilter";

const useStyles = makeStyles(theme => ({
	dialogTitle: {
		padding: theme.spacing(0),
		paddingBottom: theme.spacing(2),
		fontSize: 24,
		fontWeight: 500,
		color: theme.palette.secondary.dark,
	},
	dialogContent: {
		padding: theme.spacing(0),
		overflowY: "unset",
	},
	dialogSub: {
		paddingBottom: theme.spacing(0.5),
		fontSize: 16,
		marginBottom: theme.spacing(0),
		color: theme.palette.secondary.dark,
	},
	dialogContentGroup: {
		padding: theme.spacing(2, 0),
	},
	dialogFooter: {
		padding: theme.spacing(0),
	},
	closeButton: {
		cursor: "pointer",
		float: "right",
	},
}));

const days = {
	SUNDAY: "SUNDAY",
	MONDAY: "MONDAY",
	TUESDAY: "TUESDAY",
	WEDNESDAY: "WEDNESDAY",
	THURSDAY: "THURSDAY",
	FRIDAY: "FRIDAY",
	SATURDAY: "SATURDAY",
};

export default function BatchAppointments(props) {
	const classes = useStyles();
	const {enqueueSnackbar} = useSnackbar();

	const {setLoading, callback, isOpen, setIsOpen, calendarDates} = props;

	const [user, setUser] = useState(null);
	const [locations, setLocations] = useState([]);
	const [values, setValues] = React.useState({
		startDate: calendarDates.start.format("yyyy-MM-DD"),
		endDate: calendarDates.end.format("yyyy-MM-DD"),
		startTime: "09:00",
		endTime: "17:00",
		duration: 30,
		status: "AVAILABLE",
		reason: "",
		location: locations[0],
		workingDays: {
			[days.MONDAY]: true,
			[days.TUESDAY]: true,
			[days.WEDNESDAY]: true,
			[days.THURSDAY]: true,
			[days.FRIDAY]: true,
		},
	});

	useEffect(() => {
		setValues(prevState => {
			return {
				...prevState,
				startDate: calendarDates.start.format("yyyy-MM-DD"),
				endDate: calendarDates.end.format("yyyy-MM-DD"),
			};
		});
	}, [calendarDates]);

	useEffect(() => {
		if (user) return;
		const getUser = async () => {
			try {
				// setIsLoading(true);
				const _user = await Auth.currentAuthenticatedUser();
				setUser(_user);
			} catch (error) {
				console.log(error);
				enqueueSnackbar("Error occurred. Please try again", {variant: "error"});
			} finally {
				// setIsLoading(false);
			}
		};
		getUser();
	}, [enqueueSnackbar, user]);

	useEffect(() => {
		if (!user) return;
		const getLocations = async () => {
			try {
				const result = await API.graphql(
					graphqlOperation(locationByGpAndPostCode, {
						gp_guid: user.attributes["custom:gp_guid"],
					})
				);
				setLocations(result.data.locationByGPAndPostCode.items);
				setValues(prevState => {
					return {
						...prevState,
						location: result.data.locationByGPAndPostCode.items[0],
					};
				});
			} catch (error) {
				console.log(error);
			} finally {
				// setIsLoading(false);
			}
		};
		getLocations();
	}, [user]);

	const handleClose = () => setIsOpen(false);

	const handleChange = prop => event => {
		event.preventDefault();
		setValues({...values, [prop]: event.target.value});
	};

	const handleWorkingDayChange = day => {
		const checked = !values.workingDays[day];
		setValues(prevState => {
			return {
				...prevState,
				workingDays: {
					...prevState.workingDays,
					[day]: checked,
				},
			};
		});
	};

	const getDayColor = value => {
		if (value) {
			return "#1DCABF";
		}
		return "#d7d9e0";
	};

	const createBatchSlots = async values => {
		const {startDate, endDate, startTime, endTime, duration, reason, workingDays, location} = values;
		const availableDays = Object.keys(days).reduce((acc, day, ind) => {
			if (workingDays[day]) acc.push(ind);
			return acc;
		}, []);

		if (!availableDays.length) return;
		try {
			setLoading(true);
			const user = await Auth.currentAuthenticatedUser();

			let startDt = new Date(startDate);
			let endDt = new Date(endDate);
			const oneDayInMs = 24 * 60 * 60 * 1000;
			const startDtEpoch = startDt.getTime();
			let numberOfDays = (endDt.getTime() - startDtEpoch) / oneDayInMs;
			let slots = [];
			let tempArr;
			let startTimeMs = 0;
			tempArr = startTime.split(":");
			startTimeMs = startTimeMs + Number(tempArr[0]) * 60 * 60 * 1000 + Number(tempArr[1]) * 60 * 1000;

			let endTimeMs = 0;
			tempArr = endTime.split(":");
			endTimeMs = endTimeMs + Number(tempArr[0]) * 60 * 60 * 1000 + Number(tempArr[1]) * 60 * 1000;

			let startDtStartTimeEpoch = startDtEpoch + startTimeMs;
			let startDtEndTimeEpoch = startDtEpoch + endTimeMs;

			for (let index = 0; index <= numberOfDays; index++) {
				console.log("Computing for day " + (index + 1));
				let st = startDtStartTimeEpoch + index * oneDayInMs;
				let tempStartDate = new Date(st);
				if (availableDays.indexOf(tempStartDate.getDay()) == -1) {
					continue;
				}
				let et = startDtEndTimeEpoch + index * oneDayInMs;
				for (let timeEpoch = st; timeEpoch < et; timeEpoch += duration * 60 * 1000) {
					let dt = new Date(timeEpoch);
					console.log(moment(dt).utc().toISOString())
					slots.push({
						year: String(dt.getUTCFullYear()),
						month: String(dt.getUTCMonth() + 1).padStart(2, 0),
						day: String(dt.getUTCDate()).padStart(2, 0),
						hour: String(dt.getUTCHours()).padStart(2, 0),
						minute: String(dt.getUTCMinutes()).padStart(2, 0),
						duration,
						location_details: "deprecated",
						reason: reason,
						title: "covid",
						version: "V_0",
						staff_id: location.staff_id,
						gp_guid: user.attributes["custom:gp_guid"],
						status: "AVAILABLE",
					});
				}
			}
			console.log(slots)
			for (const slot of slots) {
				await API.graphql(graphqlOperation(createSlots, {input: slot}));
			}

			handleClose(false);
			callback();
			enqueueSnackbar("Successfully created available appointments.", {variant: "success"});
		} catch (error) {
			console.log(error);
			enqueueSnackbar("Error occurred. Please try again", {variant: "error"});
		} finally {
			setLoading(false);
		}
	};

	return (
		<Dialog
			PaperProps={{
				style: {
					padding: "32px",
					margin: "0px",
				},
			}}
			open={isOpen}
			onClose={handleClose}
			aria-labelledby="batchCreate"
		>
			<DialogTitle disableTypography className={classes.dialogTitle} id="form-dialog-title">
				Create Available Appointments
				<IconButton
					className={classes.closeButton}
					onClick={() => handleClose()}
					color="primary"
					aria-label="upload picture"
					component="span"
				>
					<CloseIcon />
				</IconButton>
			</DialogTitle>
			<DialogContent className={classes.dialogContent}>
				<DialogContentText className={classes.dialogSub} id="alert-dialog-description">
					Select your clinic start and end times, dates and location. These can be created for consecutive days and weeks.
				</DialogContentText>
				<Grid container>
					<Grid className={classes.dialogContentGroup} container spacing={1}>
						<Grid item md={6} xs={12}>
							<TextField
								variant="outlined"
								autoFocus
								margin="dense"
								id="startDate"
								label="Start Date"
								type="date"
								fullWidth
								defaultValue={values.startDate}
								onChange={handleChange("startDate")}
								InputLabelProps={{
									shrink: true,
								}}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<TextField
								variant="outlined"
								id="startTime"
								name="startTime"
								margin="dense"
								label="Start Time"
								type="time"
								fullWidth
								defaultValue={values.startTime}
								onChange={handleChange("startTime")}
								InputLabelProps={{
									shrink: true,
								}}
								inputProps={{
									step: 300, // 5 min
								}}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<TextField
								variant="outlined"
								autoFocus
								margin="dense"
								id="endDate"
								label="End Date"
								type="date"
								fullWidth
								defaultValue={values.endDate}
								onChange={handleChange("endDate")}
								InputLabelProps={{shrink: true}}
							/>
						</Grid>
						<Grid item md={6} xs={12}>
							<TextField
								variant="outlined"
								id="endTime"
								name="endTime"
								margin="dense"
								label="End Time"
								type="time"
								fullWidth
								defaultValue={values.endTime}
								onChange={handleChange("endTime")}
								InputLabelProps={{
									shrink: true,
								}}
								inputProps={{
									step: 300, // 5 min
								}}
							/>
						</Grid>
					</Grid>
					<Grid className={classes.dialogContentGroup} container spacing={1}>
						{Object.keys(days).map(day => {
							return (
								<Grid item md={1.5} key={day}>
									<Avatar
										style={{backgroundColor: getDayColor(values.workingDays[day]), cursor: "pointer"}}
										onClick={() => handleWorkingDayChange(day)}
										alt="act-score"
									>
										{day[0]}
									</Avatar>
								</Grid>
							);
						})}
					</Grid>
					<Grid item md={12} xs={12}>
						<TextField
							label="Duration (minutes)"
							type="number"
							margin="dense"
							inputProps={{min: "15", max: "90", step: "15"}}
							value={values.duration}
							onChange={handleChange("duration")}
							fullWidth
							variant="outlined"
						/>
					</Grid>
					<Grid item md={12} xs={12}>
						<TextField
							select
							label="Locations"
							fullWidth
							margin="dense"
							variant="outlined"
							labelId="demo-simple-select-label"
							id="demo-simple-select"
							value={values.location}
							onChange={handleChange("location")}
						>
							{locations.map(location => (
								<MenuItem key={location.staff_id} value={location}>
									{location.name}
								</MenuItem>
							))}
						</TextField>
					</Grid>
					<Grid item md={12} xs={12}>
						<TextField variant="outlined" label="Reason" margin="dense" value={values.reason} onChange={handleChange("reason")} fullWidth />
					</Grid>
				</Grid>
			</DialogContent>
			<DialogActions className={classes.dialogFooter}>
				<Button onClick={() => handleClose()} color="primary">
					Cancel
				</Button>
				<Button
					onClick={() => createBatchSlots(values)}
					color="primary"
					disabled={Object.values(values.workingDays).every(day => day == false)}
				>
					Create
				</Button>
			</DialogActions>
		</Dialog>
	);
}
